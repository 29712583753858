import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../utils/api";

const INITIAL_STATE = {
  fetchInventoryProductsLoading: false,
  fetchInventoryProductsSuccess: false,
  fetchInventoryProductsError: null,

  createInventoryProductLoading: false,
  createInventoryProductSuccess: false,
  createInventoryProductError: null,

  editInventoryProductLoading: false,
  editInventoryProductSuccess: false,
  editInventoryProductError: null,

  deleteInventoryProductLoading: false,
  deleteInventoryProductSuccess: false,
  deleteInventoryProductError: null,

  inventoryProducts: [],

  page: 1,
  limit: 10,
  total: 0,
};

export const fetchInventoryProductsAsync = createAsyncThunk(
  "inventoryProduct/fetchInventoryProducts",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.get(`/inventories`, {
        params: {
          paginate: true,
          page: data.page,
          perPage: data.limit,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createInventoryProductAsync = createAsyncThunk(
  "inventoryProduct/createInventoryProduct",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.post(`/inventories`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editInventoryProductAsync = createAsyncThunk(
  "inventoryProduct/editInventoryProduct",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.put(`/inventories/${data.id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteInventoryProductAsync = createAsyncThunk(
  "inventoryProduct/deleteInventoryProduct",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.delete(`/inventories/${data.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        return data.id;
      }
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const inventoryProductSlice = createSlice({
  name: "inventoryProduct",
  initialState: INITIAL_STATE,
  reducers: {
    clearFetchInventoryProducts: (state) => {
      state.fetchInventoryProductsLoading = false;
      state.fetchInventoryProductsSuccess = false;
      state.fetchInventoryProductsError = null;
    },
    clearCreateInventoryProduct: (state) => {
      state.createInventoryProductLoading = false;
      state.createInventoryProductSuccess = false;
      state.createInventoryProductError = null;
    },
    clearDeleteInventoryProduct: (state) => {
      state.deleteInventoryProductLoading = false;
      state.deleteInventoryProductSuccess = false;
      state.deleteInventoryProductError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInventoryProductsAsync.pending, (state) => {
        state.fetchInventoryProductsLoading = true;
        state.fetchInventoryProductsSuccess = false;
        state.fetchInventoryProductsError = null;
      })
      .addCase(fetchInventoryProductsAsync.fulfilled, (state, action) => {
        state.fetchInventoryProductsLoading = false;
        state.fetchInventoryProductsSuccess = true;
        state.inventoryProducts = action.payload.data.inventories;
        state.total = action.payload.data.count;
      })
      .addCase(fetchInventoryProductsAsync.rejected, (state, action) => {
        state.fetchInventoryProductsLoading = false;
        state.fetchInventoryProductsSuccess = false;
        state.fetchInventoryProductsError = action.payload;
      })
      .addCase(createInventoryProductAsync.pending, (state) => {
        state.createInventoryProductLoading = true;
        state.createInventoryProductSuccess = false;
        state.createInventoryProductError = null;
      })
      .addCase(createInventoryProductAsync.fulfilled, (state, action) => {
        state.createInventoryProductLoading = false;
        state.createInventoryProductSuccess = true;
        state.inventoryProducts = [action.payload.data, ...state.inventoryProducts];
        state.createInventoryProductError = null;
      })
      .addCase(createInventoryProductAsync.rejected, (state, action) => {
        state.createInventoryProductLoading = false;
        state.createInventoryProductSuccess = false;
        state.createInventoryProductError = action.payload;
      })
      .addCase(editInventoryProductAsync.pending, (state) => {
        state.editInventoryProductLoading = true;
        state.editInventoryProductSuccess = false;
        state.editInventoryProductError = null;
      })
      .addCase(editInventoryProductAsync.fulfilled, (state, action) => {
        state.editInventoryProductLoading = false;
        state.editInventoryProductSuccess = true;
        state.inventoryProducts = state.inventoryProducts.map((item) => {
          if (item.id === action.payload.data.id) {
            return action.payload.data;
          }
          return item;
        });
      })
      .addCase(editInventoryProductAsync.rejected, (state, action) => {
        state.editInventoryProductLoading = false;
        state.editInventoryProductSuccess = false;
        state.editInventoryProductError = action.payload;
      })
      .addCase(deleteInventoryProductAsync.pending, (state) => {
        state.deleteInventoryProductLoading = true;
        state.deleteInventoryProductSuccess = false;
        state.deleteInventoryProductError = null;
      })
      .addCase(deleteInventoryProductAsync.fulfilled, (state, action) => {
        state.deleteInventoryProductLoading = false;
        state.deleteInventoryProductSuccess = true;
        state.inventoryProducts = state.inventoryProducts.filter(
          (item) => item.id !== action.payload
        );
      })
      .addCase(deleteInventoryProductAsync.rejected, (state, action) => {
        state.deleteInventoryProductLoading = false;
        state.deleteInventoryProductSuccess = false;
        state.deleteInventoryProductError = action.payload;
      });
  },
});

export const {
  clearFetchInventoryProducts,
  clearCreateInventoryProduct,
  clearDeleteInventoryProduct,
} = inventoryProductSlice.actions;
export default inventoryProductSlice.reducer;
