import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { Login } from "./components/modals/Login";
import ProtectedRoute from "./components/common/ProtectedRoute";
import CustomModal from "./components/common/CustomModal";
import { ConfigProvider } from "antd";



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#00B728",
          },
        }}
      >
        <Routes>
          <Route
            path="login"
            element={
              <CustomModal
                centered={false}
                isModalOpen={true}
                maskClosable={false}
                closable={false}
              >
                <Login />
              </CustomModal>
            }
          />
          <Route
            path="*"
            element={
              <ProtectedRoute path={"*"}>
                <App />
              </ProtectedRoute>
            }
          />
        </Routes>
      </ConfigProvider>
    </BrowserRouter>
  </Provider>
);
