import React from "react";

export const BinCardPageLayout = () => {
  return (
    <>
      <div className="font-poppins flex flex-row justify-between items-center mb-8">
        <div className="font-poppins text-2xl font-poppins-semibold text-gray-500 mb-0">
          Bin Card Report
        </div>
      </div>
    </>
  );
};
