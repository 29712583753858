import React from "react";

/**
 * imported from chart.js and react-chartjs-2
 */
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut, Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export const DashboardChartCard = ({ doughnutChartData, pieChartData }) => {
  return (
    <div className="text-base font-poppins-regular bg-white flex flex-row items-center justify-center mx-2 w-1/2 h-full py-2 px-4 rounded-xl shadow-xl shadow-gray-300">
      <div className="w-4/5 m-2">
        {doughnutChartData && (
          <>
            <h1 className="text-center text-xl text-info-main">
              Total Number of orders
            </h1>
            <Doughnut data={doughnutChartData} />
          </>
        )}
        {pieChartData && (
          <>
            <h1 className="text-center text-xl text-info-main">
              Products, Warehouses, Shelves
            </h1>
            <Pie data={pieChartData} />
          </>
        )}
      </div>
    </div>
  );
};
