import React from "react";

export const DetailForRequisitionProduct = ({ product }) => {
  return (
    <div className="font-poppins-regular m-auto w-full">
      <h1 className="font-poppins-regular text-xl text-center text-gray-500">
        Requisition Product
      </h1>

      {Object.keys(product).length > 0 && (
        <div className="w-full flex flex-row shadow-md rounded-xl px-2 py-4 bg-gray-300 text-black">
          <div className="flex flex-col">
            <div className="flex flex-row">
              <span className="mr-2 font-bold">Name:</span>
              <span>{product["name"]}</span>
            </div>
            <div className="flex flex-row">
              <span className="mr-2 font-bold">Quantity:</span>
              <span>{product["quantity"]}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
