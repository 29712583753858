import React, { useEffect } from "react";
import { Button, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { validateMessages } from "../../utils/validate-messages";
import {
  clearCreateWarehouse,
  clearEditWarehouse,
  createWarehouseAsync,
  editWarehouseAsync,
} from "../../store/warehouse/warehouseSlice";
import { antdMessage } from "../../utils/antd-messages";

const CreateWarehouse = ({ onComplete, isEdit, initialValue }) => {
  /**
   * states
   */
  const dispatch = useDispatch();

  /**
   * selectors
   */
  const {
    createWarehouseLoading,
    createWarehouseSuccess,
    createWarehouseError,
    editWarehouseLoading,
    editWarehouseSuccess,
    editWarehouseError,
  } = useSelector((state) => state.warehouse);

  /**
   * methods
   */

  const onSubmit = (values) => {
    if (!isEdit) {
      dispatch(createWarehouseAsync(values));
    } else {
      dispatch(editWarehouseAsync({ id: initialValue.id, result: values }));
    }
  };

  /**
   * effects
   */
  useEffect(() => {
    if (createWarehouseSuccess) {
      antdMessage("success", "Warehouse created successfully");
      dispatch(clearCreateWarehouse());
      onComplete();
    }

    if (editWarehouseSuccess) {
      antdMessage("warning", "Warehouse updated successfully");
      dispatch(clearEditWarehouse());
      onComplete();
    }
  }, [createWarehouseSuccess, editWarehouseSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (createWarehouseError) {
      dispatch(clearCreateWarehouse());
    }

    if (editWarehouseError) {
      dispatch(clearEditWarehouse());
    }
  }, [createWarehouseError, editWarehouseError]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * destructuring
   */
  return (
    <>
      <div className="font-poppins-regular m-auto w-full">
        <h1 className="my-3 mb-4 text-center text-2xl font-poppins-semibold text-gray-500">
          {isEdit ? "Update Warehouse" : "Create Warehouse"}
        </h1>
        <hr className="mb-4" />
        <Form
          id={isEdit ? "editWarehouseForm" : "createWarehouseForm"}
          className="w-full"
          layout="vertical"
          validateMessages={validateMessages}
          onFinish={onSubmit}
          requiredMark={false}
          initialValues={{ ...initialValue }}
        >
          <Form.Item
            name={"warehouse_name"}
            label="Warehouse name"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="Warehouse name"
              className="!appearance-none !border !rounded-lg !w-full !py-2 !px-3 !text-gray-700 !leading-tight !focus:outline-none !focus:shadow-outline"
            />
          </Form.Item>
          <Form.Item
            name={"warehouse_location"}
            label="Warehouse location"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="Warehouse location"
              className="!appearance-none !border !rounded-lg !w-full !py-2 !px-3 !text-gray-700 !leading-tight !focus:outline-none !focus:shadow-outline"
            />
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="w-full font-poppins-regular mt-2 text-white !hover:bg-blue-500 font-bold py-2 px-8 focus:outline-none !rounded-lg focus:shadow-outline"
            loading={isEdit ? editWarehouseLoading : createWarehouseLoading}
            disabled={isEdit ? editWarehouseLoading : createWarehouseLoading}
          >
            {isEdit ? "Update" : "Submit"}
          </Button>
        </Form>
      </div>
    </>
  );
};

export default CreateWarehouse;
