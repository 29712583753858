import React, { useEffect, useState } from "react";
import { Button, Table } from "antd";
import { FileSyncOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllRequisitionsAsync,
  fetchRequestedRequisitionsAsync,
} from "../../../store/requisition/requisitionSlice";
import { CustomTable } from "../../../components/common/CustomTable";
import CustomModal from "../../../components/common/CustomModal";
import { DetailForRequisition } from "../../../components/requisition/DetailForRequisition";
import CustomTag from "../../../components/common/CustomTag";

const RequisitionAllTabLayout = () => {
  /**
   * states
   */
  const dispatch = useDispatch();
  const [showRequisitionDetailModal, setShowRequisitionDetailModal] =
    useState(false);
  const [initialValue, setInitialValue] = useState({});

  /**
   * selectors
   */

  const { fetchAllRequistionsLoading, allRequisitions } = useSelector(
    (state) => state.requisition
  );

  /**
   * methods
   */

  /**
   * effects
   */

  useEffect(() => {
    dispatch(fetchAllRequisitionsAsync());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * destructuring
   */

  const columns = [
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      render: (data) => {
        return <CustomTag data={data}>{data}</CustomTag>;
      },
    },
    {
      key: "totalNumberOfRequestedProducts",
      title: "Product quantity",
      dataIndex: "product_quantity",
      render: (data) => {
        if (data) {
          return `${data.length}`;
        }
        return <span>0</span>;
      },
    },
    {
      key: "storeName",
      title: "Store name",
      dataIndex: ["vendorId", "store_name"],
      render: (data) => {
        return <span>{data}</span>;
      },
    },
    {
      key: "vendorPhoneNumber",
      title: "Vendor phone number",
      dataIndex: ["vendorId", "phone"],

      render: (data) => {
        return <span>{data}</span>;
      },
    },
    {
      key: "referenceNumber",
      title: "Reference number",
      dataIndex: "refnumber",

      render: (data) => {
        return <span>{data}</span>;
      },
    },
  ];

  return (
    <>
      <div>
        <CustomTable
          loading={fetchAllRequistionsLoading}
          columns={columns}
          data={allRequisitions}
        />
      </div>
    </>
  );
};

export default RequisitionAllTabLayout;
