import { Alert } from "antd";

export const antdAlert = (type, text, duration = 2) => {
  switch (type.toLowerCase()) {
    case "success":
      return <Alert message="Success" description={text} type="success" />;

    case "error":
      return <Alert message="Error" description={text} type="error" />;

    case "warning":
      return <Alert message="Warning" description={text} type="warning" />;

    default:
      return <Alert message="Info" description={text} type="info" />;
  }
};
