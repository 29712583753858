import React, { useRef } from "react";
import { Button, Image } from "antd";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import ashewaLogo from "../../assets/images/ashewa-transparent.png";

const DetailForDispatchNote = ({ data }) => {
  console.log(data);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <>
      <div className="font-poppins-regular m-auto w-full rounded-xl">
        <div className="flex flex-col">
          <div className="flex flex-row mb-4 w-2/4">
            <Button
              type="primary"
              className="w-2/4"
              style={{ backgroundColor: "#1DA2A5", border: "#1DA2A5" }}
              onClick={handlePrint}
            >
              Print
            </Button>
          </div>
          <div className="bg-[#EEE] p-5" ref={componentRef}>
            <div className="mt-6 flex flex-col justify-end items-end">
              <div className="flex flex-row w-full">
                <div className="w-1/2 mt-20">
                  {/* Warehouse information */}
                  <div className="flex flex-row justify-between">
                    <span>Warehouse manager name</span>
                    <span className="text-gray-500">
                      {data !== null || data !== {}
                        ? `${data?.warehouseManager.firstName} ${data.warehouseManager.lastName}`
                        : null}
                    </span>
                  </div>
                  <div className="flex flex-row justify-between">
                    <span>Warehouse manager phone</span>
                    <span className="text-gray-500">
                      {data !== null || data !== {}
                        ? data?.warehouseManager.phone
                        : null}
                    </span>
                  </div>
                </div>
                <div className="w-1/2 flex flex-col justify-end items-end">
                  {/* Ashewa Logo */}
                  <Image
                    alt="ashewa logo"
                    preview={false}
                    width={160}
                    src={ashewaLogo}
                  />
                  <span className="text-xl">Ashewa Technology Solution</span>
                  <span className="text-gray-500 italic">
                    Together We&apos;ll build our future!
                  </span>
                </div>
              </div>

              <div className="mt-4 flex w-full flex-col justify-start items-start">
                <div className="w-[40%]">
                  <div className="w-full flex flex-row justify-between">
                    <span className="text-right">Email</span>
                    <span className="text-gray-500">admin@ashewa.com</span>
                  </div>
                  <div className="w-full flex flex-row justify-between">
                    <span className="text-right">TIN</span>
                    <span className="text-gray-500">10004014</span>
                  </div>
                  <div className="w-full flex flex-row justify-between">
                    <span className="text-right">P.O box</span>
                    <span className="text-gray-500">1010</span>
                  </div>
                  <div className="w-full flex flex-row justify-between">
                    <span className="text-right">Phone number</span>
                    <span className="text-gray-500">0922612024</span>
                  </div>
                  <div className="w-full flex flex-row justify-between">
                    <span>Address</span>
                    <span className="text-gray-500 w-full text-right">
                      22 Town square mall, Addis Ababa, Ethiopia
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8 w-full px-4 py-2  flex flex-row justify-center items-center border-[1.5px] border-gray-800">
              <span className="text-center text-xl font-bold">
                Dispatch Note
              </span>
            </div>
            <div className="mt-8 flex flex-row justify-between">
              <div>
                <span>Vendor phone</span>
                <span className="ml-4 text-gray-500">
                  {/* {data !== null || data !== {}
                    ? `${data["productId"]["vendorId"]["phone"]}`
                    : null} */}
                </span>
              </div>
              <div>
                <span>Date</span>
                <span className="ml-4 text-gray-500">{`${moment(
                  new Date()
                ).format("ddd, ll")}`}</span>
              </div>
              <div>
                <span>GRN number</span>
                <span className="ml-4 text-gray-500">GRN 000</span>
              </div>
            </div>
            <div className="mt-1 flex flex-row justify-end items-center">
              <span>Cost-Centre</span>
              <span className="ml-4 text-gray-500">Ashewa Finance</span>
            </div>

            {/* Table */}
            <div className="mt-8">
              <table className="table-auto border border-gray-800 w-full">
                <thead className="border border-gray-800">
                  <tr className="w-full">
                    <th className="w-[5%] border border-r border-gray-800"></th>
                    <th className="w-3/12 border border-r border-gray-800">
                      Goods
                    </th>
                    <th className="w-2/12 border-r border-gray-800">
                      Pack size
                    </th>
                    <th className="w-2/12 border-r border-gray-800">
                      Quantity
                    </th>
                    <th className="w-2/12 border-r border-gray-800">Price</th>
                    <th className="w-2/12 border-r border-gray-800">
                      Total price
                    </th>
                    <th className="w-4/12 border-r border-gray-800 px-6">
                      Comments
                    </th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {data?.product_quantity.map((product, idx) => {
                    return (
                      <tr key={idx} className="border border-gray-800">
                        <td className="border-r border-gray-800">{idx + 1}</td>
                        <td className="border-r border-gray-800">
                          {product?.productId?.name}
                        </td>
                        <td className="border-r border-gray-800">{"pcs"}</td>
                        <td className="border-r border-gray-800">
                          {product.quantity}
                        </td>
                        <td className="border-r border-gray-800">
                          {product?.productId?.price}
                        </td>
                        <td className="border-r border-gray-800">
                          {product?.productId?.price * product?.quantity
                            ? product?.productId?.price * product?.quantity
                            : ""}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {/* Received and Checked by */}
            <div className="mt-8 flex flex-row justify-between">
              <div>
                <span>Received by</span>
                <span>_______________________</span>
              </div>
              <div>
                <span>Checked by</span>
                <span>_______________________</span>
              </div>
            </div>
            {/* Copy Locations */}
            <div className="mt-8 flex flex-col text-sm">
              <span>1. Accounts/Finance dept. copy</span>
              <span>2. Vendor copy</span>
              <span>3. Stores/Goods inward copy</span>
            </div>
            {/* Footer */}
            <div className="mt-8">
              <hr className="my-2 bg-gray-500 h-[0.15px]" />
              <div className="w-full flex flex-col items-end justify-end">
                <span className="text-right">Ashewa Technology Solutions</span>
                <span className="text-right">&copy; All rights reserved</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailForDispatchNote;
