import React from "react";

/**
 * imported from antd
 */
import { Modal } from "antd";

const CustomModal = ({
  centered,
  children,
  closable,
  isModalOpen,
  onCancel,
  width,
  maskClosable,
}) => {
  return (
    <Modal
      centered={centered}
      destroyOnClose={true}
      open={isModalOpen}
      onCancel={onCancel}
      footer={null}
      maskClosable={maskClosable}
      closable={closable}
      width={width || 400}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
