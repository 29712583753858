import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../utils/api";

const INITIAL_STATE = {
  fetchVendorsLoading: false,
  fetchVendorsSuccess: false,
  fetchVendorsError: null,

  fetchVendorProductsStart: false,
  fetchVendorProductsSuccess: false,
  fetchVendorProductsError: null,

  vendors: [],
  vendorProducts: [],
  page: 1,
  limit: 10,
  total: 0,
};

export const fetchVendorsAsync = createAsyncThunk(
  "vendor/fetchVendorsAsync",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.get(`/vendors`, {
        params: data._all
          ? { _all: data._all }
          : {
              page: data.page,
              perPage: data.limit,
            },
        headers: { Authorization: `Bearer ${token}` },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchVendorProductsAsync = createAsyncThunk(
  "vendor/fetchVendorProductsAsync",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.get(`/products`, {
        params: {
          vendorId: data.id,
        },
        headers: { Authorization: `Bearer ${token}` },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const vendorSlice = createSlice({
  name: "vendor",
  initialState: INITIAL_STATE,
  reducers: {
    clearFetchVendors: (state) => {
      state.fetchVendorsLoading = false;
      state.fetchVendorsSuccess = false;
      state.fetchVendorsError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVendorsAsync.pending, (state) => {
      state.fetchVendorsLoading = true;
    });
    builder.addCase(fetchVendorsAsync.fulfilled, (state, action) => {
      state.fetchVendorsLoading = false;
      state.fetchVendorsSuccess = true;
      if (action.meta.arg._all) {
        state.vendors = action.payload.data;
      } else {
        state.vendors = action.payload.data.vendors;
      }
      state.total = action.payload.data.count;
    });
    builder.addCase(fetchVendorsAsync.rejected, (state, action) => {
      state.fetchVendorsLoading = false;
      state.fetchVendorsError = action.payload;
    });

    builder.addCase(fetchVendorProductsAsync.pending, (state) => {
      state.fetchVendorProductsStart = true;
    });
    builder.addCase(fetchVendorProductsAsync.fulfilled, (state, action) => {
      state.fetchVendorProductsStart = false;
      state.fetchVendorProductsSuccess = true;
      state.vendorProducts = action.payload.data;
    });
    builder.addCase(fetchVendorProductsAsync.rejected, (state, action) => {
      state.fetchVendorProductsStart = false;
      state.fetchVendorProductsError = action.payload;
    });
  },
});

export const { clearFetchVendors } = vendorSlice.actions;
export default vendorSlice.reducer;
