import React, { useRef, useState, useEffect } from "react";
import moment from "moment";
import { Button, Input, Popconfirm, Space } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import Highlighter from "react-highlight-words";
import { get, isEqual } from "lodash";
import {
  clearDeleteUser,
  clearFetchUsers,
  deleteUserAsync,
  fetchUsersAsync,
} from "../../store/user/userSlice";
import { CustomTable } from "../../components/common/CustomTable";
import { CustomButton } from "../../components/common/CustomButton";
import { CustomDrawer } from "../../components/common/CustomDrawer";
import CreateUser from "../../components/user/CreateUser";
import { antdMessage } from "../../utils/antd-messages";
import { checkUserByRole } from "../../utils/check-user-by-role";

export const UserPageLayout = () => {
  /**
   * states
   */
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({});

  /**
   * selectors
   */
  const { user } = useSelector((state) => state.auth);
  const {
    users,
    fetchUsersLoading,
    fetchUsersError,
    deleteUserSuccess,
    deleteUserError,
    page,
    limit,
    total,
  } = useSelector((state) => state.user);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);

  /**
   * methods
   */

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        className="!rounded-lg"
      >
        <Input
          ref={searchInput}
          placeholder={"Search..."}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
          className="!rounded-lg"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
            className="!rounded-lg"
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
            className="!rounded-lg"
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            className="!text-primary"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#00B728" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      get(record, dataIndex)
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      isEqual(searchedColumn, dataIndex) ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#00B728",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  /**
   * effects
   */
  useEffect(() => {
    dispatch(fetchUsersAsync({ page, limit }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fetchUsersError) {
      dispatch(clearFetchUsers());
    }
    if (deleteUserSuccess) {
      antdMessage("success", "User deleted successfully");
      dispatch(clearDeleteUser());
      dispatch(fetchUsersAsync());
    }
    if (deleteUserError) {
      dispatch(clearDeleteUser());
    }
  }, [fetchUsersError, deleteUserError, deleteUserSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * destructuring
   */

  const columns = [
    {
      title: "First name",
      dataIndex: "firstName",
      fixed: "left",
      width: "8%",
      ...getColumnSearchProps("firstName"),
    },
    {
      title: "Last name",
      dataIndex: "lastName",
      width: "8%",
      ...getColumnSearchProps("lastName"),
    },

    {
      title: "Email",
      dataIndex: "email",
      width: "16%",
      ...getColumnSearchProps("email"),
    },
    {
      title: "Phone number",
      dataIndex: "phone",
      width: "10%",
      render: (data) => {
        return <div>{data}</div>;
      },
      ...getColumnSearchProps("phone"),
    },
    {
      title: "Role",
      dataIndex: "role",
      width: "12%",
      render: (data) => {
        return data === "superadmin" ? (
          <div>{"SuperAdmin"}</div>
        ) : data === "warehouse" ? (
          <div>{"Warehouse Manager"}</div>
        ) : data === "admin" ? (
          <div>{"Admin"}</div>
        ) : (
          <div>{"Delivery"}</div>
        );
      },
    },
    {
      title: "Created date",
      dataIndex: "createdAt",
      width: "14%",
      render: (data) => {
        return moment(data).format("MMMM DD, YYYY");
      },
    },
    {
      title: "Action",
      key: "id",
      fixed: "right",
      dataIndex: "id",
      width: "7%",
      render: (id, data) => {
        return (
          <>
            <Button
              disabled={!checkUserByRole("superadmin", user)}
              onClick={() => {
                setShowEditUserModal(true);
                setInitialValues(data);
              }}
              type="primary"
              ghost
            >
              <EditOutlined />
            </Button>
            <span> </span>
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              okType="danger"
              cancelText="No"
              disabled={!checkUserByRole("superadmin", user)}
              onConfirm={() => dispatch(deleteUserAsync({ id: id }))}
            >
              <Button disabled={!checkUserByRole("superadmin", user)} danger>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="font-poppins flex flex-row justify-between items-center mb-8">
        <div className="font-poppins text-2xl font-poppins-semibold text-gray-500 mb-0">
          Users
        </div>
        <CustomButton
          type="primary"
          text={"Create User"}
          className="!rounded-lg"
          onClick={() => setShowCreateUserModal(true)}
          disabled={fetchUsersLoading}
        />
      </div>
      <div>
        <CustomTable
          loading={fetchUsersLoading}
          columns={columns}
          data={users}
          scrollValue={1300}
          pagination={{
            defaultPage: page,
            defaultPageSize: limit,
            total: total,
            onChange: (page, limit) => {
              dispatch(fetchUsersAsync({ page, limit }));
            },
          }}
        />
      </div>
      <CustomDrawer
        openDrawer={showCreateUserModal}
        onClose={() => setShowCreateUserModal(false)}
        width={500}
      >
        <CreateUser
          onComplete={() => {
            setShowCreateUserModal(false);
            dispatch(fetchUsersAsync());
          }}
        />
      </CustomDrawer>
      <CustomDrawer
        openDrawer={showEditUserModal}
        onClose={() => setShowEditUserModal(false)}
        width={450}
      >
        <CreateUser
          isEdit={true}
          initialValues={initialValues}
          onComplete={() => {
            setShowEditUserModal(false);
            dispatch(fetchUsersAsync());
          }}
        />
      </CustomDrawer>
    </>
  );
};
