import { Descriptions } from "antd";
import React, { useState } from "react";
import { commaNumber } from "../../utils/numbers";
import moment from "moment";
import CustomModal from "../common/CustomModal";
import DetailForOrderedProduct from "../order/DetailForOrderedProduct";

export const DetailForSelfPickup = ({ order }) => {
  /**
   * states
   */
  const [orderProduct, setOrderProduct] = useState();
  const [showOrderProduct, setShowOrderProduct] = useState(false);

  /**
   * selectors
   */

  /**
   * methods
   */

  /**
   *  effects
   */

  /**
   * destructuring
   */

  return (
    <div className="font-poppins-regular m-auto w-full bg-white">
      <Descriptions title="Detail for self pickup" bordered size="default">
        <Descriptions.Item label="Reference no.">
          <div>{order["order_voucher"]}</div>
        </Descriptions.Item>
        <Descriptions.Item label="Ordered by">
          {`${order["customerId"]["first_name"]} ${order["customerId"]["last_name"]}`}
        </Descriptions.Item>
        <Descriptions.Item label="Order Date">
          <div>{moment(order["createdAt"]).format("dddd Do MMMM, YYYY")}</div>
        </Descriptions.Item>
        <Descriptions.Item label="Price">
          {`${commaNumber(order["totalPrice"])} br`}
        </Descriptions.Item>
        <Descriptions.Item label="Cash On Delivery">
          {order["is_cash_on_delivery"] ? (
            <div>{"Yes, cash need to be paid."}</div>
          ) : (
            <div>{"No, payment already made."}</div>
          )}
        </Descriptions.Item>

        <Descriptions.Item label="Quantity">
          {order["product_quantity"].length}
        </Descriptions.Item>
        <Descriptions.Item label="Delivery Address">
          {order["deliveryDate"] === "next_day"
            ? "Next Day"
            : order["deliveryDate"] === "expedited"
            ? "Expedited"
            : order["deliveryDate"] === "same_day"
            ? "Same Day"
            : `${
                order["deliveryDate"].toString()[0].toUpperCase() +
                order["deliveryDate"].toString().slice(1)
              }`}
        </Descriptions.Item>
        <Descriptions.Item label="Delivery Location">
          {order["deliveryLocation"]}
        </Descriptions.Item>
        <Descriptions.Item label="Delivery Type">
          {order["delivery_type"]}
        </Descriptions.Item>

        <Descriptions.Item span={2} label="Customer Phone Number">
          <div>{order["customerId"]["phone"]}</div>
        </Descriptions.Item>
        {(order["status"] === "Ongoing" ||
          order["status"] === "Pending" ||
          order["status"] === "Delivered") && (
          <>
            <Descriptions.Item label="Deliverer Name">
              {`${order.delivererId?.firstName} ${order.delivererId?.lastName}`}
            </Descriptions.Item>
            <Descriptions.Item label="Deliverer Phone">
              <span>{`${order.delivererId?.phone}`}</span>
            </Descriptions.Item>
            <Descriptions.Item label="Deliverer Email">
              <span>{`${order.delivererId?.email}`}</span>
            </Descriptions.Item>
          </>
        )}
        <Descriptions.Item label="Ordered products">
          <div className="grid grid-cols-1 overflow-y-scroll h-[200px] delivery-scrollbar bg-white">
            {order["product_quantity"].map((product) => {
              return (
                <div
                  key={product.id}
                  className="font-poppins-light w-fit h-fit px-3 py-1 rounded-full bg-gray-500 text-white cursor-pointer"
                  onClick={() => {
                    setOrderProduct(product);
                    setShowOrderProduct(true);
                  }}
                >
                  {`${product.productId.name} - ${product.quantity} pcs`}
                </div>
              );
            })}
          </div>
        </Descriptions.Item>
      </Descriptions>
      <CustomModal
        centered
        isModalOpen={showOrderProduct}
        onCancel={() => setShowOrderProduct(false)}
        width={500}
      >
        <DetailForOrderedProduct product={orderProduct} />
      </CustomModal>
    </div>
  );
};
