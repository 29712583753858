import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import thunk from "redux-thunk";

/**
 * Reducers
 */
import authSlice from "./auth/authSlice";
import dashboardStatusSlice from "./dashboard-status/dashboardStatusSlice";
import inventoryProductSlice from "./inventory-product/inventoryProductSlice";
import shelfSlice from "./shelf/shelfSlice";
import userSlice from "./user/userSlice";
import warehouseSlice from "./warehouse/warehouseSlice";

/**
 * Reducers for ashewa.com
 */
import customerSlice from "./server/customer/customerSlice";
import productSlice from "./server/product/productSlice";
import vendorSlice from "./server/vendor/vendorSlice";
import orderSlice from "./order/orderSlice";
import dispatchNoteSlice from "./dispatch-note/dispatchNoteSlice";
import requisitionSlice from "./requisition/requisitionSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    customer: customerSlice,
    dashboard: dashboardStatusSlice,
    dispatchNote: dispatchNoteSlice,
    inventoryProduct: inventoryProductSlice,
    order: orderSlice,
    product: productSlice,
    requisition: requisitionSlice,
    shelf: shelfSlice,
    user: userSlice,
    vendor: vendorSlice,
    warehouse: warehouseSlice,
  },
  middleware: [thunk, logger],
});
