import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../utils/api";

const INITIAL_STATE = {
  fetchProductsLoading: false,
  fetchProductsSuccess: false,
  fetchProductsError: null,

  products: [],
  page: 1,
  limit: 10,
  total: 0,
};

export const fetchProductsAsync = createAsyncThunk(
  "product/fetchProductsAsync",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.get(`/products`, {
        params: {
          paginate:true,
          page: data.page,
          perPage: data.limit,
        },
        headers: { Authorization: `Bearer ${token}` },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const productSlice = createSlice({
  name: "product",
  initialState: INITIAL_STATE,
  reducers: {
    clearFetchProduct: (state) => {
      state.fetchProductsLoading = false;
      state.fetchProductsSuccess = false;
      state.fetchProductsError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProductsAsync.pending, (state) => {
      state.fetchProductsLoading = true;
    });
    builder.addCase(fetchProductsAsync.fulfilled, (state, action) => {
      state.fetchProductsLoading = false;
      state.fetchProductsSuccess = true;
      state.products = action.payload.data.products;
      state.total = action.payload.data.count;
    });
    builder.addCase(fetchProductsAsync.rejected, (state, action) => {
      state.fetchProductsLoading = false;
      state.fetchProductsError = action.payload;
    });
  },
});

export const { clearFetchProduct } = productSlice.actions;
export default productSlice.reducer;
