import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../utils/api";

const INITIAL_STATE = {
  fetchAllOrdersLoading: false,
  fetchAllOrdersSuccess: false,
  fetchAllOrdersError: null,

  fetchOrderedOrdersLoading: false,
  fetchOrderedOrdersSuccess: false,
  fetchOrderedOrdersError: null,

  fetchPendingOrdersLoading: false,
  fetchPendingOrdersSuccess: false,
  fetchPendingOrdersError: null,

  fetchOngoingOrdersLoading: false,
  fetchOngoingOrdersSuccess: false,
  fetchOngoingOrdersError: null,

  fetchDeliveredOrdersLoading: false,
  fetchDeliveredOrdersSuccess: false,
  fetchDeliveredOrdersError: null,

  fetchSelfPickupOrdersLoading: false,
  fetchSelfPickupOrdersSuccess: false,
  fetchSelfPickupOrdersError: null,

  updateSelfPickupStatusLoading: false,
  updateSelfPickupStatusSuccess: false,
  updateSelfPickupStatusError: null,

  allOrders: [],
  ordersOrdered: [],
  ordersPending: [],
  ordersOngoing: [],
  ordersDelivered: [],
  selfPickupOrders: [],

  allPage: 1,
  allLimit: 10,
  allTotal: 0,

  orderedPage: 1,
  orderedLimit: 10,
  orderedTotal: 0,

  pendingPage: 1,
  pendingLimit: 10,
  pendingTotal: 0,

  ongoingPage: 1,
  ongoingLimit: 10,
  ongoingTotal: 0,

  deliveredPage: 1,
  deliveredLimit: 10,
  deliveredTotal: 0,

  selfPickupPage: 1,
  selfPickupLimit: 10,
  selfPickupTotal: 0,
};

export const fetchAllOrdersAsync = createAsyncThunk(
  "order/fetchAllOrdersAsync",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.get(`/orders`, {
        params: {
          paginate: true,
          page: data.allPage,
          perPage: data.allLimit,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchOrderedOrdersAsync = createAsyncThunk(
  "order/fetchOrderedOrdersAsync",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.get(`/orders`, {
        params: {
          paginate: true,
          page: data.orderedPage,
          perPage: data.orderedLimit,
          status: "Ordered",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchPendingOrdersAsync = createAsyncThunk(
  "order/fetchPendingOrdersAsync",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.get(`/orders`, {
        params: {
          page: data.pendingPage,
          perPage: data.pendingLimit,
          status: "Pending",
          paginate: true,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchOngoingOrdersAsync = createAsyncThunk(
  "order/fetchOngoingOrdersAsync",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.get(`/orders`, {
        params: {
          page: data.ongoingPage,
          perPage: data.ongoingLimit,
          status: "Ongoing",
          paginate: true,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchDeliveredOrdersAsync = createAsyncThunk(
  "order/fetchDeliveredOrdersAsync",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.get(`/orders`, {
        params: {
          page: data.deliveredPage,
          perPage: data.deliveredLimit,
          status: "Delivered",
          paginate: true,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchSelfPickupOrdersAsync = createAsyncThunk(
  "order/fetchSelfPickupOrdersAsync",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.get(`/orders/get-self-order`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateSelfPickupStatusAsync = createAsyncThunk(
  "order/updateSelfPickupStatusAsync",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.patch(
        `/orders/update-selfpickup-status/${data.id}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const orderSlice = createSlice({
  name: "order",
  initialState: INITIAL_STATE,
  reducers: {
    clearFetchOrdersOrdered: (state) => {
      state.fetchOrderedOrdersLoading = false;
      state.fetchOrderedOrdersSuccess = false;
      state.fetchOrderedOrdersError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllOrdersAsync.pending, (state) => {
        state.fetchAllOrdersLoading = true;
      })
      .addCase(fetchAllOrdersAsync.fulfilled, (state, action) => {
        console.log(action);
        state.fetchAllOrdersLoading = false;
        state.fetchAllOrdersSuccess = true;
        state.fetchAllOrdersError = null;
        state.allOrders = action.payload.data.orders;
        state.allTotal = action.payload.data.count;
      })
      .addCase(fetchAllOrdersAsync.rejected, (state, action) => {
        state.fetchAllOrdersLoading = false;
        state.fetchAllOrdersSuccess = false;
        state.fetchAllOrdersError = action.payload;
      })
      .addCase(fetchOrderedOrdersAsync.pending, (state) => {
        state.fetchOrderedOrdersLoading = true;
      })
      .addCase(fetchOrderedOrdersAsync.fulfilled, (state, action) => {
        console.log(action);
        state.fetchOrderedOrdersLoading = false;
        state.fetchOrderedOrdersSuccess = true;
        state.fetchOrderedOrdersError = null;
        state.ordersOrdered = action.payload.data.orders;
        state.orderedTotal = action.payload.data.count;
      })
      .addCase(fetchOrderedOrdersAsync.rejected, (state, action) => {
        state.fetchOrderedOrdersLoading = false;
        state.fetchOrderedOrdersSuccess = false;
        state.fetchOrderedOrdersError = action.payload;
      })
      .addCase(fetchPendingOrdersAsync.pending, (state) => {
        state.fetchPendingOrdersLoading = true;
      })
      .addCase(fetchPendingOrdersAsync.fulfilled, (state, action) => {
        state.fetchPendingOrdersLoading = false;
        state.fetchPendingOrdersSuccess = true;
        state.fetchPendingOrdersError = null;
        state.ordersPending = action.payload.data.orders;
        state.pendingTotal = action.payload.data.count;
      })
      .addCase(fetchPendingOrdersAsync.rejected, (state, action) => {
        state.fetchPendingOrdersLoading = false;
        state.fetchPendingOrdersSuccess = false;
        state.fetchPendingOrdersError = action.payload;
      })
      .addCase(fetchOngoingOrdersAsync.pending, (state) => {
        state.fetchOngoingOrdersLoading = true;
      })
      .addCase(fetchOngoingOrdersAsync.fulfilled, (state, action) => {
        state.fetchOngoingOrdersLoading = false;
        state.fetchOngoingOrdersSuccess = true;
        state.fetchOngoingOrdersError = null;
        state.ordersOngoing = action.payload.data.orders;
        state.ongoingTotal = action.payload.data.count;
      })
      .addCase(fetchOngoingOrdersAsync.rejected, (state, action) => {
        state.fetchOngoingOrdersLoading = false;
        state.fetchOngoingOrdersSuccess = false;
        state.fetchOngoingOrdersError = action.payload;
      })
      .addCase(fetchDeliveredOrdersAsync.pending, (state) => {
        state.fetchDeliveredOrdersLoading = true;
      })

      .addCase(fetchDeliveredOrdersAsync.fulfilled, (state, action) => {
        state.fetchDeliveredOrdersLoading = false;
        state.fetchDeliveredOrdersSuccess = true;
        state.fetchDeliveredOrdersError = null;
        state.ordersDelivered = action.payload.data.orders;
        state.deliveredTotal = action.payload.data.count;
      })

      .addCase(fetchDeliveredOrdersAsync.rejected, (state, action) => {
        state.fetchDeliveredOrdersLoading = false;
        state.fetchDeliveredOrdersSuccess = false;
        state.fetchDeliveredOrdersError = action.payload;
      })

      .addCase(fetchSelfPickupOrdersAsync.pending, (state) => {
        state.fetchSelfPickupOrdersLoading = true;
      })

      .addCase(fetchSelfPickupOrdersAsync.fulfilled, (state, action) => {
        state.fetchSelfPickupOrdersLoading = false;
        state.fetchSelfPickupOrdersSuccess = true;
        state.fetchSelfPickupOrdersError = null;
        state.selfPickupOrders = action.payload.data;
        // TODO: Pagination
      })

      .addCase(fetchSelfPickupOrdersAsync.rejected, (state, action) => {
        state.fetchSelfPickupOrdersLoading = false;
        state.fetchSelfPickupOrdersSuccess = false;
        state.fetchSelfPickupOrdersError = action.payload;
      })

      .addCase(updateSelfPickupStatusAsync.pending, (state) => {
        state.updateSelfPickupStatusLoading = true;
        state.updateSelfPickupStatusSuccess = false;
        state.updateSelfPickupStatusError = null;
      })
      .addCase(updateSelfPickupStatusAsync.fulfilled, (state, action) => {
        state.updateSelfPickupStatusLoading = false;
        state.updateSelfPickupStatusSuccess = true;
        state.updateSelfPickupStatusError = null;
      })
      .addCase(updateSelfPickupStatusAsync.rejected, (state, action) => {
        state.updateSelfPickupStatusLoading = false;
        state.updateSelfPickupStatusSuccess = false;
        state.updateSelfPickupStatusError = action.payload;
      });
  },
});

export const { clearFetchOrdersOrdered } = orderSlice.actions;
export default orderSlice.reducer;
