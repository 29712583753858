import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

/**
 * imported from antd and @ant-design/icons
 */
import { Image, Menu, Layout } from "antd";
import {
  ContactsOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  UserOutlined,
  ApartmentOutlined,
  UsergroupAddOutlined,
  GroupOutlined,
  MenuOutlined,
  TagsOutlined,
  OrderedListOutlined,
  IdcardOutlined,
  ProfileOutlined,
  UserAddOutlined,
  TableOutlined,
  PullRequestOutlined,
} from "@ant-design/icons";

/**
 * imported from react-router-dom
 */
import { useLocation, useNavigate } from "react-router-dom";

/**
 * imported from assets
 */
import ashewaLogo from "../../assets/images/ashewa-transparent.png";
import ashewaLogoPlaceholder from "../../assets/images/ashewa-transparent.png";

const { Sider } = Layout;

const Sidebar = ({ collapsed }) => {
  /**
   * states
   */
  let navigate = useNavigate();
  let location = useLocation();
  const [currentMenuItem, setCurrentMenuItem] = useState();

  /**
   * selectors
   */

  /**
   * methods
   */
  const getItem = (label, key, icon, children, type) => {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  };

  /**
   * effects
   */

  useEffect(() => {
    localStorage.setItem("ashewaMenuKey", location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (localStorage.getItem("ashewaMenuKey").length === 1) {
      setCurrentMenuItem(localStorage.getItem("ashewaMenuKey"));
    } else {
      setCurrentMenuItem(localStorage.getItem("ashewaMenuKey").slice(1));
    }
  }, [location.pathname]);

  /**
   * destructuring
   */
  const items = [
    getItem("Dashboard", "/", <DashboardOutlined />),
    getItem("Requisitions", "requisitions", <PullRequestOutlined />),
    getItem("Warehouses", "warehouses", <GroupOutlined />),
    getItem("Shelves", "shelves", <MenuOutlined />),
    getItem("Goods Receiving Note", "goods-receiving-notes", <TableOutlined />),
    getItem("Orders", "orders", <DatabaseOutlined />),
    getItem("Self Pickup Orders", "self-pickup-orders", <ContactsOutlined />),
    getItem("Dispatch Note", "dispatch-notes", <TagsOutlined />),
    getItem("Vendors", "vendors", <UserOutlined />),
    getItem("Products", "products", <ApartmentOutlined />),
    getItem("Customers", "customers", <UsergroupAddOutlined />),
    getItem("Reports", "reports", <OrderedListOutlined />, [
      getItem("Bin Card", "reports/bin-card", <ProfileOutlined />),
      getItem("Stock Card", "reports/stock-card", <IdcardOutlined />),
    ]),
    getItem("Users", "users", <UserAddOutlined />),
  ];
  return (
    <>
      <div className="font-poppins-regular">
        <Sider
          className="!overflow-auto !top-0 !left-0 !bottom-0 !fixed h-screen !bg-white"
          trigger={null}
          collapsible
          collapsed={collapsed}
          width={220}
        >
          <div
            className={`${
              collapsed ? "mt-8" : "mt-0"
            } bg-white p-2.5 mb-5 logo flex justify-center transition-all`}
          >
            {/* Logo */}
            <LazyLoadImage
              src={ashewaLogo}
              placeholderSrc={ashewaLogoPlaceholder}
              width={"100%"}
            />
          </div>

          <Menu
            className="font-poppins-regular"
            theme="light"
            mode="inline"
            selectedKeys={[currentMenuItem]}
            items={items}
            onClick={({ key }) => {
              navigate(`${key}`, { replace: true });
              setCurrentMenuItem(key);
            }}
          />
        </Sider>
      </div>
    </>
  );
};

export default Sidebar;
