import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../utils/api";

const INITIAL_STATE = {
  fetchDashboardStatusLoading: false,
  fetchDashboardStatusSuccess: false,
  fetchDashboardStatusError: false,

  inventories: null,
  warehouses: null,
  shelves: null,
  dashboardStatus: [],
};

export const fetchDashboardStatusAsync = createAsyncThunk(
  "dashboardStatus/fetchDashboardStatus",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.get(`/dashboard`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const dashboardStatusSlice = createSlice({
  name: "dashboardStatus",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardStatusAsync.pending, (state) => {
        state.fetchDashboardStatusLoading = true;
        state.fetchDashboardStatusSuccess = false;
        state.fetchDashboardStatusError = false;
      })
      .addCase(fetchDashboardStatusAsync.fulfilled, (state, action) => {
        state.fetchDashboardStatusLoading = false;
        state.fetchDashboardStatusSuccess = true;
        state.fetchDashboardStatusError = false;
        state.inventories = action.payload.data.inventories;
        state.shelves = action.payload.data.shelves;
        state.warehouses = action.payload.data.warehouses;
        state.dashboardStatus = action.payload.data.orders
          .sort((a, b) =>
            a["_id"].toLowerCase().localeCompare(b["_id"].toLowerCase())
          )
          .map((status) => {
            return status["count"];
          });
      })
      .addCase(fetchDashboardStatusAsync.rejected, (state, action) => {
        state.fetchDashboardStatusLoading = false;
        state.fetchDashboardStatusSuccess = false;
        state.fetchDashboardStatusError = action.payload;
      });
  },
});

export default dashboardStatusSlice.reducer;
