import React from "react";

/**
 * imported from antd
 */
import { Layout } from "antd";

const { Content } = Layout;

export const HomeWrapper = (props) => {
  const { children } = props;
  return (
    <>
      <Content className="p-6 bg-white" style={{ overflow: "initial" }}>
        {children}
      </Content>
    </>
  );
};
