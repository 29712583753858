import React, { useEffect } from "react";
import { Button, Form, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { validateMessages } from "../../utils/validate-messages";
import {
  clearCreateUser,
  clearUpdateUser,
  createUserAsync,
  updateUserAsync,
} from "../../store/user/userSlice";
import { antdMessage } from "../../utils/antd-messages";
import { antdNotification } from "../../utils/antd-notifications";

const { Option } = Select;

const CreateUser = ({ isEdit, initialValues, onComplete }) => {
  /**
   * states
   */
  const dispatch = useDispatch();

  /**
   * selectors
   */
  const {
    createUserLoading,
    createUserSuccess,
    createUserError,
    updateUserLoading,
    updateUserSuccess,
    updateUserError,
  } = useSelector((state) => state.user);

  /**
   * methods
   */

  const onSubmit = (values) => {
    if (!isEdit) {
      const { firstName, lastName, email, password, role, phone } = values;
      const result = {
        firstName,
        lastName,
        email,
        password,
        role,
        phone: "0" + phone,
      };
      dispatch(createUserAsync(result));
    } else {
      const { firstName, lastName, email, role, phone } = values;
      const result = {
        firstName,
        lastName,
        email,
        role,
        phone: "0" + phone,
      };
      dispatch(updateUserAsync({ id: initialValues.id, result: result }));
    }
  };

  /**
   * effects
   */
  useEffect(() => {
    if (createUserSuccess) {
      antdMessage("success", "User created successfully");
      dispatch(clearCreateUser());
      onComplete();
    }

    if (updateUserSuccess) {
      antdMessage("warning", "User updated successfully");
      dispatch(clearUpdateUser());
      onComplete();
    }

    if (createUserError) {
      dispatch(clearCreateUser());
    }
    if (updateUserError) {
      dispatch(clearUpdateUser());
    }
  }, [createUserSuccess, updateUserSuccess, createUserError, updateUserError]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * destructuring
   */

  return (
    <div className="font-poppins m-auto w-full">
      {createUserError && createUserError.message
        ? antdNotification(
            "error",
            createUserError.response.data.data,
            "bottomRight"
          )
        : null}

      <h1 className="my-3 mb-4 text-center text-2xl font-poppins-semibold text-gray-500">
        {isEdit ? "Update User" : "Create User"}
      </h1>
      <hr className="mb-4" />
      <Form
        className="w-full"
        layout="vertical"
        validateMessages={validateMessages}
        onFinish={onSubmit}
        requiredMark={false}
        initialValues={
          isEdit && {
            ...initialValues,
            phone: initialValues.phone.slice(1),
          }
        }
      >
        <div className="flex flex-row w-full">
          <Form.Item
            name={"firstName"}
            label="First name"
            validateFirst
            rules={[
              { required: true },
              {
                pattern: new RegExp("^\\D*$"),
                message: "No spaces, numbers or special characters allowed",
              },
              {
                pattern: new RegExp("^\\S*$"),
                message: "No spaces, numbers or special characters allowed",
              },
              {
                min: 3,
                message: "Must be at least 3 characters",
              },
            ]}
            className="w-1/2"
          >
            <Input
              placeholder="First name"
              className="!appearance-none !border !rounded-lg !w-full !py-2 !px-3 !text-gray-700 !leading-tight !focus:outline-none !focus:shadow-outline"
            />
          </Form.Item>
          <span className="mx-2" />
          <Form.Item
            name={"lastName"}
            label="Last name"
            validateFirst
            rules={[
              { required: true },
              {
                pattern: new RegExp("^\\D*$"),
                message: "No spaces, numbers or special characters allowed",
              },
              {
                pattern: new RegExp("^\\S*$"),
                message: "No spaces, numbers or special characters allowed",
              },
              {
                min: 3,
                message: "Must be at least 3 characters",
              },
            ]}
            className="w-1/2"
          >
            <Input
              placeholder="Last name"
              className="!appearance-none !border !rounded-lg !w-full !py-2 !px-3 !text-gray-700 !leading-tight !focus:outline-none !focus:shadow-outline"
            />
          </Form.Item>
        </div>
        <Form.Item
          name={"email"}
          label="Email"
          rules={[{ required: true }, { type: "email" }]}
        >
          <Input
            placeholder="Email"
            className="!appearance-none !border !rounded-lg !w-full !py-2 !px-3 !text-gray-700 !leading-tight !focus:outline-none !focus:shadow-outline"
          />
        </Form.Item>
        <div className="flex flex-row">
          <Form.Item
            name={"phone"}
            label="Phone number"
            validateFirst
            rules={[
              {
                pattern: "^[9]",
                message: "Must be start with 9",
              },
              {
                pattern: new RegExp("^[0-9]{9}$"),
                message: "Must be 9 characters",
              },
              { required: true },
            ]}
            className="w-full"
          >
            <Input
              prefix={"+251"}
              placeholder="Phone number"
              className="!h-[34px] !appearance-none !border !rounded-lg !w-full !py-2 !px-3 !text-gray-700 !leading-tight !focus:outline-none !focus:shadow-outline"
            />
          </Form.Item>
          <span className="mx-2" />

          <Form.Item
            name={"password"}
            label="Password"
            rules={[
              {
                min: 4,
                message: "Password must be greater than 4 characters",
              },
              !isEdit && {
                required: true,
              },
            ]}
            className="w-full"
          >
            <Input.Password
              placeholder="Password"
              className="!h-[34px] !appearance-none !border !rounded-lg !w-full !py-2 !px-3 !text-gray-700 !leading-tight !focus:outline-none !focus:shadow-outline"
              disabled={isEdit}
            />
          </Form.Item>
        </div>

        <Form.Item
          name={"role"}
          label="Choose role"
          rules={[{ required: true, message: "Role is required!" }]}
        >
          {/* Roles */}
          <Select
            placeholder="Select a role"
            className={
              "border-none w-full !h-[34px] !border !rounded-lg bg-white outline-none select-style"
            }
          >
            <Option value="admin">Admin</Option>
            <Option value="delivery">Delivery</Option>
            <Option value="warehouse">Warehouse Manager</Option>
          </Select>
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          className="w-full font-poppins-regular mt-2 text-white !hover:bg-blue-500 font-bold py-2 px-8 focus:outline-none !rounded-lg focus:shadow-outline"
          loading={isEdit ? updateUserLoading : createUserLoading}
          disabled={isEdit ? updateUserLoading : createUserLoading}
        >
          {isEdit ? "Update" : "Create"}
        </Button>
      </Form>
    </div>
  );
};

export default CreateUser;
