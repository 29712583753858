import React, { useEffect, useState } from "react";

import { Tabs, Tag, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardStatusAsync } from "../../store/dashboard-status/dashboardStatusSlice";

/**
 * _pages
 */
import OrdersAllTabLayout from "./_pages/OrdersAllTabLayout";
import OrdersIncomingTabLayout from "./_pages/OrdersIncomingTabLayout";
import OrdersPendingTabLayout from "./_pages/OrdersPendingTabLayout";
import OrdersOngoingTabLayout from "./_pages/OrdersOngoingTabLayout";
import OrdersDeliveredTabLayout from "./_pages/OrdersDeliveredTabLayout";
import {
  fetchDeliveredOrdersAsync,
  fetchOngoingOrdersAsync,
  fetchOrderedOrdersAsync,
  fetchPendingOrdersAsync,
} from "../../store/order/orderSlice";

const OrderPageLayout = () => {
  /**
   * states
   */
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(0);
  const { dashboardStatus } = useSelector((state) => state.dashboard);

  /**
   * selectors
   */

  const { assignDeliveryBoySuccess } = useSelector((state) => state.user);

  /**
   * methods
   */

  /**
   * effects
   */

  useEffect(() => {
    dispatch(fetchDashboardStatusAsync());
  }, [assignDeliveryBoySuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * destructuring
   */

  /**  Here is the status ascending order 
   Canceled(0) Declined(1)  Delivered(2) Ongoing(3) Ordered(4) Pending(5) Refunded(6) Returned(7)
   Incoming mean ordered on this time
  */
  const items = [
    {
      label: (
        <Space>
          <span className="tab-styles-2 font-poppins-regular">
            {"Incoming"}
          </span>
          <Tag className="text-[#607D8B] border-[#607D8B]">
            {dashboardStatus[4] ? dashboardStatus[4] : 0}
          </Tag>
        </Space>
      ),
      key: "0",
      children: <OrdersIncomingTabLayout />,
    },
    {
      label: (
        <Space>
          <span className="tab-styles-3 font-poppins-regular">{"Pending"}</span>
          <Tag className="text-[#FFC107] border-[#FFC107]">
            {dashboardStatus[5] ? dashboardStatus[5] : 0}
          </Tag>
        </Space>
      ),
      key: "1",
      children: <OrdersPendingTabLayout />,
    },
    {
      label: (
        <Space>
          <span className="tab-styles-4 font-poppins-regular">{"Ongoing"}</span>
          <Tag className="text-[#FF5722] border-[#FF5722]">
            {dashboardStatus[3] ? dashboardStatus[3] : 0}
          </Tag>
        </Space>
      ),
      key: "2",
      children: <OrdersOngoingTabLayout />,
    },
    {
      label: (
        <Space>
          <span className="tab-styles-5 font-poppins-regular">
            {"Delivered"}
          </span>
          <Tag className="text-[#87d068] border-[#87d068]">
            {dashboardStatus[2] ? dashboardStatus[2] : 0}
          </Tag>
        </Space>
      ),
      key: "3",
      children: <OrdersDeliveredTabLayout />,
    },
    {
      label: (
        <Space>
          <span className="tab-styles-1 font-poppins-regular">
            {"Total orders"}
          </span>
          <Tag className="text-[#2db7f5] border-[#2db7f5]">
            {dashboardStatus.reduce(function (acc, val) {
              return acc + val;
            }, 0)}
          </Tag>
        </Space>
      ),
      key: "4",
      children: <OrdersAllTabLayout />,
    },
  ];

  return (
    <>
      <div className="font-poppins flex flex-row justify-between items-center mb-8">
        <div className="font-poppins text-2xl font-poppins-semibold text-gray-500 mb-0">
          Orders
        </div>
      </div>

      <div className="font-poppins">
        <Tabs type="card" items={items} />
      </div>
    </>
  );
};

export default OrderPageLayout;
