import React from "react";

export const StockCardPageLayout = () => {
  return (
    <>
      <div className="font-poppins flex flex-row justify-between items-center mb-8">
        <div className="font-poppins text-2xl font-poppins-semibold text-gray-500 mb-0">
          Stock Card Report
        </div>
        <div>This page will contain stock card report</div>
      </div>
    </>
  );
};
