import React, { useEffect, useRef, useState } from "react";

import { Button, Input, notification, Popconfirm, Space } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";

import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {
  clearDeleteWarehouse,
  clearFetchWarehouses,
  deleteWarehouseAsync,
  fetchWarehousesAsync,
} from "../../store/warehouse/warehouseSlice";
import { CustomTable } from "../../components/common/CustomTable";
import { CustomButton } from "../../components/common/CustomButton";
import { CustomDrawer } from "../../components/common/CustomDrawer";
import WarehouseCreate from "../../components/warehouse/CreateWarehouse";
import CreateWarehouse from "../../components/warehouse/CreateWarehouse";
import { antdMessage } from "../../utils/antd-messages";

const WarehousePageLayout = () => {
  /**
   * states
   */
  const dispatch = useDispatch();
  const [initialValue, setInitialValue] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [showCreateWarehouseModal, setShowCreateWarehouseModal] =
    useState(false);
  const [showEditWarehouseModal, setShowEditWarehouseModal] = useState(false);

  /**
   * selectors
   */
  const {
    warehouses,
    fetchWarehousesLoading,
    fetchWarehousesError,
    deleteWarehouseSuccess,
    deleteWarehouseError,
    page,
    limit,
    total,
  } = useSelector((state) => state.warehouse);

  /**
   * methods
   */

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        className="!rounded-lg"
      >
        <Input
          ref={searchInput}
          placeholder={"Search..."}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
          className="!rounded-lg"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
            className="!rounded-lg"
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
            className="!rounded-lg"
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#00B728" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#00B728",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  /**
   * effects
   */

  useEffect(() => {
    dispatch(fetchWarehousesAsync({ page, limit }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fetchWarehousesError) {
      dispatch(clearFetchWarehouses());
    }

    if (deleteWarehouseSuccess) {
      antdMessage("error", "Warehouse deleted successfully");
      dispatch(clearDeleteWarehouse());
    }

    if (deleteWarehouseError) {
      dispatch(clearDeleteWarehouse());
    }
  }, [fetchWarehousesError, deleteWarehouseSuccess, deleteWarehouseError]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * destructuring
   */

  const columns = [
    {
      key: "warehouseName",
      title: "Warehouse name",
      dataIndex: "warehouse_name",
      width: "25%",
      ...getColumnSearchProps("warehouse_name"),
    },
    {
      key: "warehouseLocation",
      title: "Warehouse location",
      dataIndex: "warehouse_location",
      width: "25%",
      ...getColumnSearchProps("warehouse_location"),
    },

    {
      key: "id",
      title: "Action",
      fixed: "right",
      dataIndex: "id",
      width: "1%",
      render: (id, warehouse) => {
        return (
          <Space>
            <Button
              onClick={() => {
                setShowEditWarehouseModal(true);
                setInitialValue(warehouse);
              }}
              type="primary"
              ghost
            >
              <EditOutlined />
            </Button>
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              okType="danger"
              cancelText="No"
              onConfirm={() => {
                dispatch(deleteWarehouseAsync({ id: id }));
              }}
              disabled
            >
              <Button disabled danger>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      {fetchWarehousesError && fetchWarehousesError.message
        ? notification.error({
            message: "Error",
            placement: "topRight",
            description: `${fetchWarehousesError.message}`,
          })
        : null}
      <div className="font-poppins flex flex-row justify-between items-center mb-8">
        <div className="text-2xl font-poppins-semibold text-gray-500 mb-0">
          Warehouses
        </div>
        <CustomButton
          type="primary"
          text={"Create Warehouse"}
          className="!rounded-lg"
          onClick={() => setShowCreateWarehouseModal(true)}
          disabled={fetchWarehousesLoading}
        />
      </div>
      <div>
        <CustomTable
          loading={fetchWarehousesLoading}
          columns={columns}
          data={warehouses}
          pagination={{
            defaultPage: page,
            defaultPageSize: limit,
            total: total,
            onChange: (page, limit) => {
              dispatch(fetchWarehousesAsync({ page, limit }));
            },
          }}
        />
      </div>
      <CustomDrawer
        openDrawer={showCreateWarehouseModal}
        onClose={() => setShowCreateWarehouseModal(false)}
      >
        <CreateWarehouse
          onComplete={() => setShowCreateWarehouseModal(false)}
        />
      </CustomDrawer>
      <CustomDrawer
        openDrawer={showEditWarehouseModal}
        onClose={() => setShowEditWarehouseModal(false)}
      >
        <WarehouseCreate
          isEdit={true}
          initialValue={initialValue}
          onComplete={() => {
            setShowEditWarehouseModal(false);
            dispatch(fetchWarehousesAsync({ page, limit }));
          }}
        />
      </CustomDrawer>
    </>
  );
};

export default WarehousePageLayout;
