import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { api } from "../../utils/api";
import getCurrentUser from "../../utils/get-current-user";

const authCookies = getCurrentUser || JSON.stringify({});

const INITIAL_STATE = {
  loginLoading: false,
  loginSuccess: false,
  loginError: false,
  user: {},
  token: null,
  ...JSON.parse(authCookies),
};

export const loginAsync = createAsyncThunk(
  "auth/login",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post(`/auth/login`, data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: INITIAL_STATE,
  reducers: {
    logout: (state) => {
      state.user = {};
      state.token = null;
      Cookies.remove(process.env.REACT_APP_COOKIES_KEY);
    },
    clearLogin: (state) => {
      state.loginError = false;
      state.loginSuccess = false;
      state.loginLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.loginLoading = true;
        state.loginSuccess = false;
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        const encryptUser = CryptoJS.AES.encrypt(
          JSON.stringify({
            token: action.payload.data.token["accessToken"],
            user: action.payload.data.user,
          }),
          process.env.REACT_APP_SECRET_KEY
        ).toString();
        Cookies.set(process.env.REACT_APP_COOKIES_KEY, encryptUser);

        state.loginLoading = false;
        state.loginSuccess = true;
        state.user = action.payload.data.user;
        state.token = action.payload.data.token["accessToken"];
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.loginLoading = false;
        state.loginSuccess = false;
        state.loginError = action.payload;
      });
  },
});

export const { logout, clearLogin } = authSlice.actions;
export default authSlice.reducer;
