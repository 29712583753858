import React, { useEffect } from "react";

/**
 * imported from antd
 */
import { Button, Form, Input } from "antd";

/**
 * imported from react-redux and react-router-dom
 */
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

/**
 * imported from store
 */
import { clearLogin, loginAsync } from "../../store/auth/authSlice";

/**
 * imported from utils
 */
import { antdAlert } from "../../utils/antd-alerts";
import { antdNotification } from "../../utils/antd-notifications";
import { checkUserByRole } from "../../utils/check-user-by-role";
import { validateMessages } from "../../utils/validate-messages";

export const Login = () => {
  /**
   * states
   */
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**
   * selectors
   */
  const { loginLoading, loginSuccess, loginError, token, user } = useSelector(
    (state) => state.auth
  );

  /**
   * methods
   */

  /**
   * effects
   */

  useEffect(() => {
    if (Object.keys(user).length !== 0) {
      console.log("Am here top");
      if (
        token &&
        (checkUserByRole("superadmin", user) ||
          checkUserByRole("warehouse", user) ||
          checkUserByRole("admin", user))
      ) {
        console.log("Am here also");
        navigate("/", { replace: true });
        antdAlert("success", "Logged in successfully");
        dispatch(clearLogin());
      } else {
        antdNotification("error", "You are not authorized", "topRight");
        dispatch(clearLogin());
      }
    } else {
      navigate("/login", { replace: true });
    }

    if (loginError && loginError.message === "Network Error") {
      antdNotification("error", "Check your internet connection", "topRight");
      dispatch(clearLogin());
    }
    if (loginError) {
      dispatch(clearLogin());
    }
  }, [loginError, loginSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * destructuring
   */

  return (
    <>
      <div className="font-poppins-light m-auto w-full pb-4">
        <h1 className="text-xl mb-6 text-center">
          Ashewa Delivery | <span className="text-primary">Login</span>
        </h1>
        {loginError &&
        loginError.message !== "Network Error" &&
        loginError.response
          ? antdNotification(
              "error",
              loginError.response.data.message,
              "topRight"
            )
          : null}
        <Form
          className="w-full"
          layout="vertical"
          validateMessages={validateMessages}
          onFinish={(values) => {
            dispatch(loginAsync(values));
          }}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Email is required",
              },
              { type: "email" },
            ]}
          >
            <Input
              placeholder="Email"
              className="!appearance-none !border !rounded-lg !w-full !py-2 !px-3 !text-gray-700 !leading-tight !focus:outline-none !focus:shadow-outline"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: "Password is required" },
              {
                min: 4,
                message: "Password must be at least 4 characters",
              },
            ]}
            className="!mb-4"
          >
            <Input.Password
              name="password"
              placeholder="Password"
              className="!h-[35.5px] !appearance-none !border !rounded-lg !w-full !py-2 !px-3 !text-gray-700 !leading-tight !focus:outline-none !focus:shadow-outline"
            />
          </Form.Item>
          <div className="mt-8">
            <Button
              type="primary"
              htmlType={"submit"}
              loading={loginLoading}
              className="!w-full !font-poppins-light text-white !hover:bg-blue-500 font-bold focus:outline-none !rounded-lg focus:shadow-outline"
            >
              Login
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};
