import React, { useEffect, useState, useRef } from "react";
import { Button, Space, Input } from "antd";
import {
  FileSyncOutlined,
  SearchOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { get, isEqual } from "lodash";
import { commaNumber } from "../../../utils/numbers";
import { CustomTable } from "../../../components/common/CustomTable";
import { fetchOngoingOrdersAsync } from "../../../store/order/orderSlice";
import CustomModal from "../../../components/common/CustomModal";
import DetailForOrder from "../../../components/order/DetailForOrder";
import DetailForWarehouse from "../../../components/warehouse/DetailForWarehouse";

const OrdersOngoingTabLayout = () => {
  /**
   * states
   */
  const dispatch = useDispatch();
  const [showOrderDetail, setShowOrderDetail] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [warehouseNoteDetail, setWarehouseNoteDetail] = useState({
    status: false,
    data: {},
  });

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  /**
   * selectors
   */
  const {
    ordersOngoing,
    fetchOngoingOrdersLoading,
    ongoingPage,
    ongoingLimit,
    ongoingTotal,
  } = useSelector((state) => state.order);

  /**
   * methods
   */

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      get(record, dataIndex)
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      isEqual(searchedColumn, dataIndex) ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleWarehouseNoteDetail = (status, data) => {
    setWarehouseNoteDetail({
      status,
      data: {
        ...warehouseNoteDetail.data,
        ...data,
      },
    });
  };

  /**
   * effects
   */
  useEffect(() => {
    dispatch(fetchOngoingOrdersAsync({ ongoingPage, ongoingLimit }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * destructuring
   */

  const columns = [
    {
      key: "orderVoucher",
      title: "Reference no.",
      dataIndex: "order_voucher",
      fixed: "left",
      width: "6%",
      ...getColumnSearchProps("order_voucher"),
      render: (data) => {
        return <span>{data}</span>;
      },
    },
    {
      key: "customerFullName",
      title: "Ordered by",
      dataIndex: "customerId",
      width: "6%",
      render: (data) => {
        return `${data?.first_name} ${data?.last_name}`;
      },
    },
    {
      key: "orderDate",
      title: "Order date",
      dataIndex: "createdAt",
      width: "10%",
      render: (data) => {
        return <div>{`${moment(data).format("dddd Do MMMM, YYYY")}`}</div>;
      },
    },
    {
      key: "totalPrice",
      title: "Price",
      dataIndex: "totalPrice",
      width: "4%",
      ...getColumnSearchProps("totalPrice"),
      render: (data) => {
        return <div>{`${commaNumber(data)} br`}</div>;
      },
    },
    {
      key: "cashOnDelivery",
      title: "Cash On Delivery",
      dataIndex: "is_cash_on_delivery",
      width: "8%",
      ...getColumnSearchProps("is_cash_on_delivery"),
      render: (data) => {
        return data ? (
          <span>Yes, cash need to be paid.</span>
        ) : (
          <span>No, payment already made.</span>
        );
      },
    },
    {
      key: "totalNumberOfProducts",
      title: "Quantity",
      dataIndex: "product_quantity",
      render: (data) => {
        return `${data.length}`;
      },
      width: "4%",
    },
    {
      key: "deliveryDate",
      title: "Delivery Date",
      dataIndex: "deliveryDate",
      render: (data) => {
        const deliveryDate = data;
        if (deliveryDate === "next_day") {
          return "Next Day";
        } else if (deliveryDate === "expedited") {
          return "Expedited";
        } else if (deliveryDate === "same_day") {
          return "Same Day";
        }
        return `${data.toString()[0].toUpperCase() + data.toString().slice(1)}`;
      },
      width: "6%",
    },
    {
      key: "deliveryLocation",
      title: "Delivery Location",
      dataIndex: "deliveryLocation",
      width: "8%",
      render: (data) => {
        return `${data}`;
      },
    },
    {
      key: "deliveryPrice",
      title: "Delivery Price",
      dataIndex: "deliveryPrice",
      width: "6%",
      render: (data) => {
        return `${commaNumber(data)} br`;
      },
    },
    {
      key: "deliveryType",
      title: "Delivery Type",
      dataIndex: "delivery_type",
      width: "8%",
      render: (data) => {
        return `${data}`;
      },
      ...getColumnSearchProps("delivery_type"),
    },
    {
      key: "customerPhoneNumber",
      title: "Customer Phone Number",
      dataIndex: ["customerId", "phone"],
      width: "8%",
      render: (data) => {
        if (data !== null) {
          const phone = data.toString().startsWith("251")
            ? `0${data.slice(3)}`
            : data;
          return <span>{phone}</span>;
        } else {
          return <></>;
        }
      },
    },
    {
      key: "deliveryFullName",
      title: "Deliverer Full Name",
      dataIndex: "delivererId",
      width: "8%",
      ...getColumnSearchProps(["delivererId", "firstName"]),
      render: (data) => {
        return `${data?.firstName} ${data?.lastName}`;
      },
    },
    {
      key: "deliveryPhoneNumber",
      title: "Deliverer Phone Number",
      dataIndex: ["delivererId", "phone"],
      width: "10%",
      render: (data) => {
        return <span>{data}</span>;
      },
    },
    {
      key: "actions",
      title: "Actions",
      dataIndex: "id",
      fixed: "right",
      width: "5%",
      render: (data, order) => {
        return (
          <Space>
            <Button
              type="primary"
              style={{ backgroundColor: "#1DA2A5", border: "#1DA2A5" }}
              onClick={() => {
                setShowOrderDetail(true);
                setInitialValues(order);
              }}
            >
              <FileSyncOutlined />
            </Button>
            <Button
              type="primary"
              onClick={() => handleWarehouseNoteDetail(true, order)}
            >
              <FileDoneOutlined />
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <CustomTable
          loading={fetchOngoingOrdersLoading}
          columns={columns}
          data={ordersOngoing}
          scrollValue={2300}
          pagination={{
            defaultPage: ongoingPage,
            defaultPageSize: ongoingLimit,
            total: ongoingTotal,
            onChange: (page, limit) => {
              dispatch(
                fetchOngoingOrdersAsync({
                  ongoingPage: page,
                  ongoingLimit: limit,
                })
              );
            },
          }}
        />
      </div>

      <CustomModal
        maskClosable={false}
        isModalOpen={showOrderDetail}
        onCancel={() => setShowOrderDetail(false)}
        width={1200}
      >
        <DetailForOrder order={initialValues} />
      </CustomModal>

      <CustomModal
        maskClosable={false}
        width={800}
        isModalOpen={warehouseNoteDetail.status}
        onCancel={() =>
          setWarehouseNoteDetail({
            ...warehouseNoteDetail,
            status: false,
          })
        }
      >
        <DetailForWarehouse data={warehouseNoteDetail.data} />
      </CustomModal>
    </>
  );
};

export default OrdersOngoingTabLayout;
