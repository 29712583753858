import { message } from "antd";

export const antdMessage = (type, text, duration = 2) => {
  switch (type.toLowerCase()) {
    case "success":
      return message.success({
        content: text,
        duration,
      });

    case "error":
      return message.error({
        content: text,
        duration,
      });

    case "warning":
      return message.warning({
        content: text,
        duration,
      });

    default:
      return message.info({
        content: text,
        duration,
      });
  }
};
