import { QRCodeSVG } from "qrcode.react";
import React from "react";

const DetailForQROrder = ({ orderId }) => {
  return (
    <>
      <div className="font-poppins m-auto w-full">
        <div className="my-3 mb-6 text-2xl text-center">
          <h1 className="text-3xl font-poppins-light text-gray-500">Scan QR</h1>
          <hr className="my-2 bg-gray-500 h-[0.15px]" />
        </div>
        <div className="my-8 flex justify-center items-center">
          <div className="flex flex-row items-center justify-center">
            <QRCodeSVG value={orderId} />
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailForQROrder;
