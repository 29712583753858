import React, { useEffect, useRef, useState } from "react";

import { Button, Input, notification, Popconfirm, Space, Tag } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

import { SearchOutlined } from "@ant-design/icons";
import { DoubleRightOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { get, isEqual } from "lodash";
import { CustomTable } from "../../components/common/CustomTable";
import {
  clearDeleteShelf,
  clearFetchShelves,
  deleteShelfAsync,
  fetchShelvesAsync,
} from "../../store/shelf/shelfSlice";
import { antdMessage } from "../../utils/antd-messages";
import { CustomDrawer } from "../../components/common/CustomDrawer";
import CreateShelf from "../../components/shelf/CreateShelf";
import { CustomButton } from "../../components/common/CustomButton";

const ShelfPageLayout = () => {
  /**
   * states
   */
  const dispatch = useDispatch();
  const [initialValue, setInitialValue] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [showCreateShelfModal, setShowCreateShelfModal] = useState(false);
  const [showEditShelfModal, setShowEditShelfModal] = useState(false);

  /**
   * selectors
   */
  const {
    shelves,
    fetchShelvesLoading,
    fetchShelvesError,
    deleteShelfSuccess,
    page,
    limit,
    total,
  } = useSelector((state) => state.shelf);

  /**
   * methods
   */

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        className="!rounded-lg"
      >
        <Input
          ref={searchInput}
          placeholder={"Search..."}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
          className="!rounded-lg"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
            className="!rounded-lg"
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
            className="!rounded-lg"
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#00B728" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      get(record, dataIndex)
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      isEqual(searchedColumn, dataIndex) ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#00B728",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  /**
   * effects
   */
  useEffect(() => {
    dispatch(fetchShelvesAsync({ page, limit }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deleteShelfSuccess) {
      antdMessage("error", "Shelf deleted successfully");
      dispatch(clearDeleteShelf());
    }
    if (fetchShelvesError) {
      dispatch(clearFetchShelves());
    }
  }, [fetchShelvesError, deleteShelfSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * destructuring
   */
  const columns = [
    {
      key: "shelfName",
      title: "Shelf name",
      dataIndex: "shelf_name",
      ...getColumnSearchProps("shelf_name"),
    },
    {
      key: "shelfRow",
      title: "Shelf rows",
      dataIndex: "shelf_row",
      render: (data) => {
        if (data !== null || data !== undefined) {
          return (
            <Tag color={"cyan"}>
              {data === 1 ? `${data} row` : `${data} rows`}
            </Tag>
          );
        }
      },
      ...getColumnSearchProps("shelf_row"),
    },
    {
      key: "shelfColumns",
      title: "Shelf columns",
      dataIndex: "shelf_column",
      render: (data) => {
        if (data !== null || data !== undefined) {
          return data.map((item, idx) => {
            return (
              <div key={idx} className="flex flex-col my-1">
                {
                  <>
                    <Space>
                      <span>
                        <Tag className="text-[#004D40] border-[#004D40]">
                          Row {idx + 1}
                        </Tag>
                        <DoubleRightOutlined style={{ color: "#004D40" }} />
                      </span>
                      <Tag className="text-[#004D40] border-[#004D40]">{`${item} columns`}</Tag>
                    </Space>
                  </>
                }
              </div>
            );
          });
        }
      },
    },
    {
      key: "warehouse",
      title: "Warehouse",
      dataIndex: ["warehouseId", "warehouse_name"],
      ...getColumnSearchProps(["warehouseId", "warehouse_name"]),
    },
    {
      key: "id",
      title: "Action",
      fixed: "right",
      dataIndex: "id",
      width: "9%",
      render: (id, shelf) => {
        return (
          <>
            <Button
              onClick={() => {
                setShowEditShelfModal(true);
                setInitialValue(shelf);
              }}
              type="primary"
              ghost
            >
              <EditOutlined />
            </Button>
            <span> </span>
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              okType="danger"
              cancelText="No"
              onConfirm={() => dispatch(deleteShelfAsync({ id: id }))}
              disabled
            >
              <Button disabled danger>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <>
      {fetchShelvesError && fetchShelvesError.message
        ? notification.error({
            message: "Error",
            placement: "topRight",
            description: `${fetchShelvesError.message}`,
          })
        : null}
      <div className="font-poppins flex flex-row justify-between items-center mb-8">
        <div className="font-poppins text-2xl font-poppins-semibold text-gray-500 mb-0">
          Shelves
        </div>
        <CustomButton
          type="primary"
          text={"Create Shelf"}
          className="!rounded-lg"
          onClick={() => setShowCreateShelfModal(true)}
          disabled={fetchShelvesLoading}
        />
      </div>
      <div>
        <CustomTable
          loading={fetchShelvesLoading}
          columns={columns}
          data={shelves}
          pagination={{
            defaultPage: page,
            defaultPageSize: limit,
            total: total,
            onChange: (page, limit) => {
              dispatch(fetchShelvesAsync({ page, limit }));
            },
          }}
        />
      </div>
      <CustomDrawer
        openDrawer={showCreateShelfModal}
        onClose={() => setShowCreateShelfModal(false)}
      >
        <CreateShelf
          onComplete={() => {
            setShowCreateShelfModal(false);
            dispatch(fetchShelvesAsync({ page, limit }));
          }}
        />
      </CustomDrawer>
      <CustomDrawer
        openDrawer={showEditShelfModal}
        onClose={() => setShowEditShelfModal(false)}
        width={450}
      >
        <CreateShelf
          isEdit={true}
          initialValue={initialValue}
          onComplete={() => {
            setShowEditShelfModal(false);
            dispatch(fetchShelvesAsync({ page, limit }));
          }}
        />
      </CustomDrawer>
    </>
  );
};

export default ShelfPageLayout;
