import React from "react";

const DetailForOrderedProduct = ({ product }) => {
  return (
    <>
      <div className="font-poppins-regular m-auto w-full">
        <h1 className="font-poppins-regular text-xl text-center text-gray-500">
          Ordered Product
        </h1>
        {product !== undefined ? (
          <div className="w-full flex flex-row shadow-md rounded-xl px-2 py-4 bg-white text-black">
            <div>
              <div className="flex flex-col">
                <div className="flex flex-row">
                  <span className="mr-2 font-bold">Parent category:</span>
                  <span>{product["productId"]["parent_category_name"]}</span>
                </div>
                <div className="flex flex-row">
                  <span className="mr-2 font-bold">Sub category:</span>
                  <span>{product["productId"]["sub_category_name"]}</span>
                </div>
              </div>
              <div className="flex flex-row">
                <span className="mr-2 font-bold">Product name:</span>
                <span className="font-bold">
                  {product["productId"]["name"]}
                </span>
              </div>
              <div className="flex flex-row">
                <span className="mr-2 font-bold">Quantity:</span>
                <span className="font-bold">{product["quantity"]}</span>
              </div>
              <div className="flex flex-row">
                <span className="mr-2 font-bold">Vendor store name:</span>
                <span>{product["productId"]["vendorId"]["store_name"]}</span>
              </div>
              <div className="flex flex-row">
                <span className="mr-2 font-bold">Vendor store location:</span>
                <span>
                  {product["productId"]["vendorId"]["store_locations"] &&
                    product["productId"]["vendorId"]["store_locations"].map(
                      (location, idx) => {
                        return <span key={idx}>{`${location},`}</span>;
                      }
                    )}
                </span>
              </div>

              <div className="flex flex-row">
                <span className="mr-2 font-bold">Vendor store phone:</span>
                <span>{product["productId"]["vendorId"]["phone"]}</span>
              </div>
            </div>
            <div>
              <img
                alt={product.productId.name}
                className="h-[10vw]"
                src={
                  !product.productId?.thumbnail?.includes("media")
                    ? `${process.env.REACT_APP_IMG_URL}/media/${product.productId.thumbnail}`
                    : `${process.env.REACT_APP_IMG_URL}/${product.productId.thumbnail}`
                }
              />
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default DetailForOrderedProduct;
