import { Button, Descriptions, Form, Select, Space } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  assignRequisitionAsync,
  clearAssignRequisition,
} from "../../store/requisition/requisitionSlice";
import {
  clearFetchDeliveryBoy,
  fetchDeliveryBoyAsync,
} from "../../store/user/userSlice";
import { antdMessage } from "../../utils/antd-messages";
import CustomModal from "../common/CustomModal";
import CustomTag from "../common/CustomTag";
import { DetailForRequisitionProduct } from "./DetailForRequisitionProduct";

const { Option } = Select;

export const DetailForRequisition = ({ onComplete, initialValue }) => {
  /**
   * states
   */
  const dispatch = useDispatch();
  const [orderProduct, setOrderProduct] = useState();
  const [showOrderProduct, setShowOrderProduct] = useState(false);

  /**
   * selectors
   */
  const { deliveryBoys, fetchDeliveryBoyLoading, fetchDeliveryBoyError } =
    useSelector((state) => state.user);

  const { assignRequisitionLoading, assignRequisitionSuccess } = useSelector(
    (state) => state.requisition
  );

  /**
   * methods
   */
  const handleSubmit = (values) => {
    const { deliveryboy } = values;
    if (!deliveryboy) {
      antdMessage("error", "Deliverer is required");
      return;
    }

    dispatch(
      assignRequisitionAsync({
        id: deliveryboy,
        requisitionId: initialValue.requisition._id,
      })
    );
  };

  /**
   * effects
   */
  useEffect(() => {
    dispatch(fetchDeliveryBoyAsync());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (assignRequisitionSuccess) {
      antdMessage("success", "Assign requisition successfully");
      dispatch(clearAssignRequisition());
      onComplete();
    }
    if (fetchDeliveryBoyError) {
      dispatch(clearFetchDeliveryBoy());
    }
  }, [fetchDeliveryBoyError, assignRequisitionSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * destructuring
   */

  return (
    <>
      <div className="font-poppins-regular m-auto w-full bg-white">
        <div className="pt-10">
          <Descriptions
            title="Requisition Information"
            bordered
            size="default"
            extra={
              <Form onFinish={handleSubmit} colon={false}>
                <Space>
                  <div className="w-3/4 flex flex-row justify-between mr-20">
                    <span className="font-poppins-regular">Status</span>
                    <CustomTag data={initialValue.requisition.status} />
                  </div>
                  {initialValue["requisition"]["status"] === "Requested" ? (
                    <>
                      <div className="w-[300px]">
                        <Form.Item name={"deliveryboy"} className={"!mb-0"}>
                          <Select
                            showSearch
                            placeholder="Select deliverer"
                            className={
                              "border-none w-full !h-[34px] !border !rounded-lg bg-white outline-none select-style-deliverer"
                            }
                            loading={fetchDeliveryBoyLoading || !deliveryBoys}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.includes(input)
                            }
                            filterSort={(optionA, optionB) =>
                              optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                            }
                          >
                            {deliveryBoys.map((deliveryBoy, idx) => {
                              return (
                                <Option key={idx} value={deliveryBoy._id}>
                                  {`${deliveryBoy.email} - ${deliveryBoy.phone}`}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                      <div>
                        <Button
                          htmlType="submit"
                          type="primary"
                          className="text-white font-poppins-regular !hover:bg-blue-500 font-bold focus:outline-none !rounded-lg focus:shadow-outline"
                          loading={assignRequisitionLoading}
                          disabled={assignRequisitionLoading}
                        >
                          Assign
                        </Button>
                      </div>
                    </>
                  ) : null}
                </Space>
              </Form>
            }
          >
            <Descriptions.Item label="Status">
              {initialValue["requisition"]["status"]}
            </Descriptions.Item>
            <Descriptions.Item label="Product quantity">
              {initialValue["requisition"]["product_quantity"].length}
            </Descriptions.Item>
            <Descriptions.Item label="Store name">
              {initialValue["data"]["store_name"]}
            </Descriptions.Item>
            <Descriptions.Item label="Vendor phone number">
              {initialValue["data"]["phone"]}
            </Descriptions.Item>
            <Descriptions.Item label="Ordered products">
              <div className="grid grid-cols-1 overflow-y-scroll h-[200px] delivery-scrollbar bg-white">
                {initialValue["requisition"]["product_quantity"].map(
                  (product) => {
                    return (
                      <div
                        key={product.id}
                        className="font-poppins-light w-fit h-fit px-3 py-1 rounded-full bg-gray-500 text-white cursor-pointer"
                        onClick={() => {
                          setOrderProduct(product);
                          setShowOrderProduct(true);
                        }}
                      >
                        {`${product.name} - ${product.quantity} pcs`}
                      </div>
                    );
                  }
                )}
              </div>
            </Descriptions.Item>
          </Descriptions>
        </div>
      </div>
      <CustomModal
        isModalOpen={showOrderProduct}
        onCancel={() => setShowOrderProduct(false)}
        width={500}
      >
        <DetailForRequisitionProduct product={orderProduct} />
      </CustomModal>
    </>
  );
};
