/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Descriptions, Form, message, Select, Space, Spin } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { commaNumber } from "../../utils/numbers";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { useRef } from "react";
import { Box, Input } from "@chakra-ui/react";

import {
  clearFetchOrdersOrdered,
  fetchOrderedOrdersAsync,
  fetchPendingOrdersAsync,
} from "../../store/order/orderSlice";
import {
  assignDeliveryBoyAsync,
  clearAssignDeliveryBoy,
  clearFetchDeliveryBoy,
  fetchDeliveryBoyAsync,
} from "../../store/user/userSlice";
import CustomModal from "../common/CustomModal";
import CustomTag from "../common/CustomTag";
import DetailForOrderedProduct from "./DetailForOrderedProduct";
import { antdMessage } from "../../utils/antd-messages";

const { Option } = Select;

const center = { lat: 8.9806, lng: 38.7578 };

const DetailForOrder = ({ order, onSuccess, onClick }) => {
  /**
   * states
   */
  const dispatch = useDispatch();
  const [initialPage, setInitialPage] = useState({
    current: 1,
  });
  const [orderProduct, setOrderProduct] = useState();
  const [showOrderProduct, setShowOrderProduct] = useState(false);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
    region: "ET",
  });

  const [map, setMap] = useState(/** @type google.maps.GoogleMap */ (null));
  const [directionResponse, setDirectionResponse] = useState(null);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const [location, setLocation] = useState({
    latitude: 0,
    longtiude: 0,
    name: null,
  });

  const originRef = useRef();

  const destinationRef = useRef();

  /**
   * selectors
   */
  const {
    deliveryBoys,
    fetchDeliveryBoyLoading,
    fetchDeliveryBoyError,
    assignDeliveryBoySuccess,
    assignDeliveryBoyError,
  } = useSelector((state) => state.user);

  const { orderedPage, orderedLimit } = useSelector((state) => state.order);

  /**
   * methods
   */

  const handleSubmit = (values) => {
    if (values.id === undefined && location.name === null) {
      message.error("Please select deliverer");
      return;
    }
    // if (location.latitude === 0 || location.longtiude === 0) {
    //   message.error("Choose Locations");
    //   return;
    // }
    const { id } = values;
    let deliverer_id = id;

    dispatch(
      assignDeliveryBoyAsync({
        orderId: order["order_id"],
        delivererId: deliverer_id,
        location: {
          latutude: "9.0156",
          longtiude: "38.7871",
          location: location,
        },
      })
    );
  };

  /**
   * effects
   */

  useEffect(() => {
    dispatch(fetchDeliveryBoyAsync());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (initialPage.current >= 2) {
      setInitialPage({ current: 2 });
    }
  }, [initialPage.current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (assignDeliveryBoySuccess) {
      antdMessage("success", "Assigned delivery boy successfully");
      if (onSuccess !== undefined) {
        onSuccess();
      }
      dispatch(fetchOrderedOrdersAsync({ orderedPage, orderedLimit }));
      dispatch(clearAssignDeliveryBoy());
      if (onClick !== undefined) {
        onClick(1);
      }
    }
  }, [assignDeliveryBoySuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fetchDeliveryBoyError) {
      dispatch(clearFetchDeliveryBoy());
    }
    if (assignDeliveryBoyError) {
      dispatch(clearAssignDeliveryBoy());
    }

    if (assignDeliveryBoySuccess) {
      dispatch(clearFetchOrdersOrdered());
    }
  }, [fetchDeliveryBoyError, assignDeliveryBoyError, assignDeliveryBoySuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * destructuring
   */

  if (!isLoaded) {
    <Spin />;
  }

  const calculateRoute = async () => {
    if (originRef.current.value === "" || destinationRef.current.value === "") {
      return;
    }
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: "Megenagna, Addis Ababa",
      destination: destinationRef.current.value,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });
    setLocation({
      ...location,
      latitude: results.routes[0].legs[0].end_location.lat(),
      longtiude: results.routes[0].legs[0].end_location.lng(),
      name: destinationRef.current.value,
    });
    setDirectionResponse(results);
    setDistance(results.routes[0].legs[0].distance.text);
    setDuration(results.routes[0].legs[0].duration.text);
  };

  const clearRoute = () => {
    setDirectionResponse(null);
    setDistance("");
    setDuration("");
    originRef.current.value = "";
    destinationRef.current.value = "";
  };

  return (
    <>
      <Form onFinish={handleSubmit} colon={false}>
        <div className="font-poppins m-auto w-full bg-white">
          <div className="pt-10">
            <Descriptions
              title="Order Information"
              bordered
              size="default"
              extra={
                <Space>
                  <div className="w-3/4 flex flex-row justify-between mr-20">
                    <span className="font-poppins-regular">Status</span>
                    <CustomTag data={order.status} />
                  </div>
                  {order["status"] === "Ordered" ? (
                    <>
                      <div>
                        <Button
                          type="primary"
                          disabled={initialPage.current > 1 ? false : true}
                          className={`${
                            initialPage.current > 1
                              ? "hover:!bg-[#1DA2A5]"
                              : null
                          } w-full bg-[#1DA2A5] text-white font-bold px-8 font-poppins-regular focus:outline-none !rounded-lg focus:shadow-outline`}
                          onClick={() =>
                            setInitialPage({
                              ...initialPage,
                              current: initialPage.current - 1,
                            })
                          }
                        >
                          Prev
                        </Button>
                      </div>
                      <div>
                        {initialPage.current === 1 && (
                          <Button
                            className="w-full text-white !hover:bg-blue-500 font-bold px-8 font-poppins-regular focus:outline-none !rounded-lg focus:shadow-outline"
                            type="primary"
                            onClick={() =>
                              setInitialPage({
                                ...initialPage,
                                current: initialPage.current + 1,
                              })
                            }
                          >
                            {"Next"}
                          </Button>
                        )}
                        {initialPage.current === 2 && (
                          <Button
                            htmlType={"submit"}
                            type="primary"
                            className="w-full text-white !hover:bg-blue-500 font-bold px-8 font-poppins-regular focus:outline-none !rounded-lg focus:shadow-outline"
                          >
                            {"Assign"}
                          </Button>
                        )}
                      </div>
                    </>
                  ) : null}
                </Space>
              }
            >
              {initialPage.current === 1 ? (
                <>
                  <Descriptions.Item label="Reference no.">
                    <div>{order["order_voucher"]}</div>
                  </Descriptions.Item>
                  <Descriptions.Item label="Ordered by">
                    {`${order["customerId"]["first_name"]} ${order["customerId"]["last_name"]}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Order Date">
                    <div>
                      {moment(order["createdAt"]).format("dddd Do MMMM, YYYY")}
                    </div>
                  </Descriptions.Item>
                  <Descriptions.Item label="Price">
                    {`${commaNumber(order["totalPrice"])} br`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Cash On Delivery">
                    {order["is_cash_on_delivery"] ? (
                      <div>{"Yes, cash need to be paid."}</div>
                    ) : (
                      <div>{"No, payment already made."}</div>
                    )}
                  </Descriptions.Item>

                  <Descriptions.Item label="Quantity">
                    {order["product_quantity"].length}
                  </Descriptions.Item>
                  <Descriptions.Item label="Delivery Address">
                    {order["deliveryDate"] === "next_day"
                      ? "Next Day"
                      : order["deliveryDate"] === "expedited"
                      ? "Expedited"
                      : order["deliveryDate"] === "same_day"
                      ? "Same Day"
                      : `${
                          order["deliveryDate"].toString()[0].toUpperCase() +
                          order["deliveryDate"].toString().slice(1)
                        }`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Delivery Location">
                    {order["deliveryLocation"]}
                  </Descriptions.Item>
                  <Descriptions.Item label="Delivery Price">
                    {order["deliveryPrice"]}
                  </Descriptions.Item>
                  <Descriptions.Item label="Delivery Type">
                    {order["delivery_type"]}
                  </Descriptions.Item>

                  <Descriptions.Item span={2} label="Customer Phone Number">
                    <div>{order["customerId"]["phone"]}</div>
                  </Descriptions.Item>
                  {(order["status"] === "Ongoing" ||
                    order["status"] === "Pending" ||
                    order["status"] === "Delivered") && (
                    <>
                      <Descriptions.Item label="Deliverer Name">
                        {`${order.delivererId?.firstName} ${order.delivererId?.lastName}`}
                      </Descriptions.Item>
                      <Descriptions.Item label="Deliverer Phone">
                        <span>{`${order.delivererId?.phone}`}</span>
                      </Descriptions.Item>
                      <Descriptions.Item label="Deliverer Email">
                        <span>{`${order.delivererId?.email}`}</span>
                      </Descriptions.Item>
                    </>
                  )}
                  <Descriptions.Item label="Ordered products">
                    <div className="grid grid-cols-1 overflow-y-scroll h-[200px] delivery-scrollbar bg-white">
                      {order["product_quantity"].map((product) => {
                        return (
                          <div
                            key={product.id}
                            className="font-poppins-light w-fit h-fit px-3 py-1 rounded-full bg-white text-black shadow-md cursor-pointer"
                            onClick={() => {
                              setOrderProduct(product);
                              setShowOrderProduct(true);
                            }}
                          >
                            {`${product.productId?.name} - ${product.quantity} pcs`}
                          </div>
                        );
                      })}
                    </div>
                  </Descriptions.Item>
                </>
              ) : null}
              {initialPage.current === 2 ? (
                <div className="mt-8">
                  <div className="w-full flex flex-row justify-center items-center">
                    <div className="w-full flex p-2 flex-row justify-center">
                      <div className="w-11/12 flex flex-col mr-2">
                        <div className="flex flex-row">
                          <div
                            className="flex flex-row h-8 cursor-pointer mx-4"
                            onClick={() => map.panTo(center)}
                          >
                            <img
                              alt="icon"
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAAD8ElEQVRYhe2WTWhcVRTHf+e9NzOZNJmMaRMk+FUNWNqFQWgpscioIISIgqYLQYpQXLgI3QkuBOmqCyuC0OJGFBWE2EUqVVsaiCZFm5hWC00WrUWrJrZNmkmbTGbexz0uXibJpPMmM7HixrN58M695/ze/5x77oP/7T82qWWxdvWmzGYOS32iW+vqWojFHETA93zrytUpf2bumJONvSUX++bvOkCw78ARaU69RmODg+eDMSvOfAH9eRyCAMAzqu/Fzhx/464AaG9vQnPOGC1bdvDkbiTTCWfPYz79AgouLOZh+mYx+UpgkVEZ6ugU3vYrxXfWBcg5Y9zXtkNeeQnat6KjP6FHP4bJvyrvU93JnnPDDLO70rqKCgT7DhyRttbXZf/L8ND96DtH0RMD6zGXmIFDseH+N2sG0K7elD6anuHZjCNdT6OH3q85OYCCb8/G74lqTCtqo9nMYRo2OZLpREfOl0vuCUwITChE1lnA8dP5g1H+SACpT3TjB/DDOfTdD9YElUuWM9dqDfdvt4b7t9vOXAuql8vFCVTJqXmxZgBN1rVgDOazY/BnScN54mR3yeBgdhlocDBrxW7tXKuEQblaWOS6W2irGUCcuA1AvlD6Xri0OnkJhFKiwm3fx1NDoNGnLRJguT3XAKAVGndNU7tqlkJpZJroEnieT74QDpnSJO2ayaTvWJ/JpFV4ZPU7T8PEtlhezQBc+W1KL4zfMeGAmPFSo6shNJNJGz/9o6wabAosmnBv3LImo9JE1kazc/2i9JZ1irQHftMN88QLlwGMTztoSayFwCcoKqD2V1F5IhWwc+agopHFE3BU2KbCtnIfMuuHqtuCNqUKG5gDY19OW8jZKH8ly5lgWf6kOKMNAwPXagYAEGE/Gq1COVPgmhueHEtEk0nn1UrrKwMM9Y+LRWT9ytkNr4C3dPwaLefklu9OTGwYAEDsWz2KVPWHs2gCsku1r7PsnJVv6Vlvz/oAg4N5W2LPQYVpAviqTLn5MKiINsXpuffCJwv/GABAhvq+VeTDKL8Ck24ef6ldGmz7o+Yzp76uKnY1i4pm9jz/iyIPr30/6eaZD8J7qN6O/frgyDdbq41ZlQJFk9nEYwIls3nac5eTJyzndmPK76glZm0AF/vmpT7ZAZqH8LK56bsAxMRyGxwebz59eu5fAwCQU5//bqk+peiCZ8Kax8XKpWz7mdbvT5b9KakYr9YNRdO9e+PuH0H3vHHt5geSx6Wvz91InPUANgHNQBOQBOqAOOHsF1bugOJ16wMukAcWgTlgBshtBKAV2LUOYLU2AlyvFQCgnlCBNKECCUIFYkv+4tNb9XSBAqECWcJTE6nA3/RinYGstoe4AAAAAElFTkSuQmCC"
                            ></img>
                          </div>
                          <div className="w-full flex flex-row">
                            <div className="w-1/2">
                              <Autocomplete>
                                <Box flexGrow={1}>
                                  <Input
                                    className="border w-10/12 rounded-lg h-[34.5px] px-2"
                                    type={"text"}
                                    placeholder="Origin"
                                    ref={originRef}
                                    value="Megenagna, Addis Ababa"
                                    disabled={true}
                                  />
                                </Box>
                              </Autocomplete>
                            </div>
                            <div className="w-1/2">
                              <Autocomplete>
                                <Box flexGrow={1}>
                                  <Input
                                    className="border w-10/12 rounded-lg h-[34.5px] px-2"
                                    type={"text"}
                                    placeholder="Destination"
                                    ref={destinationRef}
                                  />
                                </Box>
                              </Autocomplete>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-row mt-8 mb-4 ml-4">
                          <div className="w-1/4">
                            <span className="mr-2 font-poppins-semibold text-lg">
                              Distance
                            </span>
                            <span className="text-primary font-poppins-semibold text-lg">
                              {distance}
                            </span>
                          </div>
                          <div className="w-1/4 ml-3 flex flex-row justify-between">
                            <div>
                              <span className="mr-2 font-poppins-semibold text-lg">
                                Duration
                              </span>
                              <span className="text-primary font-poppins-semibold text-lg">
                                {duration}
                              </span>
                            </div>
                          </div>
                          <div className="w-2/5">
                            <Form.Item
                              label="Assign Deliverer"
                              name={"id"}
                              className="!mb-0 !mx-2"
                            >
                              <Select
                                showSearch
                                placeholder="Select deliverer"
                                className={
                                  "border-none w-full !h-[34px] !border !rounded-lg bg-white outline-none select-style-deliverer"
                                }
                                loading={
                                  fetchDeliveryBoyLoading || !deliveryBoys
                                }
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children.includes(input)
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                              >
                                {deliveryBoys.map((deliveryBoy, idx) => {
                                  return (
                                    <Option key={idx} value={deliveryBoy._id}>
                                      {`${deliveryBoy.email} - ${deliveryBoy.phone}`}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex flex-row gap-x-2">
                          <Button
                            type="primary"
                            className="text-white font-poppins-regular !hover:bg-blue-500 font-bold focus:outline-none !rounded-lg focus:shadow-outline"
                            onClick={calculateRoute}
                          >
                            Calculate Route
                          </Button>
                          <Button
                            danger
                            className="w-4/5 text-white font-poppins-regular !hover:bg-blue-500 font-bold focus:outline-none !rounded-lg focus:shadow-outline"
                            onClick={clearRoute}
                          >
                            Clear
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-[100%] h-[70vh]">
                    <GoogleMap
                      center={center}
                      zoom={15}
                      mapContainerStyle={{ width: "100%", height: "100%" }}
                      onLoad={(map) => setMap(map)}
                    >
                      <Marker position={center} />
                      {directionResponse && (
                        <DirectionsRenderer directions={directionResponse} />
                      )}
                    </GoogleMap>
                  </div>
                </div>
              ) : null}
            </Descriptions>
          </div>
        </div>
      </Form>
      <CustomModal
        centered
        isModalOpen={showOrderProduct}
        onCancel={() => setShowOrderProduct(false)}
        width={500}
      >
        <DetailForOrderedProduct product={orderProduct} />
      </CustomModal>
    </>
  );
};

export default DetailForOrder;
