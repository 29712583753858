import React from "react";

/**
 * imported from antd
 */
import { Button } from "antd";

export const CustomButton = ({
  htmlType,
  type,
  loading,
  disabled,
  text,
  onClick,
}) => {
  return (
    <>
      <Button
        type="primary"
        htmlType={htmlType}
        loading={loading}
        disabled={disabled}
        className="!font-poppins-light text-white !hover:bg-blue-500 font-bold focus:outline-none !rounded-lg focus:shadow-outline"
        onClick={htmlType !== "submit" ? onClick : null}
      >
        {text}
      </Button>
    </>
  );
};
