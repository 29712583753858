import { Button, Form, Select, message } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSelfPickupStatusAsync } from "../../store/order/orderSlice";
import { antdMessage } from "../../utils/antd-messages";
// import {
//   clearUpdateSelfPickupOrderStatus,
//   updateSelfPickupOrderStatusAsync,
// } from "../../store/order/action";

const UpdateSelfPickupOrderStatus = ({ onCancel, selfPickup }) => {
  /**
   * states
   */
  const dipsatch = useDispatch();
  const [, setState] = useState(false);

  /**
   * selectors
   */
  const {
    updateSelfPickupStatusLoading,
    updateSelfPickupStatusSuccess,
    updateSelfPickupStatusError,
  } = useSelector((state) => state.order);

  /**
   * methods
   */

  const handleChange = (value) => {
    setState(value);
  };

  const handleSubmit = () => {
    dipsatch(updateSelfPickupStatusAsync({ id: selfPickup._id }));
  };
  /**
   * effects
   */
  useEffect(() => {
    if (updateSelfPickupStatusSuccess) {
      antdMessage("success", "Order status updated successfully");
      //   dipsatch(clearUpdateSelfPickupOrderStatus());
      onCancel();
    }
    if (updateSelfPickupStatusError) {
      //   dipsatch(clearUpdateSelfPickupOrderStatus());
    }
  }, [updateSelfPickupStatusSuccess, updateSelfPickupStatusError]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * destructuring
   */
  return (
    <>
      <div className="font-poppins-regular m-auto w-full">
        <h1 className="text-2xl mb-6 text-center text-gray-500">
          Change Order Status
        </h1>
        <Form onFinish={handleSubmit}>
          <Form.Item name={"status"} className="!w-full">
            <Select
              placeholder="Order Status"
              labelInValue
              onChange={handleChange}
              options={[
                {
                  value: "Delivered",
                  label: "Completed",
                },
              ]}
              className={
                "border-none !w-full !h-[34px] !border !rounded-lg bg-white outline-none select-style"
              }
            />
          </Form.Item>
          <Button
            htmlType={"submit"}
            type="primary"
            className="w-full font-poppins-regular text-white !hover:bg-blue-500 font-bold focus:outline-none !rounded-lg focus:shadow-outline"
            loading={updateSelfPickupStatusLoading}
          >
            {"Submit"}
          </Button>
        </Form>
      </div>
    </>
  );
};

export default UpdateSelfPickupOrderStatus;
