import React, { useState } from "react";
import { Button, Space, Tooltip } from "antd";
import { FolderAddOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchReceivedRequisitionsAsync } from "../../../store/requisition/requisitionSlice";
import { CustomTable } from "../../../components/common/CustomTable";
import { CustomDrawer } from "../../../components/common/CustomDrawer";
import { CreateRequisitionToGRN } from "../../../components/requisition/CreateRequisitionToGRN";

const RequisitionReceivedTabLayout = () => {
  /**
   * states
   */
  const dispatch = useDispatch();
  const [showRequisitionDetailModal, setShowRequisitionDetailModal] =
    useState(false);
  const [initialValue, setInitialValue] = useState({});

  /**
   * selectors
   */
  const { fetchReceivedRequisitionsLoading, receivedRequisitions } =
    useSelector((state) => state.requisition);

  /**
   * methods
   */

  /**
   * effects
   */
  useEffect(() => {
    dispatch(fetchReceivedRequisitionsAsync());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * destructuring
   */

  const columns = [
    {
      key: "status",
      title: "Status",
      dataIndex: ["requisition", "status"],
      render: (data) => {
        return <span>{data}</span>;
      },
    },
    {
      key: "totalNumberOfRequestedProducts",
      title: "Product quantity",
      dataIndex: ["requisition", "product_quantity"],
      render: (data) => {
        if (data) {
          return `${data.length}`;
        }
        return <span>0</span>;
      },
    },
    {
      key: "storeName",
      title: "Store name",
      dataIndex: ["data", "store_name"],
      render: (data) => {
        return <span>{data}</span>;
      },
    },
    {
      key: "vendorPhoneNumber",
      title: "Vendor phone number",
      dataIndex: ["data", "phone"],

      render: (data) => {
        return <span>{data}</span>;
      },
    },
    {
      key: "referenceNumber",
      title: "Reference number",
      dataIndex: ["requisition", "refnumber"],

      render: (data) => {
        return <span>{data}</span>;
      },
    },
    // {
    //   key: "actions",
    //   title: "Action",
    //   dataIndex: "id",
    //   fixed: "right",
    //   width: "4%",
    //   render: (id, data) => {
    //     return (
    //       <Space>
    //         <Tooltip title="Add to GRN">
    //           <Button
    //             type="primary"
    //             style={{ backgroundColor: "#512DA8", border: "#512DA8" }}
    //             onClick={() => {
    //               setShowRequisitionDetailModal(true);
    //               setInitialValue(data);
    //             }}
    //           >
    //             <FolderAddOutlined />
    //           </Button>
    //         </Tooltip>
    //       </Space>
    //     );
    //   },
    // },
  ];
  return (
    <>
      <div>
        <CustomTable
          loading={fetchReceivedRequisitionsLoading}
          columns={columns}
          data={receivedRequisitions}
        />
        <CustomDrawer
          openDrawer={showRequisitionDetailModal}
          onClose={() => setShowRequisitionDetailModal(false)}
          width={450}
        >
          <CreateRequisitionToGRN initialValue={initialValue} />
        </CustomDrawer>
      </div>
    </>
  );
};

export default RequisitionReceivedTabLayout;
