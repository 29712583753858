import { Button, Image } from "antd";
import moment from "moment";
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import ashewaLogo from "../../assets/images/ashewa-transparent.png";

const DetailForDeliveryNote = ({ data }) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <>
      <div className="font-poppins m-auto w-full">
        <div className="flex flex-col">
          <div className="flex flex-row mb-4 w-2/4">
            <Button
              type="primary"
              className="w-2/4"
              style={{ backgroundColor: "#1DA2A5", border: "#1DA2A5" }}
              onClick={handlePrint}
            >
              Print
            </Button>
          </div>
          <div className="bg-[#EEE] p-5" ref={componentRef}>
            <div className="mt-6 flex flex-col justify-end items-end">
              <div className="flex flex-row w-full">
                <div className="w-1/2 mt-20">
                  {/* Warehouse information */}
                  <div className="flex flex-row justify-between">
                    <span>Order Reference no</span>
                    <span className="text-gray-500">
                      {data !== null || data !== {}
                        ? data["reference_no"]
                        : null}
                    </span>
                  </div>
                  <div className="flex flex-row justify-between">
                    <span>Order Total price</span>
                    <span className="text-gray-500">
                      {data !== null || data !== {}
                        ? `${data["totalPrice"]} ETB`
                        : null}
                    </span>
                  </div>
                </div>
                <div className="w-1/2 flex flex-col justify-end items-end">
                  {/* Ashewa Logo */}
                  <Image
                    alt="ashewa logo"
                    preview={false}
                    width={160}
                    src={ashewaLogo}
                  />
                  <span className="text-xl">Ashewa Technology Solution</span>
                  <span className="text-gray-500 italic">
                    Together We&apos;ll build our future!
                  </span>
                </div>
              </div>
              <div className="mt-4 flex w-full flex-row justify-between items-start">
                <div className="w-[40%]">
                  <div className="w-full flex flex-row justify-between">
                    <span className="text-right">Email</span>
                    <span className="text-gray-500">admin@ashewa.com</span>
                  </div>
                  <div className="w-full flex flex-row justify-between">
                    <span className="text-right">TIN</span>
                    <span className="text-gray-500">10004014</span>
                  </div>
                  <div className="w-full flex flex-row justify-between">
                    <span className="text-right">P.O box</span>
                    <span className="text-gray-500">1010</span>
                  </div>
                  <div className="w-full flex flex-row justify-between">
                    <span className="text-right">Phone number</span>
                    <span className="text-gray-500">0922612024</span>
                  </div>
                  <div className="w-full flex flex-row justify-between">
                    <span>Address</span>
                    <span className="text-gray-500 w-full text-right">
                      22 Town square mall, Addis Ababa, Ethiopia
                    </span>
                  </div>
                </div>
                <div className="w-[40%]">
                  <div className="mt-8 w-full flex flex-row justify-between">
                    <span className="text-right">Deliverer name</span>
                    <span className="text-gray-500">
                      {data !== null || data !== {}
                        ? `${data["delivererId"]["firstName"]} ${data["delivererId"]["lastName"]}`
                        : null}
                    </span>
                  </div>
                  <div className="w-full flex flex-row justify-between">
                    <span className="text-right">Deliverer email</span>
                    <span className="text-gray-500">
                      {data !== null || data !== {}
                        ? `${data["delivererId"]["email"]}`
                        : null}
                    </span>
                  </div>
                  <div className="w-full flex flex-row justify-between">
                    <span className="text-right">Deliverer phone</span>
                    <span className="text-gray-500">
                      {data !== null || data !== {}
                        ? `${data["delivererId"]["phone"]}`
                        : null}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8 w-full px-4 py-2  flex flex-row justify-center items-center border-[1.5px] border-gray-800">
              <span className="text-center text-xl font-bold">
                Delivery Note
              </span>
            </div>

            <div className="mt-8 grid grid-cols-2 justify-between">
              <div>
                <span>Customer name</span>
                <span className="ml-4 text-gray-500">
                  {data !== null || data !== {}
                    ? `${data["customerId"]["first_name"]} ${data["customerId"]["last_name"]}`
                    : null}
                </span>
              </div>
              <div className="mt-1 flex flex-row justify-end items-center">
                <span>Date</span>
                <span className="ml-4 text-gray-500">{`${moment(
                  new Date()
                )}`}</span>
              </div>
              <div>
                <span>Order Id</span>
                <span className="ml-4 text-gray-500">
                  {data !== null || data !== {} ? data["order_id"] : null}
                </span>
              </div>
              <div className="mt-1 flex flex-row justify-end items-center">
                <span>Cost-Centre</span>
                <span className="ml-4 text-gray-500">Ashewa Finance</span>
              </div>
            </div>

            {/* Table */}
            <div className="mt-8">
              <table className="table-auto border border-gray-800 w-full">
                <thead className="border border-gray-800">
                  <tr className="w-full">
                    <th className="w-[5%] border border-r border-gray-800">
                      #
                    </th>
                    <th className="w-3/12 border border-r border-gray-800">
                      Product name
                    </th>
                    <th className="w-2/12 border-r border-gray-800">Qty</th>
                    <th className="w-2/12 border-r border-gray-800">
                      Single price
                    </th>
                    <th className="w-2/12 border-r border-gray-800">
                      Total price
                    </th>
                    <th className="w-4/12 border-r border-gray-800 px-6">
                      Description
                    </th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {data !== null || data !== {}
                    ? data["product_quantity"].map((p, idx) => {
                        return (
                          <tr key={idx} className="border border-gray-800">
                            <td className="border-r border-gray-800">
                              {idx + 1}
                            </td>
                            <td className="border-r border-gray-800">
                              {p["productId"]["name"]}
                            </td>
                            <td className="border-r border-gray-800">
                              {"quantity"}
                            </td>
                            <td className="border-r border-gray-800">{"0"}</td>
                            <td className="border-r border-gray-800">{"0"}</td>
                            <td className="border-r border-gray-800">
                              {p["productId"]["description"]}
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>
            {/* Received and Checked by */}
            <div className="mt-8 flex flex-row justify-between">
              <div>
                <span>Authorized by</span>
                <span className="ml-4">
                  {data !== null || data !== {}
                    ? `${data["warehouseManager"]["firstName"]} ${data["warehouseManager"]["lastName"]}`
                    : null}
                </span>
              </div>
              <div>
                <span>Received by</span>
                <span className="ml-4">
                  {data !== null || data !== {}
                    ? `${data["customerId"]["first_name"]} ${data["customerId"]["last_name"]}`
                    : null}
                </span>
              </div>
            </div>

            <div className="mt-2 flex flex-row justify-between">
              <div>
                <span>Signature</span>
                <span>__________________________</span>
              </div>
              <div>
                <span>Signature</span>
                <span>_______________________</span>
              </div>
            </div>

            <div className="mt-8 flex flex-row justify-between">
              <div>
                <span>Delivered by</span>
                <span className="ml-4">
                  {data !== null || data !== {}
                    ? `${data["delivererId"]["firstName"]} ${data["delivererId"]["lastName"]}`
                    : null}
                </span>
              </div>
              <div></div>
            </div>

            <div className="mt-2 flex flex-row justify-between">
              <div>
                <span>Signature</span>
                <span>__________________________</span>
              </div>
            </div>

            {/* Footer */}
            <div className="mt-8">
              <hr className="my-2 bg-gray-500 h-[0.15px]" />
              <div className="w-full flex flex-col items-end justify-end">
                <span className="text-right">Ashewa Technology Solutions</span>
                <span className="text-right">&copy; All rights reserved</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailForDeliveryNote;
