import React from "react";

/**
 * imported from react-countup
 */
import Countup from "react-countup";

const DashboardCard = ({
  title,
  titleNumber,
  description,
  descriptionNumber,
  type,
}) => {
  let bgColor =
    type === "order"
      ? "bg-[#00C0EF]"
      : type === "product"
      ? "bg-[#00A75A]"
      : type === "warehouse"
      ? "bg-[#F39C11]"
      : type === "shelf"
      ? "bg-[#DC4B38]"
      : "bg-white";

  return (
    <>
      <div
        className={`${bgColor} mx-2 w-1/2 font-poppins-regular flex flex-col py-2 px-4 rounded-xl shadow-xl shadow-gray-300`}
      >
        <div className="h-24 flex flex-col justify-between my-2">
          <span className="text-lg font-bold text-white">{title}</span>
          <Countup
            end={titleNumber}
            duration={2}
            className="text-xl text-white"
          />
          <div className="flex flex-row text-gray-100 justify-start items-center">
            <span className="mr-2 text-base">{description}:</span>
            <span>
              <Countup
                end={descriptionNumber}
                duration={2}
                className="text-base text-white"
              />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardCard;
