import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../utils/api";

const INITIAL_STATE = {
  fetchDispatchNotesLoading: false,
  fetchDispatchNotesSuccess: false,
  fetchDispatchNotesError: null,

  page: 1,
  limit: 10,
  total: 0,

  dispatchNotes: [],
};

export const fetchDispatchNotesAsync = createAsyncThunk(
  "dispatchNote/fetchDispatchNotesAsync",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.get(`/orders`, {
        params: {
          paginate: true,
          page: data.page,
          perPage: data.limit,
          dispatched: true,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const dispatchNoteSlice = createSlice({
  name: "dispatchNote",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDispatchNotesAsync.pending, (state, action) => {
      state.fetchDispatchNotesLoading = true;
      state.fetchDispatchNotesSuccess = false;
      state.fetchDispatchNotesError = null;
    });
    builder.addCase(fetchDispatchNotesAsync.fulfilled, (state, action) => {
      state.fetchDispatchNotesLoading = false;
      state.fetchDispatchNotesSuccess = true;
      state.dispatchNotes = action.payload.data.orders;
      state.total = action.payload.data.count;
    });
    builder.addCase(fetchDispatchNotesAsync.rejected, (state, action) => {
      state.fetchDispatchNotesLoading = false;
      state.fetchDispatchNotesSuccess = false;
      state.fetchDispatchNotesError = action.payload;
    });
  },
});

export default dispatchNoteSlice.reducer;
