import React from "react";

/**
 * imported from antd
 */
import { Tag } from "antd";

const CustomTag = ({ data }) => {
  let color =
    data === "Delivered"
      ? "#87D068"
      : data === "Ordered" || data === "Requested"
      ? "#607D8B"
      : data === "Pending"
      ? "#FFC107"
      : data === "Ongoing"
      ? "#FF5722"
      : data === "Received"
      ? "#512DA8"
      : "#2DB7F5";

  return (
    <>
      <Tag color={color} className="w-20 text-center">
        {data}
      </Tag>
    </>
  );
};

export default CustomTag;
