import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../utils/api";

const INITIAL_STATE = {
  fetchShelvesLoading: false,
  fetchShelvesSuccess: false,
  fetchShelvesError: null,

  createShelfLoading: false,
  createShelfSuccess: false,
  createShelfError: null,

  editShelfLoading: false,
  editShelfSuccess: false,
  editShelfError: null,

  deleteShelfLoading: false,
  deleteShelfSuccess: false,
  deleteShelfError: null,

  shelves: [],

  page: 1,
  limit: 10,
  total: 0,
};

export const fetchShelvesAsync = createAsyncThunk(
  "shelf/fetchShelves",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.get(`/shelves`, {
        params: {
          page: data.page,
          perPage: data.limit,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createShelfAsync = createAsyncThunk(
  "shelf/createShelf",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.post(`/shelves`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editShelfAsync = createAsyncThunk(
  "shelf/editShelf",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.patch(`/shelves/${data.id}`, data.result, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteShelfAsync = createAsyncThunk(
  "shelf/deleteShelf",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.delete(`/shelves/${data.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        return data.id;
      }
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const shelfSlice = createSlice({
  name: "shelf",
  initialState: INITIAL_STATE,
  reducers: {
    clearFetchShelves: (state) => {
      state.fetchShelvesLoading = false;
      state.fetchShelvesSuccess = false;
      state.fetchShelvesError = null;
    },
    clearCreateShelf: (state) => {
      state.createShelfLoading = false;
      state.createShelfSuccess = false;
      state.createShelfError = null;
    },
    clearEditShelf: (state) => {
      state.editShelfLoading = false;
      state.editShelfSuccess = false;
      state.editShelfError = null;
    },
    clearDeleteShelf: (state) => {
      state.deleteShelfLoading = false;
      state.deleteShelfSuccess = false;
      state.deleteShelfError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShelvesAsync.pending, (state, action) => {
        state.fetchShelvesLoading = true;
        state.fetchShelvesSuccess = false;
        state.fetchShelvesError = null;
      })
      .addCase(fetchShelvesAsync.fulfilled, (state, action) => {
        state.fetchShelvesLoading = false;
        state.fetchShelvesSuccess = true;
        state.fetchShelvesError = null;
        state.shelves = action.payload.data.shelves;
        state.total = action.payload.data.count;
      })
      .addCase(fetchShelvesAsync.rejected, (state, action) => {
        state.fetchShelvesLoading = false;
        state.fetchShelvesSuccess = false;
        state.fetchShelvesError = action.payload;
      })
      .addCase(createShelfAsync.pending, (state, action) => {
        state.createShelfLoading = true;
        state.createShelfSuccess = false;
        state.createShelfError = null;
      })
      .addCase(createShelfAsync.fulfilled, (state, action) => {
        state.createShelfLoading = false;
        state.createShelfSuccess = true;
        state.createShelfError = null;
        state.shelves.push(action.payload.data);
      })
      .addCase(createShelfAsync.rejected, (state, action) => {
        state.createShelfLoading = false;
        state.createShelfSuccess = false;
        state.createShelfError = action.payload;
      })
      .addCase(editShelfAsync.pending, (state) => {
        state.editShelfLoading = true;
        state.editShelfSuccess = false;
        state.editShelfError = null;
      })
      .addCase(editShelfAsync.fulfilled, (state, action) => {
        state.editShelfLoading = false;
        state.editShelfSuccess = true;
        state.shelves = state.shelves.map((shelf) => {
          if (shelf.id === action.payload.data.data.id) {
            return action.payload.data.data;
          }
          return shelf;
        });
        state.editShelfError = null;
      })
      .addCase(editShelfAsync.rejected, (state, action) => {
        state.editShelfLoading = false;
        state.editShelfSuccess = false;
        state.editShelfError = action.payload;
      })
      .addCase(deleteShelfAsync.pending, (state) => {
        state.deleteShelfLoading = true;
        state.deleteShelfSuccess = false;
        state.deleteShelfError = null;
      })
      .addCase(deleteShelfAsync.fulfilled, (state, action) => {
        state.deleteShelfLoading = false;
        state.deleteShelfSuccess = true;
        state.deleteShelfError = null;
        state.shelves = state.shelves.filter(
          (shelf) => shelf.id !== action.payload
        );
      })
      .addCase(deleteShelfAsync.rejected, (state, action) => {
        state.deleteShelfLoading = false;
        state.deleteShelfSuccess = false;
        state.deleteShelfError = action.payload;
      });
  },
});

export const {
  clearFetchShelves,
  clearCreateShelf,
  clearEditShelf,
  clearDeleteShelf,
} = shelfSlice.actions;
export default shelfSlice.reducer;
