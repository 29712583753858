import React, { useEffect, useRef, useState } from "react";

import { Button, Space, Input } from "antd";
import { FileSyncOutlined, SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

import Highlighter from "react-highlight-words";
import { fetchDispatchNotesAsync } from "../../store/dispatch-note/dispatchNoteSlice";
import { CustomTable } from "../../components/common/CustomTable";
import DetailForDispatchNote from "../../components/dispatch-note/DetailForDispatchNote";
import CustomModal from "../../components/common/CustomModal";
import moment from "moment";
import { commaNumber } from "../../utils/numbers";

const DispatchNotePageLayout = () => {
  /**
   * states
   */
  const dispatch = useDispatch();
  const [dispatchNoteDetail, setDispatchNoteDetail] = useState({
    status: false,
    data: {},
    isEdit: false,
  });

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  /**
   * selectors
   */
  const { fetchDispatchNotesLoading, dispatchNotes, page, limit, total } =
    useSelector((state) => state.dispatchNote);

  /**
   * methods
   */
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        className="!rounded-lg"
      >
        <Input
          ref={searchInput}
          placeholder={"Search..."}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
          className="!rounded-lg"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
            className="!rounded-lg"
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
            className="!rounded-lg"
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#00B728" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#00B728",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleDispatchNoteDetail = (status, data) => {
    setDispatchNoteDetail({
      ...dispatchNoteDetail,
      status: status,
      data: {
        ...dispatchNoteDetail.data,
        ...data,
      },
    });
  };

  /**
   * effects
   */
  useEffect(() => {
    dispatch(fetchDispatchNotesAsync({ page, limit }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * destructuring
   */

  const columns = [
    {
      key: "referenceNo",
      title: "Reference no.",
      dataIndex: "reference_no",
      width: "10%",
      fixed: "left",
      ...getColumnSearchProps("reference_no"),
    },
    {
      key: "customerFullName",
      title: "Ordered by",
      dataIndex: "customerId",
      width: "12%",
      ...getColumnSearchProps(["customerId", "first_name"]),
      render: (data) => {
        return `${data["first_name"]} ${data["last_name"]}`;
      },
    },
    {
      key: "orderDate",
      title: "Order date",
      dataIndex: "createdAt",
      width: "20%",
      render: (data) => {
        return <div>{`${moment(data).format("dddd Do MMMM, YYYY")}`}</div>;
      },
    },
    {
      key: "orderQuantity",
      title: "Total Ordered Quantity",
      dataIndex: "product_quantity",
      width: "10%",
      render: (data) => {
        return <div>{data.length}</div>;
      },
    },
    {
      key: "totalPrice",
      title: "Total Price",
      dataIndex: "totalPrice",
      width: "12%",
      ...getColumnSearchProps("totalPrice"),
    },
    {
      key: "deliveryLocation",
      title: "Delivery Location",
      dataIndex: "deliveryLocation",
      width: "14%",
      ...getColumnSearchProps("deliveryLocation"),
    },
    {
      key: "deliveryType",
      title: "Delivery Type",
      dataIndex: "delivery_type",
      width: "12%",
      ...getColumnSearchProps("delivery_type"),
    },
    {
      key: "deliveryDate",
      title: "Delivery Date",
      dataIndex: "deliveryDate",
      width: "12%",
      ...getColumnSearchProps("deliveryDate"),
    },

    {
      key: "deliverer",
      title: "Deliverer Full Name",
      dataIndex: "delivererId",
      width: "12%",
      render: (delivererId) =>
        `${delivererId?.firstName ? delivererId.firstName : ""} ${
          delivererId?.lastName ? delivererId.lastName : ""
        }`,
    },
    {
      key: "deliverer",
      title: "Deliverer phone",
      dataIndex: ["delivererId", "phone"],
      width: "12%",
      ...getColumnSearchProps(["delivererId", "phone"]),
    },
    {
      key: "id",
      title: "Action",
      dataIndex: "_id",
      fixed: "right",
      width: "5%",
      render: (id, dataProduct) => {
        return (
          <>
            <Space>
              <Button
                type="primary"
                style={{ backgroundColor: "#1DA2A5", border: "#1DA2A5" }}
                onClick={() => handleDispatchNoteDetail(true, dataProduct)}
              >
                <FileSyncOutlined />
              </Button>
            </Space>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="font-poppins flex flex-row justify-between items-center mb-8">
        <div className="font-poppins text-2xl font-poppins-semibold text-gray-500 mb-0">
          Dispatch Notes
        </div>
      </div>
      <CustomTable
        loading={fetchDispatchNotesLoading}
        columns={columns}
        data={dispatchNotes}
        scrollValue={2200}
        pagination={{
          defaultPage: page,
          defaultPageSize: limit,
          total: total,
          onChange: (page, limit) => {
            dispatch(fetchDispatchNotesAsync({ page, limit }));
          },
        }}
      />

      <CustomModal
        maskClosable={false}
        width={800}
        isModalOpen={dispatchNoteDetail.status}
        onCancel={() =>
          setDispatchNoteDetail({
            ...dispatchNoteDetail,
            status: false,
          })
        }
      >
        <DetailForDispatchNote data={dispatchNoteDetail.data} />
      </CustomModal>
    </>
  );
};

export default DispatchNotePageLayout;
