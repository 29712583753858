import React, { useEffect, useState } from "react";
import { Button, Form, Input, InputNumber, Select, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { validateMessages } from "../../utils/validate-messages";
import {
  clearFetchWarehouses,
  fetchWarehousesAsync,
} from "../../store/warehouse/warehouseSlice";
import {
  clearCreateShelf,
  clearEditShelf,
  createShelfAsync,
  editShelfAsync,
} from "../../store/shelf/shelfSlice";
import { antdMessage } from "../../utils/antd-messages";

const { Option } = Select;

const CreateShelf = ({ onComplete, isEdit, initialValue }) => {
  /**
   * states
   */

  const dispatch = useDispatch();
  const [numberOfColumns, setNumberOfColumns] = useState(
    isEdit ? initialValue.shelf_row : 0
  );

  /**
   * selectors
   */
  const {
    createShelfLoading,
    createShelfSuccess,
    createShelfError,
    editShelfLoading,
    editShelfSuccess,
    editShelfError,
  } = useSelector((state) => state.shelf);

  const { warehouses, fetchWarehousesLoading, fetchWarehousesError } =
    useSelector((state) => state.warehouse);

  /**
   * methods
   */

  const columnComponent = (value) => {
    let columns = [];
    for (let i = 0; i < value; i++) {
      columns.push(
        <Form.Item
          key={`shelfCol${i}`}
          name={["shelfCols", `shelfCol${[i]}`]}
          label={`Row ${i + 1}`}
          rules={[
            {
              required: true,
              message: `Row ${i + 1}'s number of columns are required!`,
            },
            {
              type: "number",
              min: 1,
              message: "Column can't be negative",
            },
          ]}
          className={"!mx-2"}
        >
          <InputNumber
            min={1}
            max={10}
            placeholder={`Enter number of columns for row ${i + 1}`}
            className="!appearance-none !border !rounded-lg !w-full !text-gray-700 !leading-tight !focus:outline-none !focus:shadow-outline"
          />
        </Form.Item>
      );
    }
    return columns;
  };

  const columnEditComponent = (row) => {
    let columns = [];
    for (let i = 0; i < row; i++) {
      columns.push(
        <Form.Item
          key={`shelfCol${i}`}
          name={["shelf_column", `${i}`]}
          label={`Row ${i + 1}`}
          rules={[
            {
              required: true,
              message: `Number of columns for row ${i + 1} is required!`,
            },
            {
              type: "number",
              min: 1,
              message: "Column can't be negative",
            },
          ]}
          className={"!mx-2"}
        >
          <InputNumber
            min={1}
            max={10}
            placeholder={`Row ${i + 1}'s number of columns`}
            className="!appearance-none !border !rounded-lg !w-full !text-gray-700 !leading-tight !focus:outline-none !focus:shadow-outline"
          />
        </Form.Item>
      );
    }
    return columns;
  };

  const handleRowChange = (value) => {
    if (value !== null) {
      setNumberOfColumns(value);
    } else {
      setNumberOfColumns(0);
    }
  };

  const onSubmit = (values) => {
    if (!isEdit) {
      const { shelf_name, shelf_row, warehouseId, shelfCols } = values;
      const shelf_column = Object.values(shelfCols);
      const result = {
        shelf_name,
        shelf_row,
        shelf_column,
        warehouseId,
      };
      dispatch(createShelfAsync(result));
    } else {
      const { shelf_name, shelf_row, warehouseId, shelf_column } = values;
      const { _id } = warehouseId;
      const shelf_column_values = Object.values(shelf_column);
      const result = {
        shelf_name,
        shelf_row,
        shelf_column: shelf_column_values,
        warehouseId: _id,
      };

      dispatch(editShelfAsync({ id: initialValue.id, result: result }));
    }
  };

  /**
   * effects
   */
  useEffect(() => {
    dispatch(fetchWarehousesAsync());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isEdit) {
      columnComponent(numberOfColumns);
    } else {
      columnEditComponent(numberOfColumns, initialValue.shelf_column);
    }
  }, [numberOfColumns]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (createShelfSuccess) {
      antdMessage("success", "Shelf created successfully");
      dispatch(clearCreateShelf());
      onComplete();
    }

    if (editShelfSuccess) {
      antdMessage("warning", "Shelf updated successfully");
      dispatch(clearEditShelf());
      onComplete();
    }
  }, [createShelfSuccess, editShelfSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (createShelfError) {
      dispatch(clearCreateShelf());
    }
    if (editShelfError) {
      dispatch(clearEditShelf());
    }
    if (fetchWarehousesError) {
      dispatch(clearFetchWarehouses());
    }
  }, [createShelfError, editShelfError, fetchWarehousesError]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * destructuring
   */

  return (
    <>
      <div className="font-poppins-regular m-auto w-full">
        <h1 className="my-3 mb-4 text-center text-2xl font-poppins-semibold text-gray-500">
          {isEdit ? "Update Shelf" : "Create Shelf"}
        </h1>
        <hr className="mb-4" />
        <Form
          className="w-full"
          layout="vertical"
          validateMessages={validateMessages}
          onFinish={onSubmit}
          requiredMark={false}
          initialValues={{ ...initialValue }}
        >
          <Form.Item
            name={"shelf_name"}
            label="Shelf name"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="Shelf name"
              className="!appearance-none !border !rounded-lg !w-full !py-2 !px-3 !text-gray-700 !leading-tight !focus:outline-none !focus:shadow-outline"
            />
          </Form.Item>
          <div className="flex flex-col row-col-number-input">
            <Form.Item
              name={"shelf_row"}
              label="Shelf rows"
              rules={[
                { required: true },
                {
                  type: "number",
                  min: 1,
                  message: "Row can't be negative",
                },
              ]}
            >
              <InputNumber
                placeholder="Shelf rows"
                min={1}
                max={10}
                onChange={handleRowChange}
                className="!appearance-none !border !rounded-lg !w-full !text-gray-700 !leading-tight !focus:outline-none !focus:shadow-outline"
              />
            </Form.Item>
          </div>
          {numberOfColumns > 0 && (
            <div className="transition-all flex flex-col bg-white mb-4 rounded-lg shadow-md p-4 row-col-number-input">
              <Typography.Text className="my-2">
                Enter number of columns for the shelf rows
              </Typography.Text>
              <div className="overflow-y-scroll h-[200px] delivery-scrollbar">
                {isEdit
                  ? [
                      ...columnEditComponent(
                        numberOfColumns,
                        initialValue.shelf_column
                      ),
                    ]
                  : [...columnComponent(numberOfColumns)]}
              </div>
            </div>
          )}

          <Form.Item
            name={isEdit ? ["warehouseId", "_id"] : "warehouseId"}
            label="Select warehouse"
            rules={[{ required: true, message: "Warehouse is required!" }]}
          >
            {/* Warehouses */}
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="Select warehouse"
              loading={!isEdit && fetchWarehousesLoading}
              className={
                "border-none w-full !h-[34px] !border !rounded-lg bg-white outline-none select-style"
              }
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {warehouses.map((warehouse) => (
                <Option
                  key={warehouse.id}
                  value={warehouse.id}
                >{`${warehouse["warehouse_name"]} - ${warehouse["warehouse_location"]}`}</Option>
              ))}
            </Select>
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="w-full font-poppins-regular mt-2 text-white !hover:bg-blue-500 font-bold py-2 px-8 focus:outline-none !rounded-lg focus:shadow-outline"
            loading={isEdit ? editShelfLoading : createShelfLoading}
            disabled={isEdit ? editShelfLoading : createShelfLoading}
          >
            {isEdit ? "Update" : "Submit"}
          </Button>
        </Form>
      </div>
    </>
  );
};

export default CreateShelf;
