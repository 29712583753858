import React from "react";
import { Button, Table, Tag } from "antd";
// import StatusTag from "../../../components/common/StatusTag";
import { FileSyncOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchCompletedRequisitionsAsync } from "../../../store/requisition/requisitionSlice";
import { CustomTable } from "../../../components/common/CustomTable";

const RequisitionCompletedTabLayout = () => {
  /**
   * states
   */
  const dispatch = useDispatch();

  /**
   * selectors
   */
  const { fetchCompletedRequisitionsLoading, completedRequisitions } =
    useSelector((state) => state.requisition);

  /**
   * methods
   */

  /**
   * effects
   */
  useEffect(() => {
    dispatch(fetchCompletedRequisitionsAsync());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * destructuring
   */
  const columns = [
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      render: (data) => {
        return <span>{data}</span>;
      },
    },
    {
      key: "totalNumberOfRequestedProducts",
      title: "Product quantity",
      dataIndex: "product_quantity",
      render: (data) => {
        if (data) {
          return `${data.length}`;
        }
        return <span>0</span>;
      },
    },
    {
      key: "storeName",
      title: "Store name",
      dataIndex: ["vendorId", "store_name"],
      render: (data) => {
        return <span>{data}</span>;
      },
    },
    {
      key: "vendorPhoneNumber",
      title: "Vendor phone number",
      dataIndex: ["vendorId", "phone"],

      render: (data) => {
        return <span>{data}</span>;
      },
    },
    {
      key: "referenceNumber",
      title: "Reference number",
      dataIndex: "refnumber",

      render: (data) => {
        return <span>{data}</span>;
      },
    },
    {
      key: "actions",
      title: "Action",
      dataIndex: "id",
      fixed: "right",
      width: "4%",
      render: () => {
        return (
          <Button
            type="primary"
            style={{ backgroundColor: "#1DA2A5", border: "#1DA2A5" }}
          >
            <FileSyncOutlined />
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <CustomTable
          loading={fetchCompletedRequisitionsLoading}
          columns={columns}
          data={completedRequisitions}
        />
      </div>
    </>
  );
};

export default RequisitionCompletedTabLayout;
