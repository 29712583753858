import React, { useEffect, useState } from "react";
import { Button, Form, InputNumber, message, Select, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { validateMessages } from "../../utils/validate-messages";
import {
  fetchWarehousesAsync,
  fetchWarehouseShelvesAsync,
} from "../../store/warehouse/warehouseSlice";
import {
  fetchVendorProductsAsync,
  fetchVendorsAsync,
} from "../../store/server/vendor/vendorSlice";
import {
  clearCreateInventoryProduct,
  createInventoryProductAsync,
} from "../../store/inventory-product/inventoryProductSlice";
import { antdMessage } from "../../utils/antd-messages";
import {
  clearFetchWarehouseManagers,
  fetchWarehouseManagerAsync,
} from "../../store/user/userSlice";

const { Option } = Select;

const CreateGRN = ({ isEdit, onComplete }) => {
  /**
   * states
   */
  const dispatch = useDispatch();
  const [enableSearch, setEnableSearch] = useState({
    productSearch: false,
    shelfSearch: false,
  });

  const [selectedShelf, setSelectedShelf] = useState();

  const [stepper, setStepper] = useState({
    currentPage: 1,
    pages: 2,
    error: false,
    data: {
      product_id: "",
      shelf_id: "",
      stock_amount: 0,
      vendor_id: "",
      added_by: "",
      wearhouse_id: "",
      cell_details: "",
    },
  });

  /**
   * selectors
   */

  const { user } = useSelector((state) => state.auth);
  const {
    fetchVendorsLoading,
    vendors,
    vendorProducts,
    page: vendorPage,
    limit: vendorLimit,
    total: vendorTotal,
  } = useSelector((state) => state.vendor);
  const {
    warehouses,
    warehouseShelves,
    fetchWarehouseShelvesLoading,
    page: warehousePage,
    limit: warehouseLimit,
    total: warehouseTotal,
  } = useSelector((state) => state.warehouse);

  const {
    createInventoryProductLoading,
    createInventoryProductSuccess,
    createInventoryProductError,
  } = useSelector((state) => state.inventoryProduct);

  /**
   * methods
   */
  const rowColumnVision = (rows, cols) => {
    let table = [];
    for (let i = 0; i < rows; i++) {
      table.push(
        <Tag className="text-center text-info-main border-info-main !rounded-lg w-1/4">
          Row {i + 1} columns
        </Tag>
      );
      for (let j = 0; j < cols[i]; j++) {
        table.push(
          <div
            key={`${i}-${j}`}
            className={`w-${
              1 / cols[i]
            } bg-white rounded-md my-1 p-4 flex flex-row justify-between items-center shadow-lg`}
          >
            <div className="row-col-number-input flex flex-row items-center">
              <Form.Item
                key={`${i}-${j}`}
                name={[
                  "quantity",
                  `Placed-On-Row-${i + 1}-And-Column-${j + 1}`,
                ]}
                className={"!my-auto"}
              >
                <InputNumber
                  min={1}
                  max={20}
                  placeholder="Enter quantity"
                  className="!appearance-none !border !rounded-lg !w-3/4 !text-gray-700 !leading-tight !focus:outline-none !focus:shadow-outline"
                />
              </Form.Item>
            </div>
            <div
              key={`${i}-${j}`}
              className="flex flex-row justify-between items-center w-2/4"
            >
              <Tag className="!rounded-lg text-success-main border-success-main">{`Column ${
                j + 1
              }`}</Tag>
              <span>Status</span>
            </div>
          </div>
        );
      }
      table.push(<br />);
    }

    return table;
  };

  const handleVendorSearchChange = (value) => {
    setEnableSearch({ ...enableSearch, productSearch: true });
    setStepper({ ...stepper, data: { ...stepper.data, vendor_id: value } });
    dispatch(fetchVendorProductsAsync({ id: value }));
  };

  const handleWarehouseChange = (value) => {
    setEnableSearch({ ...enableSearch, shelfSearch: true });
    setStepper({ ...stepper, data: { ...stepper.data, wearhouse_id: value } });
    dispatch(fetchWarehouseShelvesAsync({ id: value }));
  };

  const handleWarehouseShelvesChange = (value) => {
    setStepper({ ...stepper, data: { ...stepper.data, shelf_id: value } });
    setSelectedShelf(warehouseShelves.find((shelf) => shelf.id === value));
  };

  const handleProductChange = (value) => {
    setStepper({ ...stepper, data: { ...stepper.data, product_id: value } });
  };

  const handleWarehouseManagerChange = (value) => {
    setStepper({
      ...stepper,
      data: { ...stepper.data, added_by: value },
    });
  };

  const handleValuesChange = (changedValues, allValues) => {
    const { quantity } = allValues;
    let stockAmountValue;
    if (quantity !== undefined) {
      stockAmountValue = Object.values(quantity).reduce((acc, curr) => {
        if (isNaN(acc)) acc = 0;
        if (isNaN(curr)) curr = 0;
        return acc + curr;
      }, 0);
    }
    setStepper({
      ...stepper,
      data: { ...stepper.data, stock_amount: stockAmountValue },
    });
  };

  const onSubmit = (values) => {
    if (Object.values(values).length === 0) {
      message.error({
        className: "font-poppins",
        content: "Please fill out all the fields",
      });
      return;
    }

    const { quantity } = values;
    const placedValue = Object.values(quantity).filter(
      (value) => value !== null
    );
    const placed = Object.keys(quantity).map((value, index) => {
      if (placedValue[index] === undefined) return null;
      return {
        row: value.split("-")[3],
        column: value.split("-")[6],
        quantity: placedValue[index],
      };
    });
    const filterPlaced = placed.filter((value) => value !== null);

    const result = filterPlaced.map((filteredText) => {
      return `${filteredText.quantity} products are stored on cell (${filteredText.row},${filteredText.column})`;
    });
    const stringResult = result.join("\n");

    const data = {
      productId: stepper.data.product_id,
      shelfId: stepper.data.shelf_id,
      stock_amount: stepper.data.stock_amount,
      vendorId: stepper.data.vendor_id,
      added_by: user.id,
      wearhouse_id: stepper.data.wearhouse_id,
      productPosition: stringResult,
    };

    dispatch(createInventoryProductAsync(data));
  };

  /**
   * effects
   */
  useEffect(() => {
    dispatch(
      fetchVendorsAsync({ page: vendorPage, limit: vendorLimit, _all: true })
    );
    dispatch(
      fetchWarehousesAsync({
        page: warehousePage,
        limit: warehouseLimit,
        _all: true,
      })
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (stepper.currentPage >= 2) {
      setStepper({
        ...stepper,
        currentPage: 2,
      });
    }
  }, [stepper.currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (createInventoryProductSuccess) {
      antdMessage("success", "GRN created successfully");
      dispatch(clearCreateInventoryProduct());
      onComplete();
    }
  }, [createInventoryProductSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (createInventoryProductError) {
      dispatch(clearCreateInventoryProduct());
    }
  }, [createInventoryProductError]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * destructuring
   */

  return (
    <>
      <div className="font-poppins-regular m-auto w-full">
        <h1 className="text-center text-2xl font-poppins-semibold text-gray-500">
          {isEdit
            ? "Update Goods Receiving Note"
            : `Create Goods Receiving Note`}
        </h1>
        <div className="my-3 mb-4 font-poppins-light text-gray-500">{`Step ${stepper.currentPage} of 2`}</div>
        <hr className="mb-4" />
        <Form
          className="w-full"
          layout="vertical"
          validateMessages={validateMessages}
          onFinish={onSubmit}
          requiredMark={false}
          onValuesChange={handleValuesChange}
        >
          {stepper.currentPage === 1 && (
            <div className="min-h-[180px]">
              <Form.Item name={"vendor"} label="Select vendor">
                {/* Select vendors */}
                <Select
                  showSearch
                  placeholder="Select vendor"
                  optionFilterProp="children"
                  onChange={handleVendorSearchChange}
                  className={
                    "border-none w-full !h-[34px] !border !rounded-lg bg-white outline-none select-style"
                  }
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  loading={fetchVendorsLoading}
                >
                  {vendors.map((vendor) => {
                    return (
                      <Option key={vendor.vendorId} value={vendor.vendorId}>
                        {`${vendor.store_name} - ${vendor.phone}`}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                name={"inventoryProduct"}
                label="Select product"
                rules={[{ required: true }]}
              >
                {/* Select vendor products */}
                <Select
                  showSearch
                  onChange={handleProductChange}
                  className={
                    "border-none w-full !h-[34px] !border !rounded-lg bg-white outline-none select-style"
                  }
                  disabled={!enableSearch.productSearch}
                  placeholder="Select product"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {vendorProducts.map((vendorProduct) => (
                    <Option
                      key={vendorProduct.id}
                      value={vendorProduct.productId}
                    >
                      {`${vendorProduct.name}`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name={"added_by"}
                label="Select warehouse manager"
                rules={[{ required: true }]}
              >
                {/* Select warehouse manager */}
                <Select
                  showSearch
                  placeholder="Select warehouse manager"
                  optionFilterProp="children"
                  onChange={handleWarehouseManagerChange}
                  className={
                    "border-none w-full !h-[34px] !border !rounded-lg bg-white outline-none select-style"
                  }
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  defaultValue={user.id}
                  disabled={true}
                >
                  <Option key={user.id} value={user.id}>
                    {`${user.firstName} ${user.lastName}`}
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item
                name={"warehouse"}
                label="Select warehouse"
                rules={[{ required: true }]}
              >
                {/* Select warehouse */}
                <Select
                  showSearch
                  placeholder="Select warehouse"
                  optionFilterProp="children"
                  onChange={handleWarehouseChange}
                  className={
                    "border-none w-full !h-[34px] !border !rounded-lg bg-white outline-none select-style"
                  }
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {warehouses.map((warehouse) => (
                    <Option key={warehouse.id} value={warehouse.id}>
                      {`${warehouse["warehouse_name"]} - ${warehouse["warehouse_location"]}`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <div className="flex flex-row w-full">
                <div className="w-full">
                  {/* Select Shelf */}
                  <Form.Item
                    name={"shelf"}
                    label="Select Shelf"
                    rules={[{ required: true }]}
                  >
                    {/* Select shelf */}
                    <Select
                      showSearch
                      placeholder="Select shelf"
                      optionFilterProp="children"
                      onChange={handleWarehouseShelvesChange}
                      className={
                        "border-none w-full !h-[34px] !border !rounded-lg bg-white outline-none select-style"
                      }
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      disabled={!enableSearch.shelfSearch}
                      loading={
                        fetchWarehouseShelvesLoading || !warehouseShelves
                      }
                    >
                      {warehouseShelves.map((warehouseShelf) => (
                        <Option
                          key={warehouseShelf.id}
                          value={warehouseShelf.id}
                        >
                          {`${warehouseShelf.shelf_name}`}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>
          )}

          {stepper.currentPage >= 2 && (
            <div className="relative">
              <div className="relative mb-4 flex justify-end w-full">
                <span className="text-lg text-info-main font-poppins-light mr-4">
                  Stock Amount: {stepper.data.stock_amount}
                </span>
              </div>

              <div
                className={
                  "relative text-gray-500 h-[50vh] flex flex-col overflow-y-auto delivery-scrollbar"
                }
              >
                {selectedShelf !== undefined ? (
                  [
                    ...rowColumnVision(
                      selectedShelf.shelf_row,
                      selectedShelf.shelf_column
                    ),
                  ]
                ) : (
                  <div className="text-gray-500 my-3 rounded-xl max-h-[264px] h-[264px] w-full shadow-md bg-white flex flex-col items-center justify-center">
                    <span className="text-lg">No shelf is selected!</span>
                  </div>
                )}
              </div>
            </div>
          )}

          <div
            className={`flex flex-row ${
              stepper.currentPage > 1 ? "justify-between" : "justify-end"
            }  items-center gap-x-2`}
          >
            {stepper.currentPage > 1 && (
              <Button
                type="primary"
                ghost
                onClick={() =>
                  setStepper({
                    ...stepper,
                    currentPage: stepper.currentPage - 1,
                  })
                }
                size="large"
                className="w-full font-poppins-regular mt-2 text-white !hover:bg-blue-500 font-bold py-2 px-8 focus:outline-none !rounded-lg focus:shadow-outline"
              >
                Back
              </Button>
            )}

            {stepper.currentPage >= 1 && (
              <Button
                type="primary"
                onClick={() =>
                  setStepper({
                    ...stepper,
                    currentPage: stepper.currentPage + 1,
                  })
                }
                size="large"
                className={`${
                  stepper.currentPage >= 2 ? "!hidden" : "!visible"
                } w-full font-poppins-regular mt-2 text-white !hover:bg-blue-500 font-bold py-2 px-8 focus:outline-none !rounded-lg focus:shadow-outline`}
              >
                Next
              </Button>
            )}

            {stepper.currentPage >= 2 && (
              <Button
                type="primary"
                htmlType={"submit"}
                size="large"
                className="w-full font-poppins-regular mt-2 text-white !hover:bg-blue-500 font-bold py-2 px-8 focus:outline-none !rounded-lg focus:shadow-outline"
                loading={createInventoryProductLoading}
                disabled={createInventoryProductLoading}
              >
                Submit
              </Button>
            )}
          </div>
        </Form>
      </div>
    </>
  );
};

export default CreateGRN;
