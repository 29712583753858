import { notification } from "antd";

export const antdNotification = (
  type,
  text,
  placement = "topRight",
  duration = 2
) => {
  switch (type.toLowerCase()) {
    case "success":
      return notification.success({
        message: "Success",
        className: "font-poppins-light",
        description: text,
        placement,
        duration,
      });

    case "error":
      return notification.error({
        message: "Error",
        className: "font-poppins-light",
        description: text,
        placement,
        duration,
      });

    case "warning":
      return notification.warning({
        message: "Warning",
        className: "font-poppins-light",
        description: text,
        placement,
        duration,
      });

    default:
      return notification.info({
        message: "Info",
        className: "font-poppins-light",
        duration,
        placement,
        description: text,
      });
  }
};
