import { Table } from "antd";

/**
 * imported from antd
 */
import React from "react";

export const CustomTable = ({
  size,
  loading,
  columns,
  data,
  scrollValue,
  pagination,
}) => {
  return (
    <>
      <Table
        size={size || "small"}
        bordered
        loading={loading || !data}
        columns={columns}
        dataSource={data}
        scroll={{ x: scrollValue }}
        pagination={pagination}
      />
    </>
  );
};
