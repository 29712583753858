import React from "react";

/**
 * imported from antd
 */
import { Drawer } from "antd";

export const CustomDrawer = ({
  title,
  placement,
  destroyOnClose,
  openDrawer,
  onClose,
  children,
  width,
}) => {
  return (
    <Drawer
      title={title}
      placement={placement}
      destroyOnClose={destroyOnClose || true}
      closable={false}
      onClose={onClose}
      open={openDrawer}
      width={width || 400}
    >
      {children}
    </Drawer>
  );
};
