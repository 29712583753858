import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../utils/api";

const INITIAL_STATE = {
  fetchAllRequistionsLoading: false,
  fetchAllRequistionsSuccess: false,
  fetchAllRequistionsError: null,

  fetchRequisitionLoading: false,
  fetchRequisitionSuccess: false,
  fetchRequisitionError: null,

  fetchRequestedRequisitionsLoading: false,
  fetchRequestedRequisitionsSuccess: false,
  fetchRequestedRequisitionsError: null,

  fetchPendingRequisitionsLoading: false,
  fetchPendingRequisitionsSuccess: false,
  fetchPendingRequisitionsError: null,

  fetchOngoingRequisitionsLoading: false,
  fetchOngoingRequisitionsSuccess: false,
  fetchOngoingRequisitionsError: null,

  fetchReceivedRequisitionsLoading: false,
  fetchReceivedRequisitionsSuccess: false,
  fetchReceivedRequisitionsError: null,

  fetchCompletedRequisitionsLoading: false,
  fetchCompletedRequisitionsSuccess: false,
  fetchCompletedRequisitionsError: null,

  assignRequisitionLoading: false,
  assignRequisitionSuccess: false,
  assignRequisitionError: null,

  allRequisitions: [],
  requestedRequisitions: [],
  pendingRequistions: [],
  ongoingRequisitions: [],
  receivedRequisitions: [],
  completedRequisitions: [],

  requisition: {},
  page: 1,
  limit: 10,
  total: 0,
};

export const fetchAllRequisitionsAsync = createAsyncThunk(
  "requisition/fetchAllRequisitionsAsync",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.get(
        `/requisition/requisitions`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchRequisitionAsync = createAsyncThunk(
  "requisition/fetchRequisitionAsync",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.get(
        `/api/requisitions/${data.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchRequestedRequisitionsAsync = createAsyncThunk(
  "requisition/fetchRequestedRequisitionsAsync",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.get(
        `/requisition/status/Requested`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchPendingRequisitionsAsync = createAsyncThunk(
  "requisition/fetchPendingRequisitionsAsync",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.get(
        `/requisition/status/Pending`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchOngoingRequisitionsAsync = createAsyncThunk(
  "requisition/fetchOngoingRequisitionsAsync",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.get(
        `/requisition/status/Ongoing`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchReceivedRequisitionsAsync = createAsyncThunk(
  "requisition/fetchReceivedRequisitionsAsync",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.get(
        `/requisition/status/Received`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchCompletedRequisitionsAsync = createAsyncThunk(
  "requisition/fetchCompletedRequisitionsAsync",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.get(
        `/requisition/status/Completed`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const assignRequisitionAsync = createAsyncThunk(
  "requisition/assignRequisition",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.patch(
        `/requisition/assignrequisitions/${data.requisitionId}`,
        { delivererid: data.id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const requisitionSlice = createSlice({
  name: "requisition",
  initialState: INITIAL_STATE,
  reducers: {
    clearAssignRequisition: (state) => {
      state.assignRequisitionLoading = false;
      state.assignRequisitionSuccess = false;
      state.assignRequisitionError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllRequisitionsAsync.pending, (state) => {
        state.fetchAllRequistionsLoading = true;
        state.fetchAllRequistionsSuccess = false;
        state.fetchAllRequistionsError = null;
      })
      .addCase(fetchAllRequisitionsAsync.fulfilled, (state, action) => {
        state.fetchAllRequistionsLoading = false;
        state.fetchAllRequistionsSuccess = true;
        state.fetchAllRequistionsError = null;
        state.allRequisitions = action.payload.data;
        //    TODO: Pagination
      })
      .addCase(fetchAllRequisitionsAsync.rejected, (state, action) => {
        state.fetchAllRequistionsLoading = false;
        state.fetchAllRequistionsSuccess = false;
        state.fetchAllRequistionsError = action.payload;
      })
      .addCase(fetchRequisitionAsync.pending, (state) => {
        state.fetchRequisitionLoading = true;
        state.fetchRequisitionSuccess = false;
        state.fetchRequisitionError = null;
      })
      .addCase(fetchRequisitionAsync.fulfilled, (state, action) => {
        state.fetchRequisitionLoading = false;
        state.fetchRequisitionSuccess = true;
        state.fetchRequisitionError = null;
        state.requisition = action.payload.data;
      })
      .addCase(fetchRequisitionAsync.rejected, (state, action) => {
        state.fetchRequisitionLoading = false;
        state.fetchRequisitionSuccess = false;
        state.fetchRequisitionError = action.payload;
      })
      .addCase(fetchRequestedRequisitionsAsync.pending, (state) => {
        state.fetchRequestedRequisitionsLoading = true;
        state.fetchRequestedRequisitionsSuccess = false;
        state.fetchRequestedRequisitionsError = null;
      })
      .addCase(fetchRequestedRequisitionsAsync.fulfilled, (state, action) => {
        state.fetchRequestedRequisitionsLoading = false;
        state.fetchRequestedRequisitionsSuccess = true;
        state.fetchRequestedRequisitionsError = null;
        state.requestedRequisitions = action.payload.data;
        // TODO: Pagination
      })
      .addCase(fetchRequestedRequisitionsAsync.rejected, (state, action) => {
        state.fetchRequestedRequisitionsLoading = false;
        state.fetchRequestedRequisitionsSuccess = false;
        state.fetchRequestedRequisitionsError = action.payload;
      })
      .addCase(fetchPendingRequisitionsAsync.pending, (state) => {
        state.fetchPendingRequisitionsLoading = true;
        state.fetchPendingRequisitionsSuccess = false;
        state.fetchPendingRequisitionsError = null;
      })
      .addCase(fetchPendingRequisitionsAsync.fulfilled, (state, action) => {
        state.fetchPendingRequisitionsLoading = false;
        state.fetchPendingRequisitionsSuccess = true;
        state.fetchPendingRequisitionsError = null;
        state.pendingRequistions = action.payload.data;
        //   TODO: Pagination
      })
      .addCase(fetchPendingRequisitionsAsync.rejected, (state, action) => {
        state.fetchPendingRequisitionsLoading = false;
        state.fetchPendingRequisitionsSuccess = false;
        state.fetchPendingRequisitionsError = action.payload;
      })
      .addCase(fetchOngoingRequisitionsAsync.pending, (state) => {
        state.fetchOngoingRequisitionsLoading = true;
        state.fetchOngoingRequisitionsSuccess = false;
        state.fetchOngoingRequisitionsError = null;
      })

      .addCase(fetchOngoingRequisitionsAsync.fulfilled, (state, action) => {
        state.fetchOngoingRequisitionsLoading = false;
        state.fetchOngoingRequisitionsSuccess = true;
        state.fetchOngoingRequisitionsError = null;
        state.ongoingRequisitions = action.payload.data;
        //    TODO: Pagination
      })

      .addCase(fetchOngoingRequisitionsAsync.rejected, (state, action) => {
        state.fetchOngoingRequisitionsLoading = false;
        state.fetchOngoingRequisitionsSuccess = false;
        state.fetchOngoingRequisitionsError = action.payload;
      })

      .addCase(fetchReceivedRequisitionsAsync.pending, (state) => {
        state.fetchReceivedRequisitionsLoading = true;
        state.fetchReceivedRequisitionsSuccess = false;
        state.fetchReceivedRequisitionsError = null;
      })

      .addCase(fetchReceivedRequisitionsAsync.fulfilled, (state, action) => {
        state.fetchReceivedRequisitionsLoading = false;
        state.fetchReceivedRequisitionsSuccess = true;
        state.fetchReceivedRequisitionsError = null;
        state.receivedRequisitions = action.payload.data;
      })

      .addCase(fetchReceivedRequisitionsAsync.rejected, (state, action) => {
        state.fetchReceivedRequisitionsLoading = false;
        state.fetchReceivedRequisitionsSuccess = false;
        state.fetchReceivedRequisitionsError = action.payload;
      })

      .addCase(fetchCompletedRequisitionsAsync.pending, (state) => {
        state.fetchCompletedRequisitionsLoading = true;
        state.fetchCompletedRequisitionsSuccess = false;
        state.fetchCompletedRequisitionsError = null;
      })

      .addCase(fetchCompletedRequisitionsAsync.fulfilled, (state, action) => {
        state.fetchCompletedRequisitionsLoading = false;
        state.fetchCompletedRequisitionsSuccess = true;
        state.fetchCompletedRequisitionsError = null;
        state.completedRequisitions = action.payload.data;
        //    TODO: Pagination
      })

      .addCase(fetchCompletedRequisitionsAsync.rejected, (state, action) => {
        state.fetchCompletedRequisitionsLoading = false;
        state.fetchCompletedRequisitionsSuccess = false;
        state.fetchCompletedRequisitionsError = action.payload;
      })

      .addCase(assignRequisitionAsync.pending, (state) => {
        state.assignRequisitionLoading = true;
        state.assignRequisitionSuccess = false;
        state.assignRequisitionError = null;
      })

      .addCase(assignRequisitionAsync.fulfilled, (state, action) => {
        state.assignRequisitionLoading = false;
        state.assignRequisitionSuccess = true;
        state.assignRequisitionError = null;
      })

      .addCase(assignRequisitionAsync.rejected, (state, action) => {
        state.assignRequisitionLoading = false;
        state.assignRequisitionSuccess = false;
        state.assignRequisitionError = action.payload;
      });
  },
});

export const { clearAssignRequisition } = requisitionSlice.actions;
export default requisitionSlice.reducer;
