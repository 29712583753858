import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../utils/api";

const INITIAL_STATE = {
  fetchCustomersLoading: false,
  fetchCustomersSuccess: false,
  fetchCustomersError: null,

  customers: [],
  page: 1,
  limit: 10,
  total: 0,
};

export const fetchCustomersAsync = createAsyncThunk(
  "customer/fetchCustomersAsync",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.get(`/customers`, {
        params: {
          page: data.page,
          perPage: data.limit,
        },
        headers: { Authorization: `Bearer ${token}` },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const customerSlice = createSlice({
  name: "customer",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCustomersAsync.pending, (state) => {
      state.fetchCustomersLoading = true;
    });
    builder.addCase(fetchCustomersAsync.fulfilled, (state, action) => {
      state.fetchCustomersLoading = false;
      state.fetchCustomersSuccess = true;
      state.customers = action.payload.data.customers;
      state.total = action.payload.data.count;
      // TODO: Pagination
    });
    builder.addCase(fetchCustomersAsync.rejected, (state, action) => {
      state.fetchCustomersLoading = false;
      state.fetchCustomersError = action.payload;
    });
  },
});

export default customerSlice.reducer;
