import React, { useEffect, useState, useRef } from "react";

import {
  Button,
  Popconfirm,
  Tag,
  Space,
  notification,
  Input,
  Tooltip,
} from "antd";
import {
  FileSyncOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";

import Highlighter from "react-highlight-words";
import { get, isEqual } from "lodash";
import {
  clearDeleteInventoryProduct,
  clearFetchInventoryProducts,
  deleteInventoryProductAsync,
  fetchInventoryProductsAsync,
} from "../../store/inventory-product/inventoryProductSlice";
import { CustomTable } from "../../components/common/CustomTable";
import { antdMessage } from "../../utils/antd-messages";
import { CustomButton } from "../../components/common/CustomButton";
import { CustomDrawer } from "../../components/common/CustomDrawer";
import CreateGRN from "../../components/inventory-product(GRN)/CreateGRN";
import CustomModal from "../../components/common/CustomModal";
import DetailForGRN from "../../components/inventory-product(GRN)/DetailForGRN";
import moment from "moment";

const GoodsReceivingNotePageLayout = () => {
  /**
   * states
   */
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [goodReceivingNoteDetail, setGoodReceivingNoteDetail] = useState({
    status: false,
    data: {},
    isEdit: false,
  });
  const [showCreateGRNModal, setShowCreateGRNModal] = useState(false);

  /**
   * selectors
   */
  const {
    inventoryProducts,
    fetchInventoryProductsLoading,
    fetchInventoryProductsError,
    deleteInventoryProductLoading,
    deleteInventoryProductSuccess,
    deleteInventoryProductError,
    page,
    limit,
    total,
  } = useSelector((state) => state.inventoryProduct);

  /**
   * methods
   */

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        className="!rounded-lg"
      >
        <Input
          ref={searchInput}
          placeholder={"Search..."}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
          className="!rounded-lg"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
            className="!rounded-lg"
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
            className="!rounded-lg"
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#00B728" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      get(record, dataIndex)
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      isEqual(searchedColumn, dataIndex) ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#00B728",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleGoodReceivingDetail = (status, data) => {
    setGoodReceivingNoteDetail({
      ...goodReceivingNoteDetail,
      status: status,
      data: {
        ...goodReceivingNoteDetail.data,
        ...data,
      },
    });
  };

  /**
   * effects
   */
  useEffect(() => {
    dispatch(fetchInventoryProductsAsync({ page, limit }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deleteInventoryProductSuccess) {
      antdMessage("success", "Inventory product deleted successfully");
      dispatch(fetchInventoryProductsAsync({ page, limit }));
      dispatch(clearDeleteInventoryProduct());
    }

    if (fetchInventoryProductsError) {
      dispatch(clearFetchInventoryProducts());
    }

    if (deleteInventoryProductError) {
      dispatch(clearDeleteInventoryProduct());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    deleteInventoryProductSuccess,
    fetchInventoryProductsError,
    deleteInventoryProductError,
  ]);

  /**
   * destructuring
   */
  const columns = [
    {
      key: "date",
      title: "Stored Date",
      dataIndex: "createdAt",
      width: "9%",
      fixed: "left",
      render: (data) => {
        return <span>{moment(data).format("dddd Do MMMM, YYYY")}</span>;
      },
    },
    {
      key: "warehouse",
      title: "Warehouse",
      dataIndex: ["shelfId", "warehouseId", "warehouse_name"],
      render: (data) => {
        if (data !== undefined) return <Tag color={"gold"}>{data}</Tag>;
      },
      width: "8%",
      ...getColumnSearchProps(["shelfId", "warehouseId", "warehouse_name"]),
    },
    {
      key: "productShelf",
      title: "Shelf",
      dataIndex: ["shelfId", "shelf_name"],
      render: (data) => {
        return <Tag color={"purple"}>{data}</Tag>;
      },
      width: "8%",
      ...getColumnSearchProps(["shelfId", "shelf_name"]),
    },
    {
      key: "product",
      title: "Product name",
      dataIndex: ["productId", "name"],
      width: "8%",
      render: (data) => {
        return <div>{data}</div>;
      },
      ...getColumnSearchProps(["productId", "name"]),
    },
    {
      key: "parentCategory",
      title: "Parent category name",
      dataIndex: ["productId", "parent_category_name"],
      width: "8%",
      render: (data) => {
        return <div>{data}</div>;
      },
      ...getColumnSearchProps(["productId", "parent_category_name"]),
    },
    {
      key: "categoryName",
      title: "Category name",
      dataIndex: ["productId", "category_name"],
      width: "8%",
      render: (data) => {
        return <div>{data}</div>;
      },
      ...getColumnSearchProps(["productId", "category_name"]),
    },
    {
      key: "subCategoryName",
      title: "Sub category name",
      dataIndex: ["productId", "sub_category_name"],
      width: "8%",
      render: (data) => {
        return <div>{data}</div>;
      },
      ...getColumnSearchProps(["productId", "sub_category_name"]),
    },
    {
      key: "productPrice",
      title: "Product price",
      dataIndex: ["productId", "price"],
      width: "8%",
      render: (data) => {
        return <div>{`${data} Br`}</div>;
      },
      ...getColumnSearchProps(["productId", "price"]),
    },
    {
      key: "stockAmount",
      title: "Stock amount",
      dataIndex: "stock_amount",
      width: "8%",
      ...getColumnSearchProps("stock_amount"),
    },

    {
      key: "productPosition",
      title: "Product stored position",
      dataIndex: "productPosition",
      width: "10%",
      render: (data) => {
        if (data !== null || data !== undefined) {
          const positionDescriptionValues = data.split("\n");
          return positionDescriptionValues.map((positionDescription, idx) => {
            return (
              <div key={idx} className="my-1">
                {positionDescription}
              </div>
            );
          });
        } else {
          return <Tag>{data}</Tag>;
        }
      },
    },
    {
      key: "vendor",
      title: "Vendor phone",
      dataIndex: ["productId", "vendorId", "phone"],
      width: "8%",
      render: (data) => {
        if (data !== undefined) {
          return (
            <div>
              <Space direction="vertical">
                <Tag>{data}</Tag>
              </Space>
            </div>
          );
        }
      },
      ...getColumnSearchProps(["productId", "vendorId", "phone"]),
    },
    {
      key: "id",
      title: "Action",
      dataIndex: "id",
      fixed: "right",
      width: "5%",
      render: (id, dataProduct) => {
        return (
          <>
            <Space>
              {/* <Button
                type="primary"
                ghost
                onClick={() => {
                  dispatch(showEditInventoryProductModal());
                }}
              >
                <EditOutlined />
              </Button> */}
              <Popconfirm
                title="Are you sure?"
                okText="Yes"
                okType="danger"
                cancelText="No"
                onConfirm={() =>
                  dispatch(deleteInventoryProductAsync({ id: id }))
                }
                disabled={deleteInventoryProductLoading}
              >
                <Button danger>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
              <Tooltip title="Click here to print GRN">
                <Button
                  type="primary"
                  style={{ backgroundColor: "#1DA2A5", border: "#1DA2A5" }}
                  onClick={() => handleGoodReceivingDetail(true, dataProduct)}
                >
                  <FileSyncOutlined />
                </Button>
              </Tooltip>
            </Space>
          </>
        );
      },
    },
  ];

  return (
    <>
      {fetchInventoryProductsError && fetchInventoryProductsError.message
        ? notification.error({
            message: "Error",
            placement: "topRight",
            description: `${fetchInventoryProductsError.message}`,
          })
        : null}
      <div className="font-poppins flex flex-row justify-between items-center mb-8">
        <div className="font-poppins text-2xl font-poppins-semibold text-gray-500 mb-0">
          Goods Receiving Notes
        </div>
        <CustomButton
          type="primary"
          text={"Create GRN Product"}
          className="!rounded-lg"
          onClick={() => setShowCreateGRNModal(true)}
          disabled={fetchInventoryProductsLoading}
        />
      </div>

      <CustomTable
        loading={fetchInventoryProductsLoading}
        columns={columns}
        data={inventoryProducts}
        scrollValue={2200}
        pagination={{
          defaultPage: page,
          defaultPageSize: limit,
          total: total,
          onChange: (page, limit) => {
            dispatch(fetchInventoryProductsAsync({ page, limit }));
          },
        }}
      />

      <CustomDrawer
        openDrawer={showCreateGRNModal}
        onClose={() => setShowCreateGRNModal(false)}
        width={450}
      >
        <CreateGRN
          onComplete={() => {
            setShowCreateGRNModal(false);
            dispatch(fetchInventoryProductsAsync({ page, limit }));
          }}
        />
      </CustomDrawer>

      <CustomModal
        maskClosable={false}
        width={800}
        isModalOpen={goodReceivingNoteDetail.status}
        onCancel={() =>
          setGoodReceivingNoteDetail({
            ...goodReceivingNoteDetail,
            status: false,
          })
        }
      >
        <DetailForGRN data={goodReceivingNoteDetail.data} />
      </CustomModal>
    </>
  );
};

export default GoodsReceivingNotePageLayout;
