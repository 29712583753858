import React, { useEffect, useState } from "react";

import { Tabs, Space, Tag } from "antd";

import { useDispatch, useSelector } from "react-redux";

/**
 * _pages
 */
import RequisitionAllTabLayout from "./_pages/RequisitionAllTabLayout";
import RequisitionRequestedTabLayout from "./_pages/RequisitionRequestedTabLayout";
import RequisitionPendingTabLayout from "./_pages/RequisitionPendingTabLayout";
import RequisitionOngoingTabLayout from "./_pages/RequisitionOngoingTabLayout";
import RequisitionCompletedTabLayout from "./_pages/RequisitionCompletedTabLayout";
import RequisitionReceivedTabLayout from "./_pages/RequisitionReceivedTabLayout";

import {
  fetchAllRequisitionsAsync,
  fetchCompletedRequisitionsAsync,
  fetchOngoingRequisitionsAsync,
  fetchPendingRequisitionsAsync,
  fetchReceivedRequisitionsAsync,
  fetchRequestedRequisitionsAsync,
} from "../../store/requisition/requisitionSlice";

const RequisitionPageLayout = () => {
  /**
   * states
   */
  const dispatch = useDispatch();
  const [tabChanged, setTabChanged] = useState(false);

  /**
   * selectors
   */
  const {
    allRequisitions,
    requestedRequisitions,
    pendingRequistions,
    ongoingRequisitions,
    receivedRequisitions,
    completedRequisitions,
  } = useSelector((state) => state.requisition);

  /**
   * methods
   */

  /**
   * effects
   */
  useEffect(() => {
    dispatch(fetchAllRequisitionsAsync());
    dispatch(fetchRequestedRequisitionsAsync());
    dispatch(fetchPendingRequisitionsAsync());
    dispatch(fetchOngoingRequisitionsAsync());
    dispatch(fetchReceivedRequisitionsAsync());
    dispatch(fetchCompletedRequisitionsAsync());
  }, [tabChanged]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * destructuring
   */

  console.log(allRequisitions);

  const items = [
    {
      label: (
        <Space>
          <span className="tab-styles-2 font-poppins-regular">
            {"Requested"}
          </span>
          <Tag className="text-[#607D8B] border-[#607D8B]">
            {requestedRequisitions.length}
          </Tag>
        </Space>
      ),
      key: "0",
      children: <RequisitionRequestedTabLayout />,
    },
    {
      label: (
        <Space>
          <span className="tab-styles-3 font-poppins-regular">{"Pending"}</span>
          <Tag className="text-[#FFC107] border-[#FFC107]">
            {pendingRequistions.length}
          </Tag>
        </Space>
      ),
      key: "1",
      children: <RequisitionPendingTabLayout />,
    },
    {
      label: (
        <Space>
          <span className="tab-styles-4 font-poppins-regular">{"Ongoing"}</span>
          <Tag className="text-[#FF5722] border-[#FF5722]">
            {ongoingRequisitions.length}
          </Tag>
        </Space>
      ),
      key: "2",
      children: <RequisitionOngoingTabLayout />,
    },
    {
      label: (
        <Space>
          <span className="tab-styles-0 font-poppins-regular">
            {"Received"}
          </span>
          <Tag className="text-[#512DA8] border-[#512DA8]">
            {receivedRequisitions.length}
          </Tag>
        </Space>
      ),
      key: "3",
      children: <RequisitionReceivedTabLayout />,
    },
  ];

  return (
    <>
      <div className="flex flex-row justify-between items-center mb-8">
        <div className="text-2xl font-poppins-semibold text-gray-500 mb-0">
          Requisitions
        </div>
      </div>

      <div className="font-poppins-regular">
        <Tabs
          onChange={(activeKey) => setTabChanged(activeKey)}
          items={items}
        />
      </div>
    </>
  );
};

export default RequisitionPageLayout;
