import React, { useEffect, useState } from "react";
import { Button, Space } from "antd";
import { FileSyncOutlined, FileDoneOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { CustomTable } from "../../../components/common/CustomTable";
import { fetchDeliveredOrdersAsync } from "../../../store/order/orderSlice";
import CustomModal from "../../../components/common/CustomModal";
import DetailForOrder from "../../../components/order/DetailForOrder";
import DetailForDeliveryNote from "../../../components/warehouse/DetailForDeliveryNote";

const OrdersDeliveredTabLayout = () => {
  /**
   * states
   */
  const dispatch = useDispatch();
  const [showOrderDetail, setShowOrderDetail] = useState(false);
  const [initialValues, setInitialValues] = useState(false);
  const [deliveryNoteDetail, setDeliveryNoteDetail] = useState({
    status: false,
    data: {},
  });

  /**
   * selectors
   */
  const {
    ordersDelivered,
    fetchDeliveredOrdersLoading,
    deliveredPage,
    deliveredLimit,
    deliveredTotal,
  } = useSelector((state) => state.order);

  /**
   * methods
   */
  const handleDeliveryNoteDetail = (status, data) => {
    setDeliveryNoteDetail({
      status,
      data: {
        ...deliveryNoteDetail.data,
        ...data,
      },
    });
  };

  /**
   * effects
   */
  useEffect(() => {
    dispatch(fetchDeliveredOrdersAsync({ deliveredPage, deliveredLimit }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * destructuring
   */

  const columns = [
    {
      key: "orderVoucher",
      title: "Reference no.",
      dataIndex: "order_voucher",
      fixed: "left",
      width: "5%",
      render: (data) => {
        return <span>{data}</span>;
      },
    },
    {
      key: "customerFullName",
      title: "Ordered by",
      dataIndex: "customerId",
      width: "6%",
      render: (data) => {
        return `${data["first_name"]} ${data["last_name"]}`;
      },
    },
    {
      key: "orderDate",
      title: "Order date",
      dataIndex: "createdAt",
      width: "8%",
      render: (data) => {
        return <div>{`${moment(data).format("dddd Do MMMM, YYYY")}`}</div>;
      },
    },
    {
      key: "totalPrice",
      title: "Price",
      dataIndex: "totalPrice",
      width: "4%",
    },
    {
      key: "cashOnDelivery",
      title: "Cash on delivery",
      dataIndex: "is_cash_on_delivery",
      width: "8%",
      render: (data) => {
        return data ? (
          <span>Yes, cash need to be paid.</span>
        ) : (
          <span>No, payment already made.</span>
        );
      },
    },
    {
      key: "totalNumberOfProducts",
      title: "Quantity",
      dataIndex: "product_quantity",
      render: (data) => {
        return `${data.length}`;
      },
      width: "8%",
    },
    {
      key: "deliveryDate",
      title: "Delivery Date",
      dataIndex: "deliveryDate",
      render: (data) => {
        return `${data}`;
      },
      width: "6%",
    },
    {
      key: "deliveryLocation",
      title: "Delivery location",
      dataIndex: "deliveryLocation",
      width: "8%",
      render: (data) => {
        return `${data}`;
      },
    },
    {
      key: "deliveryPrice",
      title: "Delivery price",
      dataIndex: "deliveryPrice",
      width: "6%",
      render: (data) => {
        return `${data}`;
      },
    },
    {
      key: "deliveryType",
      title: "Delivery type",
      dataIndex: "delivery_type",
      width: "8%",
      render: (data) => {
        return `${data}`;
      },
    },
    {
      key: "customerPhoneNumber",
      title: "Customer phone number",
      dataIndex: ["customerId", "phone"],
      width: "8%",
      render: (data) => {
        return <span>{data}</span>;
      },
    },
    {
      key: "deliveryPhoneNumber",
      title: "Deliverer phone number",
      dataIndex: ["delivererId", "phone"],
      width: "8%",
      render: (data) => {
        return <span>{data}</span>;
      },
    },
    {
      key: "actions",
      title: "Actions",
      dataIndex: "id",
      fixed: "right",
      width: "4%",
      render: (data, order) => {
        return (
          <Space>
            <Button
              type="primary"
              style={{ backgroundColor: "#1DA2A5", border: "#1DA2A5" }}
              onClick={() => {
                setShowOrderDetail(true);
                setInitialValues(order);
              }}
            >
              <FileSyncOutlined />
            </Button>
            <Button
              type="primary"
              onClick={() => handleDeliveryNoteDetail(true, order)}
            >
              <FileDoneOutlined />
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <CustomTable
          loading={fetchDeliveredOrdersLoading}
          columns={columns}
          data={ordersDelivered}
          scrollValue={2700}
          pagination={{
            defaultPage: deliveredPage,
            defaultPageSize: deliveredLimit,
            total: deliveredTotal,
            onChange: (page, limit) => {
              dispatch(
                fetchDeliveredOrdersAsync({
                  deliveredPage: page,
                  deliveredLimit: limit,
                })
              );
            },
          }}
        />
      </div>

      <CustomModal
        maskClosable={false}
        isModalOpen={showOrderDetail}
        onCancel={() => setShowOrderDetail(false)}
        width={1200}
      >
        <DetailForOrder order={initialValues} />
      </CustomModal>
      <CustomModal
        maskClosable={false}
        width={800}
        isModalOpen={deliveryNoteDetail.status}
        onCancel={() =>
          setDeliveryNoteDetail({
            ...deliveryNoteDetail,
            status: false,
          })
        }
      >
        <DetailForDeliveryNote data={deliveryNoteDetail.data} />
      </CustomModal>
    </>
  );
};

export default OrdersDeliveredTabLayout;
