import "./App.css";
import { Layout } from "antd";
import { useState } from "react";
import Sidebar from "./components/common/Sidebar";
import Navbar from "./components/common/Navbar";
import { HomeWrapper } from "./components/common/HomeWrapper";
import { Route, Routes } from "react-router-dom";
/**
 * pages
 */
import { DashboardPageLayout } from "./pages/dashboard/DashboardPageLayout";
import { UserPageLayout } from "./pages/user/UserPageLayout";
import CustomerPageLayout from "./pages/customer/CustomerPageLayout";
import VendorPageLayout from "./pages/vendor/VendorPageLayout";
import ProductPageLayout from "./pages/product/ProductPageLayout";
import DispatchNotePageLayout from "./pages/dispatch-note/DispatchNotePageLayout";
import SelfPickupPageLayout from "./pages/self-pickup/SelfPickupPageLayout";
import WarehousePageLayout from "./pages/warehouse/WarehousePageLayout";
import ShelfPageLayout from "./pages/shelf/ShelfPageLayout";
import GoodsReceivingNotePageLayout from "./pages/inventory-product (GRN)/GoodsReceivingNote";
import OrderPageLayout from "./pages/order/OrderPageLayout";
import RequisitionPageLayout from "./pages/requisition/RequisitionPageLayout";
import { BinCardPageLayout } from "./pages/report/BinCardPageLayout";
import { StockCardPageLayout } from "./pages/report/StockCardPageLayout";

const App = () => {
  /**
   * states
   */
  const [collapsed, setCollapsed] = useState(false);

  /**
   * selectors
   */

  /**
   * methods
   */
  const toggle = () => {
    setCollapsed(!collapsed);
  };

  /**
   * effects
   */

  /**
   * destructured variables
   */
  return (
    <>
      <Layout className="!min-h-screen">
        <Sidebar collapsed={collapsed} />
        <Layout
          className={`${
            collapsed ? "ml-16" : "ml-[210px]"
          } transition-all site-layout`}
        >
          <Navbar collapsed={collapsed} toggle={toggle} />
          <HomeWrapper>
            <Routes>
              <Route path="/" element={<DashboardPageLayout />} />
              <Route path="requisitions" element={<RequisitionPageLayout />} />
              <Route path="warehouses" element={<WarehousePageLayout />} />
              <Route path="shelves" element={<ShelfPageLayout />} />
              <Route
                path="goods-receiving-notes"
                element={<GoodsReceivingNotePageLayout />}
              />
              <Route path="orders" element={<OrderPageLayout />} />
              <Route
                path="self-pickup-orders"
                element={<SelfPickupPageLayout />}
              />
              <Route
                path="dispatch-notes"
                element={<DispatchNotePageLayout />}
              />
              <Route path="vendors" element={<VendorPageLayout />} />
              <Route path="products" element={<ProductPageLayout />} />
              <Route path="customers" element={<CustomerPageLayout />} />
              <Route path="reports">
                <Route path="bin-card" element={<BinCardPageLayout />} />
                <Route path="stock-card" element={<StockCardPageLayout />} />
              </Route>
              <Route path="users" element={<UserPageLayout />} />
            </Routes>
          </HomeWrapper>
        </Layout>
      </Layout>
    </>
  );
};

export default App;
