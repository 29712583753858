import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../utils/api";

const INITIAL_STATE = {
  fetchUsersLoading: false,
  fetchUsersSuccess: false,
  fetchUsersError: null,

  createUserLoading: false,
  createUserSuccess: false,
  createUserError: null,

  updateUserLoading: false,
  updateUserSuccess: false,
  updateUserError: null,

  fetchDeliveryBoyLoading: false,
  fetchDeliveryBoySuccess: false,
  fetchDeliveryBoyError: null,

  assignDeliveryBoyLoading: false,
  assignDeliveryBoySuccess: false,
  assignDeliveryBoyError: null,

  fetchWarehouseManagersLoading: false,
  fetchWarehouseManagersSuccess: false,
  fetchWarehouseManagersError: null,

  deleteUserLoading: false,
  deleteUserSuccess: false,
  deleteUserError: null,

  page: 1,
  limit: 10,
  total: 0,

  users: [],
  deliveryBoys: [],
  warehouseManagers: [],
};

export const fetchUsersAsync = createAsyncThunk(
  "user/fetchUsersAsync",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.get(`/users`, {
        params: {
          page: data.page,
          perPage: data.limit,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createUserAsync = createAsyncThunk(
  "user/createUserAsync",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.post(`/users`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateUserAsync = createAsyncThunk(
  "user/updateUserAsync",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.patch(`/users/${data.id}`, data.result, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchWarehouseManagerAsync = createAsyncThunk(
  "user/fetchWarehouseManagerAsync",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.get(`/users`, {
        params: {
          role: "warehouse",
        },
        headers: {
          "  Authorization": `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchDeliveryBoyAsync = createAsyncThunk(
  "user/fetchDeliveryBoyAsync",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.get(`/orders/list-deliverer-status/ACTIVE`, {
        headers: {
          // params: {
          //   role: "delivery",
          // },
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const assignDeliveryBoyAsync = createAsyncThunk(
  "user/assignDeliveryBoyAsync",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.patch(
        `/orders/assign-deliverer/${data.orderId}`,
        {
          delivererId: data.delivererId,
          location: data.location,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteUserAsync = createAsyncThunk(
  "user/deleteUserAsync",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.delete(`/users/${data.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 204) {
        return data.id;
      }
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: INITIAL_STATE,
  reducers: {
    clearFetchUsers: (state) => {
      state.fetchUsersLoading = false;
      state.fetchUsersSuccess = false;
      state.fetchUsersError = null;
    },
    clearAssignDeliveryBoy: (state) => {
      state.assignDeliveryBoyLoading = false;
      state.assignDeliveryBoySuccess = false;
      state.assignDeliveryBoyError = null;
    },
    clearFetchDeliveryBoy: (state) => {
      state.fetchDeliveryBoyLoading = false;
      state.fetchDeliveryBoySuccess = false;
      state.fetchDeliveryBoyError = null;
    },
    clearFetchWarehouseManagers: (state) => {
      state.fetchWarehouseManagersLoading = false;
      state.fetchWarehouseManagersSuccess = false;
      state.fetchWarehouseManagersError = null;
    },
    clearCreateUser: (state) => {
      state.createUserLoading = false;
      state.createUserSuccess = false;
      state.createUserError = null;
    },
    clearUpdateUser: (state) => {
      state.updateUserLoading = false;
      state.updateUserSuccess = false;
      state.updateUserError = null;
    },
    clearDeleteUser: (state) => {
      state.deleteUserLoading = false;
      state.deleteUserSuccess = false;
      state.deleteUserError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersAsync.pending, (state, action) => {
        state.fetchUsersLoading = true;
      })
      .addCase(fetchUsersAsync.fulfilled, (state, action) => {
        state.fetchUsersLoading = false;
        state.fetchUsersSuccess = true;
        state.users = action.payload.data.users;
        state.total = action.payload.data.count;
      })
      .addCase(fetchUsersAsync.rejected, (state, action) => {
        state.fetchUsersLoading = false;
        state.fetchUsersError = action.payload;
      })
      .addCase(createUserAsync.pending, (state, action) => {
        state.createUserLoading = true;
      })
      .addCase(createUserAsync.fulfilled, (state, action) => {
        state.createUserLoading = false;
        state.createUserSuccess = true;
      })
      .addCase(createUserAsync.rejected, (state, action) => {
        state.createUserLoading = false;
        state.createUserError = action.payload;
      })
      .addCase(updateUserAsync.pending, (state, action) => {
        state.updateUserLoading = true;
      })
      .addCase(updateUserAsync.fulfilled, (state, action) => {
        state.updateUserLoading = false;
        state.updateUserSuccess = true;
        state.users = state.users.map((user) => {
          if (user.id === action.payload.data.id) {
            return action.payload.data;
          }
          return user;
        });
        state.updateUserError = null;
      })
      .addCase(updateUserAsync.rejected, (state, action) => {
        state.updateUserLoading = false;
        state.updateUserSuccess = false;
        state.updateUserError = action.payload;
      })
      .addCase(fetchDeliveryBoyAsync.pending, (state, action) => {
        state.fetchDeliveryBoyLoading = true;
      })
      .addCase(fetchDeliveryBoyAsync.fulfilled, (state, action) => {
        state.fetchDeliveryBoyLoading = false;
        state.fetchDeliveryBoySuccess = true;
        state.deliveryBoys = action.payload.data;
      })
      .addCase(fetchDeliveryBoyAsync.rejected, (state, action) => {
        state.fetchDeliveryBoyLoading = false;
        state.fetchDeliveryBoyError = action.payload;
      })
      .addCase(assignDeliveryBoyAsync.pending, (state, action) => {
        state.assignDeliveryBoyLoading = true;
      })
      .addCase(assignDeliveryBoyAsync.fulfilled, (state, action) => {
        state.assignDeliveryBoyLoading = false;
        state.assignDeliveryBoySuccess = true;
      })
      .addCase(assignDeliveryBoyAsync.rejected, (state, action) => {
        state.assignDeliveryBoyLoading = false;
        state.assignDeliveryBoyError = action.payload;
      })

      .addCase(deleteUserAsync.pending, (state, action) => {
        state.deleteUserLoading = true;
      })
      .addCase(deleteUserAsync.fulfilled, (state, action) => {
        state.deleteUserLoading = false;
        state.deleteUserSuccess = true;
        state.users = state.users.filter((user) => user.id !== action.payload);
      })
      .addCase(deleteUserAsync.rejected, (state, action) => {
        state.deleteUserLoading = false;
        state.deleteUserError = action.payload;
      });
  },
});

export const {
  clearFetchUsers,
  clearAssignDeliveryBoy,
  clearFetchDeliveryBoy,
  clearFetchWarehouseManagers,
  clearCreateUser,
  clearUpdateUser,
  clearDeleteUser,
} = userSlice.actions;
export default userSlice.reducer;
