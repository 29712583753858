import React from "react";

/**
 * imported from react-redux and react-router-dom
 */
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

const ProtectedRoute = ({ path, children }) => {
  return (
    <Routes>
      <Route
        path={path}
        element={
          <RequiredShieldAuth redirectTo={"/login"}>
            {children}
          </RequiredShieldAuth>
        }
      />
    </Routes>
  );
};

const RequiredShieldAuth = ({ children, redirectTo }) => {
  const { token } = useSelector((state) => state.auth);
  return token ? children : <Navigate to={redirectTo} />;
};

export default ProtectedRoute;
