import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardCard from "../../components/dashboard/DashboardCard";
import { DashboardChartCard } from "../../components/dashboard/DashboardChartCard";
import { fetchDashboardStatusAsync } from "../../store/dashboard-status/dashboardStatusSlice";

export const DashboardPageLayout = () => {
  /**
   * states
   */
  const dispatch = useDispatch();

  /**
   * selectors
   */
  const { dashboardStatus, inventories, shelves, warehouses } = useSelector(
    (state) => state.dashboard
  );

  /**
   * methods
   */

  /**
   * effects
   */
  useEffect(() => {
    dispatch(fetchDashboardStatusAsync());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * destructured variables
   */

  /**  Here is the status ascending order 
   Canceled(0) Declined(1)  Delivered(2) Ongoing(3) Ordered(4) Pending(5) Refunded(6) Returned(7)
   Incoming mean ordered on this time
  */

  const data = [
    {
      title: "Orders",
      titleNumber: dashboardStatus.reduce(function (acc, val) {
        return acc + val;
      }, 0),
      description: "Pending orders",
      descriptionNumber: dashboardStatus[5],
      type: "order",
    },
    {
      title: "GRN Products",
      titleNumber: inventories,
      description: "All GRN Products",
      descriptionNumber: inventories,
      type: "product",
    },
    {
      title: "Warehouses",
      titleNumber: warehouses,
      description: "Total number of Warehouses",
      descriptionNumber: warehouses,
      type: "warehouse",
    },
    {
      title: "Shelves",
      titleNumber: shelves,
      description: "Total number of Shelves",
      descriptionNumber: shelves,
      type: "shelf",
    },
  ];
  // Create new array 
  const total_order = [
    dashboardStatus[2],
    dashboardStatus[3],
    dashboardStatus[4],
    dashboardStatus[5],
  ];
  const doughnutChartData = {
    labels: ["Delivered", "Ongoing", "Incoming", "Pending"],
    datasets: [
      {
        label: "Orders",
        data: dashboardStatus.length !== 0 ? total_order : [0, 0, 0, 0],
        backgroundColor: [
          "rgba(135,208,104,1)",
          "rgba(255,87,34,1)",
          "rgba(96,125,139,1)",
          "rgba(255,193,7,1)",
        ],
        borderColor: [
          "rgba(135,208,104,1)",
          "rgba(255,87,34,1)",
          "rgba(96,125,139,1)",
          "rgba(255,193,7,1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const pieChartData = {
    labels: ["Warehouses", "Shelves", "Products"],
    datasets: [
      {
        label: "Inventory",
        data: [warehouses, shelves, inventories],
        backgroundColor: [
          "rgba(243,156,17,1)",
          "rgba(255,87,34,1)",
          "rgba(135,208,104,1)",
        ],
        borderColor: [
          "rgba(243,156,17,1)",
          "rgba(255,87,34,1)",
          "rgba(135,208,104,1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="w-full flex flex-col">
      <div className="flex flex-row justify-between w-full">
        {data.map((customCard, idx) => {
          return (
            <DashboardCard
              key={idx}
              title={customCard.title}
              titleNumber={customCard.titleNumber}
              description={customCard.description}
              descriptionNumber={customCard.descriptionNumber}
              type={customCard.type}
            />
          );
        })}
      </div>
      <div className="mt-6 w-full flex flex-row">
        <DashboardChartCard
          title={"Total number of orders"}
          doughnutChartData={doughnutChartData}
        />
        <DashboardChartCard
          title={"Inventory Status"}
          pieChartData={pieChartData}
        />
      </div>
    </div>
  );
};
