import React from "react";

/**
 * imported from antd and @ant-design/icons
 */
import { Layout, Dropdown, Space, Avatar } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";

/**
 * imported from react-redux and react-router-dom
 */
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

/**
 * imported from store
 */
import { logout } from "../../store/auth/authSlice";

const { Header } = Layout;

const Navbar = ({ collapsed, toggle }) => {
  /**
   * states
   */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /**
   * selectors
   */
  const { user } = useSelector((state) => state.auth);

  /**
   * methods
   */
  const getItem = (label, key, icon, children, type) => {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  };

  /**
   * effects
   */

  /**
   * destructured values
   */
  const items = [
    {
      key: "logout",
      label: <div onClick={() => dispatch(logout())}>Logout</div>,
    },
  ];

  return (
    <>
      <Header className="!bg-white !p-0 !px-4 text-xl">
        <div className="pr-8 flex justify-between items-center">
          <span onClick={toggle}>
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </span>
          <div className="flex flex-row justify-center items-center">
            <Dropdown menu={{ items }} trigger={["click"]}>
              <Space>
                <Avatar size={"default"} className="!bg-primary">
                  {(user !== null || user !== undefined) &&
                    user["firstName"][0]?.toUpperCase()}
                </Avatar>
              </Space>
            </Dropdown>
          </div>
        </div>
      </Header>
    </>
  );
};

export default Navbar;
