import React, { useState } from "react";

import { Button, Tag, Tooltip } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FileSyncOutlined } from "@ant-design/icons";
// import { ChangeSelfPickupOrderStatus } from "../../components/self-pickup/ChangeSelfPickupOrderStatus";
import { commaNumber } from "../../utils/numbers";
import { fetchSelfPickupOrdersAsync } from "../../store/order/orderSlice";
import { CustomTable } from "../../components/common/CustomTable";
import CustomModal from "../../components/common/CustomModal";
import UpdateSelfPickupOrderStatus from "../../components/self-pickup/UpdateSelfPickupOrderStatus";
import CustomTag from "../../components/common/CustomTag";
import { DetailForSelfPickup } from "../../components/self-pickup/DetailForSelfPickup";

const SelfPickupPageLayout = () => {
  /**
   * states
   */
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState(null);
  const [showSelfPickupDetail, setShowSelfPickupDetail] = useState(false);
  const [showChangeOrderStatusModal, setShowChangeOrderStatusModal] =
    useState(false);

  /**
   * selectors
   */
  const { fetchSelfPickupOrdersLoading, selfPickupOrders } = useSelector(
    (state) => state.order
  );

  /**
   * methods
   */

  /**
   * effects
   */
  useEffect(() => {
    dispatch(fetchSelfPickupOrdersAsync());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * destructuring
   */

  const columns = [
    {
      key: "orderVoucher",
      title: "Reference no.",
      dataIndex: "order_voucher",
      fixed: "left",
      width: "5%",

      render: (data) => {
        return <span>{data}</span>;
      },
    },
    {
      key: "customerFullName",
      title: "Ordered by",
      dataIndex: "customerId",
      width: "6%",
      render: (data) => {
        return `${data["first_name"]} ${data["last_name"]}`;
      },
    },
    {
      key: "orderDate",
      title: "Order date",
      dataIndex: "createdAt",
      width: "8%",
      render: (data) => {
        return <div>{`${moment(data).format("dddd Do MMMM, YYYY")}`}</div>;
      },
    },
    {
      key: "totalPrice",
      title: "Price",
      dataIndex: "totalPrice",
      width: "4%",
      render: (data) => {
        return <div>{`${commaNumber(data)} br`}</div>;
      },
    },
    {
      key: "cashOnDelivery",
      title: "Cash on delivery",
      dataIndex: "is_cash_on_delivery",
      width: "8%",
      render: (data) => {
        return data ? (
          <Tag color={"gold"}>Yes, cash need to be paid.</Tag>
        ) : (
          <span>No, payment already made.</span>
        );
      },
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      render: (data, selfPickupOrder) => {
        return (
          <div
            className="cursor-pointer"
            onClick={() => {
              setShowChangeOrderStatusModal(true);
              setInitialValues(selfPickupOrder);
            }}
          >
            <CustomTag data={data} />
          </div>
        );
      },
      width: "4%",
    },
    {
      key: "totalNumberOfProducts",
      title: "Quantity",
      dataIndex: "product_quantity",
      render: (data) => {
        return `${data.length}`;
      },
      width: "4%",
    },
    {
      key: "deliveryDate",
      title: "Delivery Type",
      dataIndex: "deliveryDate",
      render: (data) => {
        const deliveryDate = data;
        if (deliveryDate === "next_day") {
          return "Next Day";
        } else if (deliveryDate === "expedited") {
          return "Expedited";
        } else if (deliveryDate === "same_day") {
          return "Same Day";
        }
        return `${data.toString()[0].toUpperCase() + data.toString().slice(1)}`;
      },
      width: "6%",
    },
    {
      key: "deliveryLocation",
      title: "Delivery Location",
      dataIndex: "deliveryLocation",
      width: "8%",
      render: (data) => {
        return `${data}`;
      },
    },
    {
      key: "deliveryType",
      title: "Deliver From",
      dataIndex: "delivery_type",
      width: "8%",
      render: (data) => {
        return `${data}`;
      },
    },
    {
      key: "customerPhoneNumber",
      title: "Customer Phone Number",
      dataIndex: ["customerId", "phone"],
      width: "7%",
      render: (data) => {
        if (data !== null) {
          const phone = data.toString().startsWith("251")
            ? `0${data.slice(3)}`
            : data;
          return <span>{phone}</span>;
        } else {
          return <></>;
        }
      },
    },
    {
      key: "actions",
      title: "Action",
      dataIndex: "id",
      fixed: "right",
      width: "2%",
      render: (data, order) => {
        return (
          <Tooltip title="Click here to see details">
            <Button
              type="primary"
              style={{ backgroundColor: "#1DA2A5", border: "#1DA2A5" }}
              onClick={() => {
                setShowSelfPickupDetail(true);
                setInitialValues(order);
              }}
            >
              <FileSyncOutlined />
            </Button>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <>
      <div className="font-poppins-regular flex flex-row justify-between items-center mb-8">
        <div className="text-2xl font-poppins-semibold text-gray-500 mb-0">
          Self pickup orders
        </div>
      </div>
      <div>
        <CustomTable
          loading={fetchSelfPickupOrdersLoading}
          columns={columns}
          data={selfPickupOrders}
          scrollValue={2700}
        />
      </div>
      <CustomModal
        centered
        isModalOpen={showChangeOrderStatusModal}
        onCancel={() => setShowChangeOrderStatusModal(false)}
      >
        <UpdateSelfPickupOrderStatus
          onCancel={() => {
            setShowChangeOrderStatusModal(false);
            dispatch(fetchSelfPickupOrdersAsync());
          }}
          selfPickup={initialValues}
        />
      </CustomModal>
      <CustomModal
        maskClosable={false}
        isModalOpen={showSelfPickupDetail}
        onCancel={() => setShowSelfPickupDetail(false)}
        width={1200}
      >
        <DetailForSelfPickup order={initialValues} />
      </CustomModal>
    </>
  );
};

export default SelfPickupPageLayout;
