import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../utils/api";

const INITIAL_STATE = {
  fetchWarehousesLoading: false,
  fetchWarehousesSuccess: false,
  fetchWarehousesError: null,

  createWarehouseLoading: false,
  createWarehouseSuccess: false,
  createWarehouseError: null,

  editWarehouseLoading: false,
  editWarehouseSuccess: false,
  editWarehouseError: false,

  deleteWarehouseLoading: false,
  deleteWarehouseSuccess: false,
  deleteWarehouseError: false,

  fetchWarehouseShelvesLoading: false,
  fetchWarehouseShelvesSuccess: false,
  fetchWarehouseShelvesError: false,

  warehouse: {},
  warehouses: [],
  warehouseShelves: [],

  page: 1,
  limit: 10,
  total: 0,
};

export const fetchWarehousesAsync = createAsyncThunk(
  "warehouse/fetchWarehouses",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.get(`/warehouses`, {
        params: data._all
          ? { _all: data._all }
          : {
              page: data.page,
              limit: data.limit,
            },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createWarehouseAsync = createAsyncThunk(
  "warehouse/createWarehouse",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.post(`/warehouses`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editWarehouseAsync = createAsyncThunk(
  "warehouse/editWarehouse",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.patch(`/warehouses/${data.id}`, data.result, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteWarehouseAsync = createAsyncThunk(
  "warehouse/deleteWarehouse",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.delete(`/warehouses/${data.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        return data.id;
      }
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchWarehouseShelvesAsync = createAsyncThunk(
  "warehouse/fetchWarehouseShelves",
  async (data, { getState, rejectWithValue }) => {
    try {
      const {
        auth: { token },
      } = getState();
      const response = await api.get(`/shelves`, {
        params: {
          warehouseId: data.id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const warehouseSlice = createSlice({
  name: "warehouse",
  initialState: INITIAL_STATE,
  reducers: {
    clearFetchWarehouses: (state) => {
      state.fetchWarehousesLoading = false;
      state.fetchWarehousesSuccess = false;
      state.fetchWarehousesError = null;
    },
    clearCreateWarehouse: (state) => {
      state.createWarehouseLoading = false;
      state.createWarehouseSuccess = false;
      state.createWarehouseError = null;
    },
    clearEditWarehouse: (state) => {
      state.editWarehouseLoading = false;
      state.editWarehouseSuccess = false;
      state.editWarehouseError = null;
    },
    clearDeleteWarehouse: (state) => {
      state.deleteWarehouseLoading = false;
      state.deleteWarehouseSuccess = false;
      state.deleteWarehouseError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWarehousesAsync.pending, (state, action) => {
        state.fetchWarehousesLoading = true;
        state.fetchWarehousesSuccess = false;
        state.fetchWarehousesError = null;
      })
      .addCase(fetchWarehousesAsync.fulfilled, (state, action) => {
        state.fetchWarehousesLoading = false;
        state.fetchWarehousesSuccess = true;
        if (action.meta.arg._all) {
          state.warehouses = action.payload.data;
        } else {
          state.warehouses = action.payload.data.warehouses;
        }
        state.total = action.payload.data.total;
      })
      .addCase(fetchWarehousesAsync.rejected, (state, action) => {
        state.fetchWarehousesLoading = false;
        state.fetchWarehousesSuccess = false;
        state.fetchWarehousesError = action.payload;
      })
      .addCase(createWarehouseAsync.pending, (state, action) => {
        state.createWarehouseLoading = true;
        state.createWarehouseSuccess = false;
        state.createWarehouseError = null;
      })
      .addCase(createWarehouseAsync.fulfilled, (state, action) => {
        state.createWarehouseLoading = false;
        state.createWarehouseSuccess = true;
        state.warehouses.push(action.payload.data);
        state.createWarehouseError = null;
      })
      .addCase(createWarehouseAsync.rejected, (state, action) => {
        state.createWarehouseLoading = false;
        state.createWarehouseSuccess = false;
        state.createWarehouseError = action.payload;
      })
      .addCase(editWarehouseAsync.pending, (state, action) => {
        state.editWarehouseLoading = true;
        state.editWarehouseSuccess = false;
        state.editWarehouseError = null;
      })
      .addCase(editWarehouseAsync.fulfilled, (state, action) => {
        state.editWarehouseLoading = false;
        state.editWarehouseSuccess = true;
        state.warehouses = state.warehouses.map((warehouse) => {
          if (warehouse.id === action.payload.data.data.id) {
            return action.payload.data.data;
          }
          return warehouse;
        });
      })
      .addCase(editWarehouseAsync.rejected, (state, action) => {
        state.editWarehouseLoading = false;
        state.editWarehouseSuccess = false;
        state.editWarehouseError = action.payload;
      })
      .addCase(deleteWarehouseAsync.pending, (state, action) => {
        state.deleteWarehouseLoading = true;
        state.deleteWarehouseSuccess = false;
        state.deleteWarehouseError = null;
      })
      .addCase(deleteWarehouseAsync.fulfilled, (state, action) => {
        state.deleteWarehouseLoading = false;
        state.deleteWarehouseSuccess = true;
        state.warehouses = state.warehouses.filter(
          (warehouse) => warehouse.id !== action.payload
        );
        state.deleteWarehouseError = null;
      })
      .addCase(deleteWarehouseAsync.rejected, (state, action) => {
        state.deleteWarehouseLoading = false;
        state.deleteWarehouseSuccess = false;
        state.deleteWarehouseError = action.payload;
      })

      .addCase(fetchWarehouseShelvesAsync.pending, (state, action) => {
        state.fetchWarehouseShelvesLoading = true;
        state.fetchWarehouseShelvesSuccess = false;
        state.fetchWarehouseShelvesError = null;
      })
      .addCase(fetchWarehouseShelvesAsync.fulfilled, (state, action) => {
        state.fetchWarehouseShelvesLoading = false;
        state.fetchWarehouseShelvesSuccess = true;
        state.warehouseShelves = action.payload.data.shelves;
      })
      .addCase(fetchWarehouseShelvesAsync.rejected, (state, action) => {
        state.fetchWarehouseShelvesLoading = false;
        state.fetchWarehouseShelvesSuccess = false;
        state.fetchWarehouseShelvesError = action.payload;
      });
  },
});

export const {
  clearFetchWarehouses,
  clearCreateWarehouse,
  clearEditWarehouse,
  clearDeleteWarehouse,
} = warehouseSlice.actions;
export default warehouseSlice.reducer;
