import React, { useState } from "react";
import { Button, Space } from "antd";
import { FileSyncOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchOngoingRequisitionsAsync } from "../../../store/requisition/requisitionSlice";
import { CustomTable } from "../../../components/common/CustomTable";
import CustomModal from "../../../components/common/CustomModal";
import { DetailForRequisition } from "../../../components/requisition/DetailForRequisition";
import { QrcodeOutlined } from "@ant-design/icons";
import DetailForQROrder from "../../../components/order/DetailForQROrder";

const RequisitionOngoingTabLayout = () => {
  /**
   * states
   */
  const dispatch = useDispatch();
  const [showRequisitionDetailModal, setShowRequisitionDetailModal] =
    useState(false);
  const [initialValue, setInitialValue] = useState({});
  const [showQROrder, setShowQROrder] = useState({
    orderId: "",
    status: false,
  });

  /**
   * selectors
   */
  const { fetchOngoingRequisitionsLoading, ongoingRequisitions } = useSelector(
    (state) => state.requisition
  );

  /**
   * methods
   */

  /**
   * effects
   */
  useEffect(() => {
    dispatch(fetchOngoingRequisitionsAsync());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * destructuring
   */
  const columns = [
    {
      key: "status",
      title: "Status",
      dataIndex: ["requisition", "status"],
      render: (data) => {
        return <span>{data}</span>;
      },
    },
    {
      key: "totalNumberOfRequestedProducts",
      title: "Product quantity",
      dataIndex: ["requisition", "product_quantity"],
      render: (data) => {
        if (data) {
          return `${data.length}`;
        }
        return <span>0</span>;
      },
    },
    {
      key: "storeName",
      title: "Store name",
      dataIndex: ["data", "store_name"],
      render: (data) => {
        return <span>{data}</span>;
      },
    },
    {
      key: "vendorPhoneNumber",
      title: "Vendor phone number",
      dataIndex: ["data", "phone"],

      render: (data) => {
        return <span>{data}</span>;
      },
    },
    {
      key: "referenceNumber",
      title: "Reference number",
      dataIndex: ["requisition", "refnumber"],

      render: (data) => {
        return <span>{data}</span>;
      },
    },
    {
      key: "actions",
      title: "Action",
      dataIndex: "id",
      fixed: "right",
      width: "4%",
      render: (id, data) => {
        return (
          <Space>
            <Button
              type="primary"
              style={{ backgroundColor: "#1DA2A5", border: "#1DA2A5" }}
              onClick={() => {
                setShowRequisitionDetailModal(true);
                setInitialValue(data);
              }}
            >
              <FileSyncOutlined />
            </Button>
            <Button
              className="!border-[#FF5722]"
              ghost
              onClick={() => {
                setShowQROrder({
                  ...showQROrder,
                  orderId: data["requisition"]["refnumber"],
                  status: true,
                });
              }}
            >
              <QrcodeOutlined
                style={{
                  color: "#FF5722",
                }}
              />
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <CustomTable
          loading={fetchOngoingRequisitionsLoading}
          columns={columns}
          data={ongoingRequisitions}
        />
        <CustomModal
          isModalOpen={showRequisitionDetailModal}
          onCancel={() => setShowRequisitionDetailModal(false)}
          width={1000}
        >
          <DetailForRequisition
            onComplete={() => {
              setShowRequisitionDetailModal(false);
              dispatch(fetchOngoingRequisitionsAsync());
            }}
            initialValue={initialValue}
          />
        </CustomModal>
        <CustomModal
          centered
          maskClosable={false}
          isModalOpen={showQROrder.status}
          onCancel={() => setShowQROrder({ ...showQROrder, status: false })}
          width={600}
        >
          <DetailForQROrder orderId={showQROrder.orderId} />
        </CustomModal>
      </div>
    </>
  );
};

export default RequisitionOngoingTabLayout;
